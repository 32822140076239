import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const withRoleCheck = (allowedRoles) => (WrappedComponent) => {
  const ComponentWithRoleCheck = (props) => {
    const navigate = useNavigate();
    const userRole = localStorage.getItem('@usertype');

    useEffect(() => {
      if (userRole !== 'Directeur' && !allowedRoles.includes(userRole)) {
        navigate('/signup');
      }
    }, [navigate, userRole]);

    return <WrappedComponent {...props} />;
  };

  return ComponentWithRoleCheck;
};

export default withRoleCheck;
