import React from 'react';
import * as d3 from 'd3';
import * as topojson from 'topojson-client';
import { useState, useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import '../../../App.css';
import { culture } from '../../../api/auth_constant';
import colors from './colors';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';

const soilGroups = [
  {
    types: [
      "mil",
            "sorgho",
            "néré",
            "coton",
            "Mais",
            "riz",
            "oignon",
            "pomme de terre",
            "cultures maraichères"
    ],
    color: "#F5DEB3" // Couleur dorée pour ce groupe
  },
  {
    types: [
      "coton",
            "mais",
            "sorgho",
            "Arachide",
            "niébé"
    ],
    color: "#D2691E" // Couleur vert clair pour ce groupe
  },

  {
    types: [
      "arachide",
      "igname",
      "mais",
      "coton",
      "Tabac",
      "riziculture",
      "culture maraichère"
    ],
    color: "#F0FFFF" // Couleur bleu violet pour ce groupe
  },
  {
    types: [
      "sorgho",
      "mil",
      "mais",
      "manioc",
      "Igname",
      "fonio",
      "voandzou",
      "riz",
      "niébé",
      "tabac",
      "Arachide" 
      ],
    color: "#F4A460" // Couleur rose vif pour ce groupe
  },
  {
    types: [
        "mais",
        "manioc",
        "palmier"
    ],
    color: "#CC7722" // Couleur rose vif pour ce groupe
  },
  {
    types: [
      "mais",
      "sorgho",
      "manioc",
      "Arachide",
      "igname",
      "coton",
      "Tabac",
      "courge",
      "plantes pérennes",
      "Cultures maraichères"
   ],
    color: "#800000" // Couleur rose vif pour ce groupe
  },

  {
    types: [
      "mais",
      "manioc",
      "niébé",
      "Cultures maraichère",
      "Cultures vivrières",
      "cultures pérennes",
      "essences fruitières",
      "essences forestières"
      ],      
    color: "#808080" // Couleur rose vif pour ce groupe
  },
  {
    types: [
        "mais",
        "manioc",
        "niébé",
        "arachide"
    ],
    color: "#C2B280" // Couleur rose vif pour ce groupe
  },
];

const soilSymbols = {
  "mil": "+",
  "sorgho": "-",
  "néré": "*",
  "coton": "±",
  "mais": "~",
  "riz": "▪",
  "oignon": "●",
  "pomme de terre": "♀",
  "cultures maraichères": "☀",
  "arachide": "▫",
  "igname": "▲",
  "tabac": "♂",
  "riziculture": "º",
  "palmier": "✗",
  "manioc": "✪",
  "fonio": "✵",
  "voandzou": "❖",
  "niébé": "0",
  "courge": "x",
  "plantes pérennes": "¤",
  "anacardier": "✿",
  "cultures vivrières": "′",
  "essences fruitières": "°",
  "essences forestières": "∙",
  "acacia": "◐",
  "teck": "ા"
};

export default function MapChartSol() {
  const [state, setState] = useState({ country: null, tractorsData: [], pannesData: [] });
  const [soilData, setSoilData] = useState([]);
  const [dialogData, setDialogData] = useState(null); // État pour gérer les données du dialog
  const [isDialogOpen, setIsDialogOpen] = useState(false); // État pour gérer l'ouverture du dialog
  const svgRef = useRef(null);
  const height = 850;

  const handleClose = () => {
    setIsDialogOpen(false);
  };
  const fetchSoilData = async () => {
    try {
      const response = await fetch(culture);
      const data = await response.json();
      setSoilData(data);
    } catch (error) {
      console.error("Error fetching soil data:", error);
    }
  }
  const jsonToData = async (data) => {
    try {
      const responses = await Promise.all([
        d3.json(String(data.countries)),
      ]);
      const countriesGeoJSON = topojson.feature(responses[0], responses[0].objects.collection);
      setState({ country: countriesGeoJSON });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchData = async () => {
    await jsonToData({
      countries: new URL("https://raw.githubusercontent.com/oscarduzer/countries-states-cities-database/master/country.topojson"),
    });
    await fetchSoilData();
  };

  useEffect(() => {
    fetchData();
  }, []);
  
// Fonctions pour récupérer la couleur et le symbole associés aux types de sol
function getColorForSoil(soilArray) {
  const groupMatches = soilGroups.map(group => {
    const matchCount = group.types.filter(type => soilArray.includes(type)).length;
    return { color: group.color, matchCount };
  });

  // Trouver le groupe avec le plus grand nombre de correspondances
  const bestMatch = groupMatches.reduce((prev, current) => {
    return current.matchCount > prev.matchCount ? current : prev;
  }, { matchCount: 0 });

  // Si un groupe a au moins une correspondance, retourner sa couleur
  return bestMatch.matchCount > 0 ? bestMatch.color : "#000000"; // Couleur par défaut si aucun groupe ne correspond
}

function getSymbolForSoil(soilArray) {
  if (!soilArray || soilArray.length === 0) {
    return "?"; // Symbole par défaut si aucune culture n'est trouvée pour cette région
  }
  
  // Crée un string de symboles à partir des données
  const symbols = soilArray.map(soil => {
    const symbol = soilSymbols[soil.toLowerCase()] || "?";
    return symbol;
  }).join(" ");
    return symbols;
}



function removeAccents(str) {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
}

useEffect(() => {
  if (state.country && svgRef.current) {
    const svg = d3.select(svgRef.current)
      .attr("viewBox", [0, 0, height / 2])
      .attr("height", height);

    const tooltip = d3.select("#tooltip").style('pointer-events', 'none').style("visibility", "hidden");

    const projection = d3.geoProjection((x, y) => [x, Math.log(Math.tan(Math.PI / 4 + y / 2))])
      .scale(5800)
      .translate([-20, 1370]);

    const path = d3.geoPath().projection(projection);

    svg.selectAll("*").remove();
    const g = svg.append("g").attr("fill", "#444");

    // Dessin des régions
    g.selectAll("path")
      .data(state.country.features)
      .join('path')
      .attr('fill', d => {
        const soilDataForCurrentFeature = soilData.find(item => removeAccents(String(item.Communes)).toLowerCase() === removeAccents(d.properties.name).toLowerCase());
        return soilDataForCurrentFeature && soilDataForCurrentFeature.Cultures.length > 0
          ? getColorForSoil(soilDataForCurrentFeature.Cultures)
          : "#000000";
      })
      .attr('d', path)
      .attr('stroke', 'gray')
      .attr('cursor', "pointer")
      .on("mouseover", (event, d) => {
        const soils = soilData.find(item => removeAccents(String(item.Communes)).toLowerCase() === removeAccents(d.properties.name).toLowerCase())?.Cultures.join(', ') || "Aucun";
        tooltip.html(`
          <em style="text-align:center;font-weight:bold;">${d.properties.name}</em><br/>
          <em style="font-weight:bold;">Types de Cultures : </em>${soils}<br/>
        `)
        .style('left',  `${event.clientX}px`)
        .style('top', `${event.clientY}px`)
        .style("position", "fixed")
        .style("z-index", "1000")
        .style("visibility", "visible");
      })
      .on("mouseout", () => tooltip.style("visibility", "hidden"))
      .on("click", clicked);

    // Ajout des symboles de sol pour chaque région
    svg.selectAll("text")
    .data(state.country.features)
    .join("text")
    .attr("x", d => {
      const centroid = path.centroid(d);
      return centroid[0];
    })
    .attr("y", d => {
      const centroid = path.centroid(d);
      return centroid[1];
    })
    .attr("text-anchor", "middle")
    .attr("dy", "0.35em")
    .style("font-size", "10px")
    .style("fill", "#000")
    .text(d => {
      const soilDataForCurrentFeature = soilData.find(item => removeAccents(String(item.Communes)).toLowerCase() === removeAccents(d.properties.name).toLowerCase());
      return soilDataForCurrentFeature && soilDataForCurrentFeature.Cultures.length > 0
        ? getSymbolForSoil(soilDataForCurrentFeature.Cultures)
        : "?";
    });
  

    // Création de la légende
    const legendGroup = svg.append("g")
      .attr("transform", `translate(${height / 2 + 50}, 50)`);

    soilGroups.forEach((group, i) => {
      const legendRow = legendGroup.append("g")
        .attr("transform", `translate(0, ${i * 30})`);

      legendRow.append("rect")
        .attr("width", 20)
        .attr("height", 20)
        .attr("fill", group.color)
        .attr("stroke", "black");

      legendRow.append("text")
        .attr("x", 30)
        .attr("y", 15)
        .style("font-size", "14px")
        .text(group.types);
    });

    // Fonction pour ouvrir le dialog avec les informations du tooltip
    function clicked(event, d) {
      const soils = soilData.find(item => removeAccents(String(item.Communes)).toLowerCase() === removeAccents(d.properties.name).toLowerCase())?.Cultures.join(', ') || "Aucun";
      setDialogData({
        name: d.properties.name,
        soils: soils,
      });
      setIsDialogOpen(true);
    }
  }
}, [state, height, soilGroups, soilData]);

// Composant de dialog pour afficher les détails
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));


  return (
    <>
      <svg width={"100%"} ref={svgRef} />
      <div id="tooltip"></div>
      {/* Dialog pour afficher les informations */}
      <BootstrapDialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}   sx={{ 
    '& .MuiPaper-root': { 
      borderRadius: '40px' // Appliquer des bords arrondis au contenu du Dialog
    } 
  }}>
        {dialogData && (
          <>
            <DialogTitle sx={{fontWeight:'bold', color:'green'}}>{dialogData.name}</DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={(theme) => ({
                position: 'absolute',
                right: 8,
                top: 8,
                color: theme.palette.grey[500],
              })}
            >
          <Box
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 30, // diamètre du cercle
                height: 30, // diamètre du cercle
                borderRadius: '50%', // pour rendre la forme circulaire
                border: '2px solid red', // couleur et taille du bord du cercle
              }}
            >
              <CloseIcon sx={{ color: 'red' }} />
            </Box>
        </IconButton>
            <DialogContent dividers>
              <DialogContentText gutterBottom>
                <strong>Types de Cultures :</strong> {dialogData.soils}<br />
              </DialogContentText>
            </DialogContent>
          </>
        )}
      </BootstrapDialog>
    </>
  );
}
