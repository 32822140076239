import React, { useState, useEffect } from 'react';
import axios from 'axios';
import RoleCheck from './Dashboards/RoleCheck';
import SideBar from './SideBar';
import { AppBar, Toolbar, IconButton, Typography, Container, Grid, Box, Paper, Autocomplete, TextField, MenuItem, FormControl, InputLabel } from '@mui/material';
import { Tab, Tabs, Nav, Row, Col  } from 'react-bootstrap';
import CalendarButton from './CalendarButton';
import ButtonComponent from './ButtonComponent';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useMediaQuery } from '@mui/material';
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const currentDate = new Date();
const formattedDate = currentDate.toLocaleDateString('fr-FR', { year: 'numeric', month: 'long' });
const formattedDateWithCapitalizedMonth = capitalizeFirstLetter(formattedDate); 

function PredictionsComponent() {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [tabValue, setTabValue] = useState('1');

    const handleDrawerOpen = () => {
        setSidebarOpen(true);
    };

    const handleDrawerClose = () => {
        setSidebarOpen(false);
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const isXs = useMediaQuery('(max-width:600px)');

    return (
        <Box sx={{ display: 'flex' }}>
        <SideBar open={sidebarOpen} onClose={() => setSidebarOpen(false)} />
        <Box component="main" sx={{ flexGrow: 1, p: { xs: 1, sm: 2, md: 3 } }}>
          <Toolbar />
          <Box display="flex" justifyContent="space-between" mb={3}>
            <CalendarButton />
            <ButtonComponent />
          </Box>
  
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 3, borderColor: 'divider' }}>
              <Nav variant="tabs" className="flex-column flex-sm-row">
                {['Evolution des marchés', 'Prévisions de production'].map((label, index) => (
                  <Nav.Item key={index}>
                    <Nav.Link
                      eventKey={`${index + 1}`}
                      active={tabValue === `${index + 1}`}
                      onClick={() => handleTabChange(null, `${index + 1}`)}
                      style={{
                        color: tabValue === `${index + 1}` ? '#F6CA11' : '#02834A',
                        fontSize: '1.2rem',
                        textTransform: 'none',
                      }}
                    >
                      {label}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Box>
  
            <TabPanel value="1">
            </TabPanel>
  
            <TabPanel value="2">           
                </TabPanel>
          </TabContext>
        </Box>
      </Box>
    );
  }
  
  export default RoleCheck(['Commercial'])(PredictionsComponent);
