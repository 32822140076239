import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { register } from '../api/auth_client';
import validateSignup from './ValidateSignup';

function Signup() {
  const navigate = useNavigate(); 
  const [values, setValues] = useState({
    mail: '',
    password: '',
    usertype: ''
  });

  const [errors, setErrors] = useState({});

  const handleInput = (e) => {
    setValues(prev => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation des données avant la soumission
    const validationErrors = validateSignup(values);
    setErrors(validationErrors);

    try {
      const response = await register(values);
      console.log(response.data);

      localStorage.setItem('token', response.data.token);
      navigate('/');
    } catch (error) {
      console.error('Erreur lors de l\'inscription: ', error);
    }
  };
  
  return (
    <div className='signup template d-flex justify-content-center align-items-center vh-100 bg-success'>
      <div className='form_container p-5 rounded bg-white'>
        <form onSubmit={handleSubmit}>
          <h3 className='text-center'>Sign up</h3>
          <div className='mb-2'>
            <label htmlFor='email'>Email</label>
            <input type='email' placeholder='Enter Email' name='mail'
              onChange={handleInput} 
              className='form-control' 
              />
              {errors.email && <span className="text-danger">{errors.email}</span>}
            </div>
          <div className='mb-2'>
                    <label htmlFor='password'>Password</label>
                    <input type='password' placeholder='Enter Password' className='form-control' name='password' 
                    onChange={handleInput}
                    />
                    {errors.password && <span className="text-danger">{errors.password}</span>}
                  </div>
          <div className='mb-2'>
          <label htmlFor='role'>Role</label>
            <select value={values.usertype} onChange={handleInput} className="form-select" name="usertype">
              <option value="">Select Role</option>
              <option value="Directeur">Directeur</option>
              <option value="Mecanicien">Mecanicien</option>
              <option value="Commercial">Commercial</option>
              <option value="Informaticien">Informaticien</option>
              <option value="Financier">Financier</option>
            </select>
          </div>
          <div className='d-grid mt-2'>
            <button type='submit' className='btn btn-success'>Sign Up</button>
          </div>
          <p className='text-end mt-2'>
            Already Registered? <Link to="/" className='ms-2'>Sign in</Link>
          </p>
        </form>
      </div>
    </div>
  );
}

export default Signup;
