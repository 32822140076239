import React, { useState, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts';

const BarChartComponent = (props) => {
  const {xAxisDatas,yAxisDatas,xAxisLabels,yAxisLabels,chartTitle,xAxisDatasFormater,yAxisDatasFormaters,yAxisLegends}=props
  const scaleTypes={
    number:"linear",
    string: "band",
    object:"time", 
  }
  let xAxisConfig=null
  let yAxisConfig=null
  let seriesConfig=null
  xAxisConfig=[]
  xAxisConfig.push({id:`xAxis1`,data:xAxisDatas,scaleType:'band',label:String(xAxisLabels).toLocaleLowerCase(),valueFormatter:(value)=>xAxisDatasFormater(value)});
  seriesConfig=[]
  yAxisConfig=[]
  yAxisDatas.forEach((yAxisData,index) => {
    seriesConfig.push({ yAxisKey:`yAxis${index}`,data:yAxisDatas[index].map((item)=>item),valueFormatter:(value,context)=>yAxisDatasFormaters[index](value,context),label:yAxisLegends[index]})
    yAxisConfig.push({id:`yAxis${index}`,data:yAxisData,scaleType:scaleTypes[typeof(yAxisData)],label:String(yAxisLabels[index]).toLocaleLowerCase(),valueFormatter:(value,context)=>yAxisDatasFormaters[index](value,context)})
  });
  
  return(
    <>
        {xAxisConfig!=null?  <BarChart
          margin={{
            left:100,
            right:90
          }}
              xAxis={xAxisConfig}
              yAxis={yAxisConfig}
              series={[
                {
                 yAxisKey:'yAxis0',data:yAxisDatas[0].map((item)=>item),valueFormatter:(value,context)=>yAxisDatasFormaters[0](value,context),label:'CA'
                }
              ]}
              leftAxis={'yAxis0'}
              sx={
                {  
                  [`& .${axisClasses.left} .${axisClasses.label}`]: {
                        transform: 'translateX(-50px)',
                  },
                }
              }
            />:''}
    </>
  )
};

export default BarChartComponent;
