function validateSignup(values){
  let errors = {};
const email_pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const password_pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;

  if (values.mail === "") {
    errors.email = 'Email est  requis';
  } else if (!email_pattern.test(values.mail)) {
    errors.email = 'Email incorrect';
  }else{
      errors.email = ""
  }
  
  if (values.password === "") {
    errors.password = 'Password est requis';
  }
    else{
        errors.password = ""
    }

  return errors;
};

export default validateSignup;
