
export function NumberFormater(number){
    if (Math.abs(number) >= 1000) {
        const power = Math.floor(Math.log10(Math.abs(number)) / 3);
        const numberFormated = (number / Math.pow(1000, power)).toFixed(1);
        const suffixe = ["", "k", "M", "B", "BB"][power];
        return `${numberFormated}${suffixe}`;
    } else {
        return number.toString();
    }
}