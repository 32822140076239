import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import RoleCheck from './RoleCheck';
import SideBar from '../SideBar';
import { Paper, Grid, Modal, Box, Toolbar, Typography, TextField } from '@mui/material';
import { statistiqueUrl, prodIndust, panne, tractor} from '../../api/auth_constant';
import ButtonComponent from '../ButtonComponent';
import CalendarButton from '../CalendarButton';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Autocomplete from '@mui/material/Autocomplete';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { BarChart, Bar, Cell, ResponsiveContainer, LabelList } from 'recharts';
import FlecheComponent from '../FlecheComponent';
import { Nav, Container  } from 'react-bootstrap';
import { useMediaQuery } from '@mui/material';
import TableComponent from '../TableComponent';
import CustomCard from '../CustomCard';
import RvHookupIcon from '@mui/icons-material/RvHookup';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import LinkIcon from '@mui/icons-material/Link';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const currentDate = new Date();
const formattedDate = currentDate.toLocaleDateString('fr-FR', { year: 'numeric', month: 'long' });
const formattedDateWithCapitalizedMonth = capitalizeFirstLetter(formattedDate); 
   
const anneeOptions = [
  { label: "2022" },
  { label: "2023" },
  { label: "2024" },
];
const vagueOptions = [
  { label: "" },  
  { label: "Marques"},
  { label: "Puissance motrice"},
];
const categories = {
  'TRACTEURS': [
    'PSF0024', 'PSF0091', 'PSF0083', 'PSF0087', 'PSF0050', 'PSF0052', 'PSF0051'
  ],
  'AUTRES': [
    'PSF0022', 'PSF0012', 'PSF0011', 'PSF0010', 'PSF0092', 'PSF0085', 'PSF0102',
    'PSF0089', 'PSF0055', 'PSF0139', 'PSF0058', 'PSF0137', 'PSF0103', 'PSF0104',
    'PSF0086', 'PSF0001', 'PSF0015', 'PSF0108', 'PSF0107', 'PSF0106', 'PSF0105',
    'PSF0101', 'PSF0035', 'PSF0033', 'PSF0034', 'PSF0059', 'PSF0130', 'PSF0090',
    'PSF0041', 'PSF0038', 'PSF0039', 'PSF0084', 'PSF0040', 'PSF0088',
    'PSF0079'
  ]
};
const columns = [
  { id: 'Chef_D\'équipe', label: 'Chef D\'équipe', align: 'right' },
  { id: 'Conformité', label: 'Conformité', align: 'right' },
  { id: 'Date', label: 'Date', align: 'right' },
  { id: 'Date_Début', label: 'Date Début', align: 'right' },
  { id: 'Date_Fin', label: 'Date Fin', align: 'right' },
  { id: 'Durée', label: 'Durée', align: 'right' },
  { id: 'Désignation_Article', label: 'Désignation Article', align: 'right' },
  { id: 'Fiche_de_Production', label: 'Fiche de Production', align: 'right' },
  { id: 'Heure_Début', label: 'Heure Début', align: 'right' },
  { id: 'Heure_Fin', label: 'Heure Fin', align: 'right' },
  { id: 'Nbre_Opérateurs', label: 'Nbre Opérateurs', align: 'right' },
  { id: 'Numéro_Inconu', label: 'Numéro Inconu', align: 'right' },
  { id: 'Numéro_chassis', label: 'Numéro chassis', align: 'right' },
  { id: 'Observations', label: 'Observations', align: 'right' },
  { id: 'Ordre', label: 'Ordre', align: 'right' },
  { id: 'Réf_Article', label: 'Réf Article', align: 'right' },
  { id: 'Sem', label: 'Sem', align: 'right' },
  { id: 'Site', label: 'Site', align: 'right' },
  { id: 'Série_lot/Final', label: 'Série lot/Final', align: 'right' },
  { id: '_id', label: 'ID', align: 'right' },
];
function FormationComponent() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [tabValue, setTabValue] = useState('1');
  const [averageOperators, setAverageOperators] = useState({});
  const [averageDurations, setAverageDurations] = useState({});
  const [conformityData, setConformityData] = useState([]);
  const [averageOperatorsMarque, setAverageOperatorsMarque] = useState({});
  const [averageDurationsMarque, setAverageDurationsMarque] = useState({});
  const [conformityDataMarque, setConformityDataMarque] = useState([]);
  const [ProdData, setProdData] = useState([]);
  const [totalProduit, setTotalProduit] = useState(0);
  const [totalPanne, setTotalPanne] = useState(null);
  const [total_tracteur, setTotalTracteur] = useState(0);
  const [totaltracteurDispo, setTotal_T] = useState(0);
  const [conformite, setconforme] = useState(0);
  const [cloture, setcloture] = useState(0);
  const [dataPuissance, setDataPuissance] = useState([]);
  const [dataMarque, setdataMarque] = useState([]);
  const [noncloture, setnoncloture] = useState(0);
  const [trackConf, settrackConf] = useState(0);
  const [trackConf2, settrackConf2] = useState(0);
  const [selectedAnnee, setSelectedAnnee] = useState(null);
  const navigate = useNavigate();
  const [selectedVague, setSelectedVague] = useState(vagueOptions[0].label);
  const [chartData, setChartData] = useState({ labels: [], data: [] });
  const [chartDataPannes, setChartDataPannes] = useState({ labels: [], data: [] });
  const [barData, setBarData] = useState([]); // État pour les données du graphique à barres
  const [barData2, setBarData2] = useState([]); // État pour les données du graphique à barres
  const [chartDataPannesByMark, setChartDataPannesByMark] = useState([]);
  const [tracteurPuissance, setTracteurPuissance] = useState([]);
  const [totalTractors, setTotalTractors] = useState(0);
  const [generalOperator, setGeneralAverageOperators] = useState(0);
  const [generalConformity, setTotalConformityPercentage] = useState(0);
  const [generalConformityMarque, setTotalConformityPercentageMarque] = useState(0);
  const [generalOperatorMarque, setGeneralAverageOperatorsMarque] = useState(0);
  const [avgTauxConformite, setAvgTauxConformite] = useState(0); // Nouvel état pour la moyenne
  const [articlesParCategorie, setArticlesParCategorie] = useState(0); 
  const [showMore, setShowMore] = useState(false);

  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  const handleDrawerClose = () => {
    setSidebarOpen(false);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleVagueChange = (event, newValue) => {
    setSelectedVague(newValue ? newValue.label : vagueOptions[0].label);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };


  function calculerTauxConformite(data) {
    const total = data.length;
    const conformes = data.filter(item => item['Conformité']!==null && item['Conformité']!==undefined && item['Conformité'].toLowerCase() === 'oui').length;
    return Math.trunc((conformes / total) * 100);
  }
  function calculerTauxConformite2(data) {
    const total = data.length;
    const conformes = data.filter(item => item['Conformité']!==null && item['Conformité']!==undefined && item['Conformité'].toLowerCase() === 'oui').length;
    return Math.trunc((conformes / total) * 100);
  }

  function calculerDossierCloture(data) {
    const dossierCloture = data.filter(item => item['Dossier clôturé'].toLowerCase() === 'oui').length;
    return dossierCloture ;
  }

  function calculernonDossierCloture(data) {
    const dossierCloture = data.filter(item => item['Dossier clôturé'].toLowerCase() === 'non').length;
    return dossierCloture ;
  }
  const productionFormationsByMonth = (data) => {
    const months = [
      'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
      'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
    ];
    
    // Initialiser le tableau des mois avec des zéros
    const monthlyCounts = new Array(12).fill(0);
  
    // Parcourir les données
    data.forEach(item => {
      // Convertir la date au format Date
      const date = new Date(item.Date);
      
      // Obtenir le mois et l'année de la date
      const month = date.getMonth(); // 0 pour Janvier, 11 pour Décembre
      const year = date.getFullYear();
  
      // Vérifier si l'année correspond à l'année sélectionnée
      if (year === Number(selectedAnnee)) {
        // Incrémenter le compteur du mois correspondant
        monthlyCounts[month]++;
      }
    });
  
    return { labels: months, data: monthlyCounts };
  };
  
  const regrouperTracteursParNomAppareil = (data) => {
    return data.reduce((acc, panneItem) => {
      const designationTracteur = panneItem['Désignation_tracteur']; 
  
      if (!acc[designationTracteur]) {
        acc[designationTracteur] = {
          name: designationTracteur,
          production: 0,
        };
      }
      acc[designationTracteur].production += 1; 
  
      return acc;
    }, {});
  };
  
  const productionProductionsByCategory = (data) => {
    // Initialisation des compteurs pour chaque catégorie
    const productions = {
        TRACTEURS: {},
        AUTRES: {}
    };

    // Stockage des désignations d'articles pour chaque référence
    const articleDesignations = {};

    // Parcourir les données pour compter les articles par catégorie
    data.forEach(item => {
        const refArticle = item['Réf_Article'];
        const designationArticle = item['Désignation_Article'];

        // Stockage de la désignation d'article pour chaque référence
        if (!articleDesignations[refArticle]) {
            articleDesignations[refArticle] = designationArticle;
        }

        // Compteur pour chaque catégorie
        if (categories.TRACTEURS.includes(refArticle)) {
            productions.TRACTEURS[refArticle] = (productions.TRACTEURS[refArticle] || 0) + 1;
        } 
        else if (categories.AUTRES.includes(refArticle)) {
            productions.AUTRES[refArticle] = (productions.AUTRES[refArticle] || 0) + 1;
        }
    });

    // Convertir les compteurs en format attendu pour les graphiques
    const formatData = (categoryCounts) => {
        // Convertir les compteurs en tableau d'objets
        const dataArray = Object.keys(categoryCounts).map(refArticle => ({
            name: articleDesignations[refArticle] || refArticle, // Utilise la désignation ou la référence si non trouvée
            Production: categoryCounts[refArticle]
        }));

        // Trier les données par Production en ordre décroissant
        return dataArray.sort((a, b) => b.Production - a.Production);
    };

    // Calculer le nombre total pour chaque catégorie
    const totalTRACTEURS = Object.values(productions.TRACTEURS).reduce((acc, production) => acc + production, 0);
    const totalAUTRES = Object.values(productions.AUTRES).reduce((acc, production) => acc + production, 0);

    return {
        TRACTEURS: formatData(productions.TRACTEURS),
        AUTRES: formatData(productions.AUTRES),
        totalTRACTEURS,  // Nombre total de tracteurs
        totalAUTRES      // Nombre total d'articles dans la catégorie AUTRES
    };
};

function transformDataByPuissance(data) {
  let totalTracteurs = 0; // Initialiser le total des tracteurs
  const result = data.reduce((acc, item) => {
    if (item.Puissance !== null && item.Puissance !== undefined && item.Puissance !== '') {
      const puissance = item.Puissance;

      // Incrémenter le total des tracteurs
      totalTracteurs++;

      if (acc[puissance]) {
        acc[puissance].Production += 1;
      } else {
        acc[puissance] = {
          name: puissance,
          Production: 1,
        };
      }
    }
    return acc;
  }, {});

  // Correction de la construction de Dataresult
  const Dataresult = Object.keys(result).map(puissance => {
    return {
      name: puissance, // Utilise la désignation de l'article
      Production: result[puissance].Production, // Référence à la valeur correcte
    };
  });

  // Tri des résultats par Production de manière décroissante
  Dataresult.sort((a, b) => b.Production - a.Production);

  return { Dataresult, totalTracteurs }; // Retourner à la fois les données et le total
}

function transformDataByMarque(data) {
  let total_Tractors = 0;
  const result = data.reduce((acc, item) => {
    if (item.Marque !== null && item.Marque !== undefined && item.Marque !== '') {
      const marque = item.Marque;
      total_Tractors++;

      if (acc[marque]) {
        acc[marque].Production += 1;
      } else {
        acc[marque] = {
          name: marque,
          Production: 1,
        };
      }
    }
    return acc;
  }, {});

  // Correction de la construction de Dataresult
  const DataresultMarque = Object.keys(result).map(marque => {
    return {
      name: marque, // Utilise la désignation de l'article
      Production: result[marque].Production, // Référence à la valeur correcte
    };
  });

  // Tri des résultats par Production de manière décroissante
  DataresultMarque.sort((a, b) => b.Production - a.Production);
  return { DataresultMarque, total_Tractors };
}

  function Taux_conformite_par_tracteurs(data) {
    // Filtrage des tracteurs ayant une puissance définie
    const tracteursData = data.filter(item => item['Puissance']);
  
    // Variables pour calculer la moyenne globale
    let totalConformes = 0;
    let totalTracteurs = 0;
  
    // Regroupement par puissance
    const tracteursGroupes = tracteursData.reduce((acc, item) => {
      const puissance = item['Puissance'];
      if (!acc[puissance]) {
        acc[puissance] = {
          puissance: puissance, // Stocke la puissance
          total: 0, // Nombre total d'éléments
          conformes: 0 // Nombre d'éléments conformes
        };
      }
  
      // Incrémenter le total des tracteurs pour cette puissance
      acc[puissance].total++;
      totalTracteurs++; // Incrémenter le total général des tracteurs
  
      // Vérification de la conformité ('oui')
      if (item['Conformité'] && item['Conformité'].toLowerCase() === 'oui') {
        acc[puissance].conformes++;
        totalConformes++; // Incrémenter le total des tracteurs conformes
      }
      return acc;
    }, {});
  
    // Calcul du taux de conformité pour chaque puissance
    const tauxParPuissance = Object.keys(tracteursGroupes).map(puissance => {
      const groupe = tracteursGroupes[puissance];
      const Conformite = (groupe.conformes / groupe.total) * 100; // Calcul du pourcentage
      return {
        name: puissance,
        Conformite: Math.trunc(Conformite), // Arrondir à 2 décimales
        ConformiteLabel: `${Math.trunc(Conformite)}%`
      };
    });
  
    // Tri par taux de conformité décroissant
    tauxParPuissance.sort((a, b) => b.Conformite - a.Conformite);
  
    // Calcul de la moyenne des conformités globales
    const moyenneConformite = (totalConformes / totalTracteurs) * 100;
  
    return {
      tauxParPuissance, // Les taux par puissance
      moyenneConformite: Math.trunc(moyenneConformite) // La moyenne arrondie à l'entier
    };
  }  

function Taux_conformite_par_marques(data) {
  // Filtrage des tracteurs ayant une puissance définie
  const tracteursData = data.filter(item => item['Marque']);

  // Regroupement par puissance
  const tracteursGroupes = tracteursData.reduce((acc, item) => {
      const marque = item['Marque'];
      if (!acc[marque]) {
          acc[marque] = {
              marque: marque, // Stocke la marque
              total: 0, // Nombre total d'éléments
              conformes: 0 // Nombre d'éléments conformes
          };
      }
      
      // Incrémenter le total des tracteurs pour cette marque
      acc[marque].total++;
      
      // Vérification de la conformité ('oui')
      if (item['Conformité'] && item['Conformité'].toLowerCase() === 'oui') {
          acc[marque].conformes++;
      }
      return acc;
  }, {});

  // Calcul du taux de conformité pour chaque marque
  const tauxParMarque = Object.keys(tracteursGroupes).map(marque => {
      const groupe = tracteursGroupes[marque];
      const Conformite = (groupe.conformes / groupe.total) * 100; // Calcul du pourcentage
      return {
          name: marque,
          Conformite: Math.trunc(Conformite), // Arrondir à 2 décimales
          ConformiteLabel: `${Math.trunc(Conformite)}%`
      };
  });

  // Tri par taux de conformité décroissant
  tauxParMarque.sort((a, b) => b.Conformite - a.Conformite);

  return tauxParMarque;
}

const regrouperArticlesParCategorie = (data) => {
  // Regroupement des articles en sous-catégories
  const regroupements = {
      Charrues: {},
      Remorques: {},
      Cultivateurs: {},
      Semoirs: {},
      Autres: {}
  };

  data.forEach(item => {
      const refArticle = item['Réf_Article'];
      const designationArticle = item['Désignation_Article'];
      let categorie = 'Autres'; // Valeur par défaut

      // Vérifiez si l'article appartient à la catégorie AUTRES
      if (categories.AUTRES.includes(refArticle)) {
          // Déterminez la sous-catégorie basée sur la désignation de l'article
          if (designationArticle.includes('Charrue')) {
              categorie = 'Charrues';
          } else if (designationArticle.includes('Remorque')) {
              categorie = 'Remorques';
          } else if (designationArticle.includes('Cultivateur')) {
              categorie = 'Cultivateurs';
          } else if (designationArticle.includes('Semoir')) {
              categorie = 'Semoirs';
          }

          // Comptage des articles dans la sous-catégorie appropriée
          if (!regroupements[categorie][designationArticle]) {
              regroupements[categorie][designationArticle] = 0;
          }
          regroupements[categorie][designationArticle] += 1;
      }
  });

  // Convertir les résultats en format utilisable pour le graphique
  const resultats = Object.keys(regroupements).map(categorie => ({
      name: categorie,
      production: Object.values(regroupements[categorie]).reduce((acc, val) => acc + val, 0)
  }));

  return resultats;
};
const formatDuration = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const mins = Math.round(minutes % 60);
  return `${hours} h ${mins} min`;
};

const calculateCounts = (data) => {
  let totalCounts = {}; // Pour stocker les totaux par puissance
  let totalConformity = { production: 0, conformity: 0 };
  let totalOperators = 0;
  let totalItems = 0;

  data.forEach(item => {
    // Vérifier si Puissance est valide
    const puissance = item.Puissance;
    if (puissance !== null && puissance !== undefined && puissance !== '') {
      const isConform = item['Conformité'].toLowerCase() === 'oui';
      const numOperators = parseFloat(item['Nbre_Opérateurs']) || 0;

      // Initialiser la durée à 0 par défaut
      let duration = 0;

      // Vérifier si les dates et heures sont valides
      if (item.Date_Début && item.Heure_Début && item.Date_Fin && item.Heure_Fin) {
        const startTime = new Date(`${item.Date_Début.split(' ')[0]}T${item.Heure_Début}`);
        const endTime = new Date(`${item.Date_Fin.split(' ')[0]}T${item.Heure_Fin}`);

        // Si les dates sont valides, calculer la durée
        if (!isNaN(startTime) && !isNaN(endTime)) {
          if (endTime < startTime) {
            duration = (startTime - endTime) / 60000; // en minutes
          } else {
            duration = (endTime - startTime) / 60000; // en minutes
          }
        }
      }

      // Initialiser l'objet totalCounts pour la puissance, s'il n'existe pas encore
      if (!totalCounts[puissance]) {
        totalCounts[puissance] = {
          production: 0,
          opCount: 0,
          duration: 0,
          items: 0,
          conformity: 0
        };
      }

      totalCounts[puissance].production++;
      totalCounts[puissance].opCount += numOperators;
      totalCounts[puissance].duration += duration; // Ajouter la durée calculée
      totalCounts[puissance].items++;
      totalItems++;
      totalOperators += numOperators;

      if (isConform) {
        totalCounts[puissance].conformity++;
      }

      totalConformity.production++;
      if (isConform) {
        totalConformity.conformity++;
      }
    }
  });

  const averageOperators = {};
  const averageDurations = {};
  const conformityData = [];

  Object.keys(totalCounts).forEach(puissance => {
    averageOperators[puissance] = totalCounts[puissance].items ?
      (totalCounts[puissance].opCount / totalCounts[puissance].items).toFixed(2) : '0.00';

    // Calculer la durée moyenne seulement si des items existent
    averageDurations[puissance] = totalCounts[puissance].items ? 
      formatDuration(totalCounts[puissance].duration / totalCounts[puissance].items) : '0:00';
    const conformityPercentage = totalCounts[puissance].production ? 
      (totalCounts[puissance].conformity / totalCounts[puissance].production * 100).toFixed(2) : 0;

    conformityData.push({
      id: puissance,
      labelBar: `${conformityPercentage}%`,
      label: puissance,
      value: conformityPercentage
    });
  });

  // Calculer le pourcentage de conformité global
  const totalConformityPercentage = totalConformity.production ? 
    (totalConformity.conformity / totalConformity.production * 100).toFixed(2) : 0;
  const generalAverageOperators = totalItems ? (totalOperators / totalItems).toFixed(2) : '0.00';
  setAverageOperators(averageOperators);
  setAverageDurations(averageDurations);
  setConformityData(conformityData.sort((a, b) => b.value - a.value));
  setGeneralAverageOperators(generalAverageOperators);
  setTotalConformityPercentage(totalConformityPercentage);
};
const calculateCountsMarque = (data) => {
  let totalCounts = {}; // Pour stocker les totaux par puissance
  let totalConformity = { production: 0, conformity: 0 };
  let totalOperators = 0;
  let totalItems = 0;

  data.forEach(item => {
    // Vérifier si Puissance est valide
    const marque = item.Marque;
    if (marque !== null && marque !== undefined && marque !== '') {
      const isConform = item['Conformité'].toLowerCase() === 'oui';
      const numOperators = parseFloat(item['Nbre_Opérateurs']) || 0;

      // Initialiser la durée à 0 par défaut
      let duration = 0;

      // Vérifier si les dates et heures sont valides
      if (item.Date_Début && item.Heure_Début && item.Date_Fin && item.Heure_Fin) {
        const startTime = new Date(`${item.Date_Début.split(' ')[0]}T${item.Heure_Début}`);
        const endTime = new Date(`${item.Date_Fin.split(' ')[0]}T${item.Heure_Fin}`);

        // Si les dates sont valides, calculer la durée
        if (!isNaN(startTime) && !isNaN(endTime)) {
          if (endTime < startTime) {
            duration = (startTime - endTime) / 60000; // en minutes
          } else {
            duration = (endTime - startTime) / 60000; // en minutes
          }
        }
      }

      // Initialiser l'objet totalCounts pour la marque, s'il n'existe pas encore
      if (!totalCounts[marque]) {
        totalCounts[marque] = {
          production: 0,
          opCount: 0,
          duration: 0,
          items: 0,
          conformity: 0
        };
      }

      totalCounts[marque].production++;
      totalCounts[marque].opCount += numOperators;
      totalCounts[marque].duration += duration; // Ajouter la durée calculée
      totalCounts[marque].items++;
      totalItems++;
      totalOperators += numOperators;

      if (isConform) {
        totalCounts[marque].conformity++;
      }

      totalConformity.production++;
      if (isConform) {
        totalConformity.conformity++;
      }
    }
  });

  const averageOperatorsMarque = {};
  const averageDurationsMarque = {};
  const conformityDataMarque = [];

  Object.keys(totalCounts).forEach(marque => {
    averageOperatorsMarque[marque] = totalCounts[marque].items ?
      (totalCounts[marque].opCount / totalCounts[marque].items).toFixed(2) : '0.00';

    // Calculer la durée moyenne seulement si des items existent
    averageDurationsMarque[marque] = totalCounts[marque].items ? 
      formatDuration(totalCounts[marque].duration / totalCounts[marque].items) : '0:00';
    const conformityPercentage = totalCounts[marque].production ? 
      (totalCounts[marque].conformity / totalCounts[marque].production * 100).toFixed(2) : 0;

    conformityDataMarque.push({
      id: marque,
      labelBar: `${conformityPercentage}%`,
      label: marque,
      value: conformityPercentage
    });
  });

  // Calculer le pourcentage de conformité global
  const totalConformityPercentage = totalConformity.production ? 
    (totalConformity.conformity / totalConformity.production * 100).toFixed(2) : 0;
  const generalAverageOperators = totalItems ? (totalOperators / totalItems).toFixed(2) : '0.00';
  setAverageOperatorsMarque(averageOperatorsMarque);
  setAverageDurationsMarque(averageDurationsMarque);
  setConformityDataMarque(conformityDataMarque.sort((a, b) => b.value - a.value));
  setGeneralAverageOperatorsMarque(generalAverageOperators);
  setTotalConformityPercentageMarque(totalConformityPercentage);
};
  useEffect(() => {
    fetchData(statistiqueUrl);
    fetchData2(prodIndust);
    fetchData3(panne);
    fetchData6(panne);
    fetchData7(prodIndust);
  }, []);
  
  useEffect(() => {
    async function loadData() {
      try {
        await fetchData66();
      } catch (error) {
        console.error('Error loading data:', error);
        setBarData2([]); 
      }
    }

    loadData();
  }, []);
  
    useEffect(() => {
      async function loadData() {
        try {
          await fetchData77();
        } catch (error) {
          console.error('Error loading data:', error);
          setBarData([]); 
        }
      }

      loadData();
    }, []);
  

  useEffect(() => {
    if (selectedAnnee) {
      fetchData2(prodIndust);
    }
  }, [selectedAnnee]);
  
  const fetchData = async (url) => {
    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error('Network response was not ok');
      const data = await response.json();
      setTotal_T(data["Total"]);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchData2 = async (url) => {
    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error('Network response was not ok');
      const data = await response.json();
      setTotalProduit(data.length);
      calculateCounts(data);
      calculateCountsMarque(data);
      const sortedData = data.sort((a, b) => new Date(b.Date) - new Date(a.Date));
      setProdData(sortedData);
      const { Dataresult, totalTracteurs } = transformDataByPuissance(data);
      setDataPuissance(Dataresult);
      setTotalTracteur(totalTracteurs);
      const { DataresultMarque, total_Tractors } = transformDataByMarque(data);
      setdataMarque(DataresultMarque);
      setTotalTractors(total_Tractors);

        const { labels, data: monthlyData } = productionFormationsByMonth(data);
        setChartData({
          labels,
          data: monthlyData,
        });
      const barChartData = productionProductionsByCategory(data);
      setBarData(barChartData);
      const { tauxParPuissance, moyenneConformite } = Taux_conformite_par_tracteurs(data);
      settrackConf(tauxParPuissance); // Mettre à jour les données de conformité par puissance
      setconforme(moyenneConformite);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchData3 = async (url) => {
    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error('Network response was not ok');
      const data = await response.json();
      setTotalPanne(data.length);
      setcloture(calculerDossierCloture(data));
      setnoncloture(calculernonDossierCloture(data));
      
        const { labels, data: monthlyData } = productionFormationsByMonth(data);
        setChartDataPannes({
          labels,
          data: monthlyData,
        });
      const barChartData = productionProductionsByCategory(data);
      setBarData(barChartData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const fetchData7 = async (url) => {
    try {
        const response = await fetch(url);
        if (!response.ok) throw new Error('Failed to fetch data');
        const data = await response.json();
        
        const articlesParCategorie = regrouperArticlesParCategorie(data);
        
        const articlesParCategorieTrie = articlesParCategorie.sort((a, b) => b.production - a.production);
        
        setArticlesParCategorie(articlesParCategorieTrie);
    } catch (error) {
        console.error('Error fetching and processing data:', error);
    }
};

  async function fetchData66() {
    try {
      const responseProd = await fetch(prodIndust);
      if (!responseProd.ok) throw new Error('Failed to fetch data from panne');
      const dataProd = await responseProd.json();
      
      const responseTractor = await fetch(tractor);
      if (!responseTractor.ok) throw new Error('Failed to fetch data from tractor');
      const dataTractor = await responseTractor.json();
      
      const tractorMarkCounts = dataProd.reduce((acc, panneItem) => {
        const matchingTractor = dataTractor.find(tractorItem => tractorItem.id === panneItem.Numéro_chassis);
  
        if (matchingTractor) {
          const { tractorMark } = matchingTractor;
          if (!acc[tractorMark]) {
            acc[tractorMark] = 0;
          }
          acc[tractorMark]++;
        }
  
        return acc;
      }, {});
      
      // Convert to array, sort in descending order, and calculate total production
      const barChartData = Object.keys(tractorMarkCounts).map((tractorMark) => ({
        tractorMark,
        Production: tractorMarkCounts[tractorMark],
      })).sort((a, b) => b.Production - a.Production);
  
      // Calculer le nombre total de tracteurs
      const totalTractors = Object.values(tractorMarkCounts).reduce((sum, production) => sum + production, 0);
  
      // Mettre à jour l'état avec les nouvelles données et le total
      setBarData2(barChartData);
  
    } catch (error) {
      console.error('Error fetching and processing data:', error);
    }
  }
  
  const fetchData6 = async (urlPanne) => {
    try {
      const response = await fetch(urlPanne);
      if (!response.ok) throw new Error('Failed to fetch data from panne');
      const dataPanne = await response.json();
  
      const tracteursParNomAppareil = regrouperTracteursParNomAppareil(dataPanne);
  
      const tracteursParNomAppareilData = Object.keys(tracteursParNomAppareil)
        .map(nomAppareil => ({
          name: nomAppareil, 
          production: tracteursParNomAppareil[nomAppareil].production,
          label: `${tracteursParNomAppareil[nomAppareil].production}`, 
        }))
        .sort((a, b) => b.production - a.production);

      setTracteurPuissance(tracteursParNomAppareilData);
    } catch (error) {
      console.error('Error fetching and processing data:', error);
    }
  };
  async function fetchData77() {
    try {
      const responseProdIndust = await fetch(prodIndust);
      if (!responseProdIndust.ok) throw new Error('Failed to fetch data from prodIndust');
      const dataProdIndust = await responseProdIndust.json();
  
      const responseTractor = await fetch(tractor);
      if (!responseTractor.ok) throw new Error('Failed to fetch data from tractor');
      const dataTractor = await responseTractor.json();
  
      const tracteursGroupesParMarque = dataProdIndust.reduce((acc, prodItem) => {
        const matchingTractor = dataTractor.find(tractorItem => tractorItem.id === prodItem.Numéro_chassis);
        if (matchingTractor) {
          const { tractorMark } = matchingTractor;
          if (!acc[tractorMark]) {
            acc[tractorMark] = {
              tractorMark,
              items: [],
            };
          }
          acc[tractorMark].items.push(prodItem);
        }
        return acc;
      }, {});
  
      const tauxParMarque = Object.keys(tracteursGroupesParMarque).map(tractorMark => {
        const groupe = tracteursGroupesParMarque[tractorMark];
        const taux = calculerTauxConformite2(groupe.items);
        return {
          name: tractorMark,
          Conformite: taux,
          ConformiteLabel: `${taux}%`,
        };
      });
  
      const tauxParMarqueTries = tauxParMarque.sort((a, b) => b.Conformite - a.Conformite);
  
      // Calculer la moyenne du Conformite
      const totalTaux = tauxParMarqueTries.reduce((sum, marque) => sum + marque.Conformite, 0);
      const moyenneTauxConformite = tauxParMarqueTries.length > 0 ? Math.trunc((totalTaux / tauxParMarqueTries.length)) : 0;
  
      // Mettre à jour l'état
      settrackConf2(Taux_conformite_par_marques(dataProdIndust));
      setAvgTauxConformite(moyenneTauxConformite); // Stocker la moyenne
    } catch (error) {
      console.error('Error fetching and processing data:', error);
    }
  }
  const handleAnneeChange = (event, value) => {
    setSelectedAnnee(value?.label || null);
  };
  const isXs = useMediaQuery('(max-width:600px)');
  const isXl = useMediaQuery('(min-width:1200px)');
  return (
    <Box sx={{ display: 'flex' }}>
    <SideBar open={sidebarOpen} onClose={() => setSidebarOpen(false)} />
    <Box component="main" sx={{ flexGrow: 1, p: { xs: 1, sm: 2, md: 3 } }}>
      <Toolbar />
      <Box display="flex" justifyContent="space-between" mb={3}>
        <CalendarButton />
        <ButtonComponent />
      </Box>
        <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 3, borderColor: 'divider' }}>
              <Nav variant="tabs" className="flex-column flex-sm-row">
                {['Production', 'Qualité'].map((label, index) => (
                  <Nav.Item key={index}>
                    <Nav.Link
                      eventKey={`${index + 1}`}
                      active={tabValue === `${index + 1}`}
                      onClick={() => handleTabChange(null, `${index + 1}`)}
                      style={{
                        color: tabValue === `${index + 1}` ? '#F6CA11' : '#02834A',
                        fontSize: '1.2rem',
                        textTransform: 'none',
                      }}
                    >
                      {label}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Box>
          <TabPanel value="1">
            <Box display="flex" alignItems="center" mb={2}>
                <Autocomplete
                  options={vagueOptions}
                  onChange={handleVagueChange}
                  renderInput={(params) => <TextField {...params} label="Filtre" color='success' size='small' />}
                  sx={{ width: 200, ml: 2 }} />
                
              </Box>
              {selectedVague === "" && (
              <>
                  <div style={{ textAlign: 'center', marginBottom: isXs ? '15px': ''}}>
                  <span style={{ marginLeft: '10px', color: '#02834A', fontWeight: 'bold', fontSize: isXs ? '1rem' : '2.5rem', }}>{formattedDateWithCapitalizedMonth}</span>
                </div>
              <Grid container spacing={3}>
               <Grid item xs={12} md={6}>
                  <Paper  elevation={3} 
                  sx={{width: { xs: '100%', sm: '90%', md: 'auto' }, maxWidth: { xs: 350, sm: 500, md: isXl ? 800 :600 }, height: { xs: 380, sm: 400, md: 400 },        
                  padding: 2,
                  borderRadius: 3,
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)'}}
                  >
                    <div style={{ textAlign: 'center' }}>
                      <span style={{ fontWeight: 'bold', fontSize: isXs ? '1rem' : '1.5rem'}}>Equipements motorisés</span>
                      <span style={{ marginLeft: '10px', color: '#02834A', fontWeight: 'bold', fontSize: isXs ? '1rem' : '2.5rem',}}>({total_tracteur})</span>
                    </div>
                    {isXs && <div style={{ marginTop: '20px' }} />}

                    {dataPuissance&& dataPuissance.length > 0 ? (
                      <>
                        <ResponsiveContainer width="100%" height={300}>
                          <BarChart
                            layout="vertical"
                            data={dataPuissance}
                            margin={isXs ? {} : { top: 5, left: 10, bottom: 2 }} 
                            >
                            <XAxis type="number" axisLine={false} tick={false} />
                            <YAxis
                              type="category"
                              dataKey="name"
                              axisLine={false}
                              tickMargin={isXs ? 10 : 20}
                              tickLine={false}
                              width={isXs ? 80 : 100}
                              tick={{fontSize: 16, fontFamily: 'Times New Roman', fontWeight: 'bold' }}
                            />
                            <Tooltip />
                            <Bar dataKey="Production" fill="#F6CA11" radius={[5, 5, 5, 5]} minPointSize={60}>
                              <LabelList
                                dataKey="Production"
                                position="insideEnd"
                                fill="#fff"
                                fontSize={18}
                                offset={10}
                              />
                            </Bar>
                          </BarChart>
                        </ResponsiveContainer>
                        {!isXs && <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '-20px'}}>
                          <FlecheComponent currentMonthValue={total_tracteur} previousMonthValue={200} />
                        </div>}
                      </>
                    ) : (
                      <Typography sx={{ textAlign: 'center', padding: 2 }}>Aucune donnée</Typography>
                    )}
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                <Paper  elevation={3} 
                  sx={{width: { xs: '100%', sm: '90%', md: 'auto' }, maxWidth: { xs: 350, sm: 500, md: isXl ? 800 : 600 }, height: { xs: 380, sm: 400, md: 400 },        
                  padding: 2,
                  borderRadius: 3,
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)'}}
                  >
                   <div style={{ textAlign: 'center' }}>
                      <span style={{ fontWeight: 'bold', fontSize: isXs ? '1rem' : '1.5rem' }}>Conformité des tracteurs</span>
                      <span style={{ marginLeft: '10px', color: '#02834A', fontWeight: 'bold', fontSize: isXs ? '1rem' : '2.5rem', }}>({`${conformite}%`})</span>
                    </div>
                    {isXs && <div style={{ marginTop: '15px' }} />}
                    {trackConf && trackConf.length > 0 ? (
                      <>
                      <ResponsiveContainer width="100%" height={300}>
                        <BarChart
                          layout="vertical"
                          data={trackConf}
                          margin={isXs ? {} : { top: 5, left: 20, bottom: 2 }} 
                          >
                          <XAxis type="number" axisLine={false} tick={false} />
                          <YAxis
                            type="category"
                            dataKey="name"
                            axisLine={false}
                            tickMargin={isXs ? 10 : 20}
                            tickLine={false}
                            width={isXs ? 80 : 100}
                            tick={{ fontSize: 16, fontFamily: 'Times New Roman', fontWeight:'bold' }}
                             />
                          <Tooltip />
                          <Bar dataKey="Conformite" fill="#F6CA11" radius={[5, 5, 5, 5]} minPointSize={60}>
                            <LabelList
                              dataKey="ConformiteLabel"
                              position="insideEnd"
                              fill="#fff"
                              fontSize={18} />
                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>
                      {!isXs && <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '-20px'}}>
                      <FlecheComponent currentMonthValue={conformite} previousMonthValue={'100%'}/>
                     </div>}
                      </>
                    ) : (
                      <Typography sx={{ textAlign: 'center', padding: 2 }}>Aucune donnée</Typography>
                    )}
                  </Paper>
                </Grid>
              </Grid>
              <Modal
              open={showMore}
              onClose={handleShowMore}
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft:20 }}
            >
              <Box 
                sx={{ 
                  bgcolor: 'background.paper', 
                  boxShadow: 24, 
                  p: 2, 
                  borderRadius: 2, 
                  outline: 'none',
                  width:900,
                  height:500,
                  overflow:'auto'
                }}
              >
                <div style={{ textAlign: 'center' }}>
                            <span style={{ fontWeight: 'bold', fontSize: isXs ? '1rem': '1.5rem' }}>Equipements non motorisés</span>
                            <span style={{ marginLeft: '10px', color: '#02834A', fontWeight: 'bold', fontSize: isXs ? '1rem' : '2.5rem', }}>({barData.totalAUTRES})</span>
                          </div>
                {barData.AUTRES && barData.AUTRES.length > 0 ? (
                  <ResponsiveContainer width={'100%'} height={400}>
                    <BarChart
                      layout="horizontal"
                      data={barData.AUTRES}
                      width={500}
                      height={300}
                      margin={{ right: 20, left: 10, bottom: 50 }}
                    >
                      <YAxis type="number" axisLine={false} tick={false} />
                      <XAxis type="category" dataKey="name" axisLine={false} tickMargin={25} tickLine={false} interval={2} tick={{ angle: -15, fontSize: 14, fontWeight:'bold' }} />
                      <Tooltip />
                      <Bar dataKey="Production" fill="#F6CA11" radius={[5, 5, 5, 5]} minPointSize={60}>
                        <LabelList
                          dataKey="Production"
                          position="top"
                          fill="#F6CA11"
                          offset={5} 
                          fontSize={14} />
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                ) : (
                  <Typography sx={{ textAlign: 'center', padding: 2 }}>Aucune donnée</Typography>
                )}
              </Box>
               </Modal>        
                <Grid item xs={12} md={6} mt={3} lg={12}>
                <Paper  elevation={3} 
                  sx={{width: { xs: '100%', sm: '90%', md: 'auto' }, maxWidth: { xs: 350, sm: 500, md: isXl ? '100%' : 1000 }, height: { xs: 380, sm: 420, md: isXl ? 520: 420 },        
                  padding: 2,
                  borderRadius: 3,
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                  alignContent:'center'
                }}
                  >
                  <div style={{ textAlign: 'center' }}>
                  <span style={{ fontWeight: 'bold', fontSize: isXs ? '1rem': '1.5rem' }}>Equipements non motorisés par catégorie</span>
                  <span style={{ marginLeft: '10px', color: '#02834A', fontWeight: 'bold', fontSize: isXs ? '1rem' : '2.5rem', }}>({barData.totalAUTRES})</span>
                    </div>
                    {articlesParCategorie && articlesParCategorie.length > 0 ? (
                      <ResponsiveContainer width="100%" height={isXl ? 400:300}>
                        <BarChart
                          data={articlesParCategorie}
                          margin={isXs ? {} : { top: 5, right: 30, left: 20, bottom: 5 }} 
                        >
                          <XAxis type="category" dataKey="name" tick={{fontSize: 14, fontWeight:'bold' }}/>
                          <YAxis tick={{fontSize: 14, fontWeight:'bold' }}/>
                          <Tooltip />
                          <Bar dataKey="production" fill="#F6CA11" radius={[5, 5, 5, 5]} minPointSize={60}>
                            <LabelList dataKey="production" position="insideEnd" fill="#fff" fontSize={18}/>
                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>
                    ) : (
                      <Typography sx={{ textAlign: 'center', padding: 2 }}>Aucune donnée</Typography>
                    )}
                 {!isXs && <Box textAlign="center" mt={2}>
                    <Typography 
                      component="span" 
                      onClick={handleShowMore} 
                      sx={{ 
                        cursor: 'pointer', 
                        color: '#F6CA11', 
                        textDecoration: 'underline',
                        fontFamily:'Times New Roman',
                        fontSize:'1.2rem'
                      }}
                    >
                      {showMore ? 'Voir moins...' : 'Voir plus...'}
                    </Typography>
                  </Box>}
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6} mt={2}>
                <Typography variant="h6"  sx={{ 
                      fontWeight: 'bold', 
                      fontSize: { xs: '1rem', sm: '1.2rem', md: '1.3rem' },
                      textAlign: 'center',
                      mb: { xs: 2, sm: 3 }
                      }}> Nombre de production par mois</Typography>
                  <Box mb={3}>
                    <Autocomplete
                      options={anneeOptions}
                      getOptionLabel={(option) => option.label}
                      onChange={handleAnneeChange}
                      renderInput={(params) => <TextField {...params} label="Année" color='success' size='small' />}
                      sx={{ width: 100 }} />
                  </Box>
                  <ResponsiveContainer width="100%" sx={{  maxWidth: {
                    xs: '100%',  
                    sm: '100%', 
                    md:'800px'
                  }}} 
                  height={400}> 
                  <LineChart data={chartData.data.map((Production, index) => ({ month: chartData.labels[index], Production }))}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="Production" stroke="#F6CA11" activeDot={{ r: 6 }}/>
                  </LineChart>
                  </ResponsiveContainer>
                </Grid></>
 )}
          {selectedVague === "Marques" && (
           <>
                 <div style={{ textAlign: 'center'}}>
                  <span style={{ marginLeft: '10px', color: '#02834A', fontWeight: 'bold', fontSize: isXs ? '1rem': '2.5rem', }}>{formattedDateWithCapitalizedMonth}</span>
                </div>
            <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
            <Paper
                elevation={3}
                sx={{
                  width: { xs: '100%', sm: '90%', md: 'auto' },
                  maxWidth: { xs: 350, sm: 500, md: isXl ? 800 :600 },
                  height: { xs: 270, sm: 300, md: 300 },
                  padding: 2,
                  borderRadius: 3,
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                }}
              >
                <div style={{ textAlign: 'center' }}>
                      <span style={{ fontWeight: 'bold', fontSize: isXs ? '1rem': '1.5rem' }}>Production par marque</span>
                      <span style={{ marginLeft: '10px', color: '#02834A', fontWeight: 'bold', fontSize: isXs ? '1rem' : '2.5rem', }}>({200})</span>
                    </div>
                  {dataMarque.length > 0 ? (
                     <>
                    <ResponsiveContainer width='100%' height={200}>
                      <BarChart
                        layout="vertical"
                        data={dataMarque}
                        margin={isXs ? {} : { top: 5, left: 10, bottom: 2 }} 
                        >
                        <XAxis type="number" axisLine={false} tick={false} />
                        <YAxis
                          type="category"
                          dataKey="name"
                          axisLine={false}
                          tickMargin={isXs ? 10 :20}
                          width={isXs ? 120 : 130}
                          tickLine={false}
                          tick={{ fontSize: 16, fontFamily: 'Times New Roman', fontWeight: 'bold' }}
                           />
                        <Tooltip />
                        <Bar dataKey="Production" fill="#F6CA11" radius={[5, 5, 5, 5]} barSize={40} minPointSize={60}>
                        <LabelList
                              dataKey="Production"
                              position="insideEnd"
                              fill="#fff"
                              fontSize={18} />
                        </Bar>
                      </BarChart>
                    </ResponsiveContainer>
                    {!isXs && <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '-20px'}}>
                    <FlecheComponent currentMonthValue={200} previousMonthValue={900} />
                      </div>}
                     </>
                  ) : (
                    <Typography sx={{ textAlign: 'center', padding: 2 }}>Aucune donnée</Typography>
                  )}
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
              <Paper
                elevation={3}
                sx={{
                  width: { xs: '100%', sm: '90%', md: 'auto' },
                  maxWidth: { xs: 350, sm: 500, md: isXl ? 800 :600 },
                  height: { xs: 270, sm: 300, md: 300 },
                  padding: 2,
                  borderRadius: 3,
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                }}
              >                
              <div style={{ textAlign: 'center' }}>
                      <span style={{ fontWeight: 'bold', fontSize: isXs ? '1rem' : '1.5rem' }}>Conformité par marque</span>
                      <span style={{ marginLeft: '10px', color: '#02834A', fontWeight: 'bold', fontSize: isXs ? '1rem' : '2.5rem', }}>({avgTauxConformite}%)</span>
                    </div>
                    {isXs && <div style={{ marginTop: '15px' }} />}

                  {trackConf2 && trackConf2.length > 0 ? (
                    <>
                    <ResponsiveContainer width="100%" height={200}>
                      <BarChart
                        layout="vertical"
                        data={trackConf2}
                        margin={isXs ? {} : { top: 5, left: 10, bottom: 2 }} 
                      >
                        <XAxis type="number" axisLine={false} tick={false} />
                        <YAxis
                          type="category"
                          dataKey="name"
                          axisLine={false}
                          tickMargin={isXs ? 10 :20}
                          width={isXs ? 120 : 130}
                          tickLine={false}
                          tick={{ fontSize: 14, fontFamily: 'Times New Roman', fontWeight:'bold' }}
                           />
                        <Tooltip />
                        <Bar dataKey="Conformite" fill="#F6CA11" radius={[5, 5, 5, 5]} barSize={40} minPointSize={60}>
                            <LabelList
                              dataKey="ConformiteLabel"
                              position="insideEnd"
                              fill="#fff"
                              fontSize={18} />
                        </Bar>
                      </BarChart>
                    </ResponsiveContainer>
                    {!isXs && <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '-20px'}}>
                    <FlecheComponent currentMonthValue={avgTauxConformite} previousMonthValue={'100%'} />
                     </div>}
                     </>
                  ) : (
                    <Typography sx={{ textAlign: 'center', padding: 2 }}>Aucune donnée</Typography>
                  )}
                </Paper>
              </Grid>
              </Grid>
                <Grid item xs={12} md={6} mt={2}>
                <Typography variant="h6"  sx={{ 
                      fontWeight: 'bold', 
                      fontSize: { xs: '1rem', sm: '1.2rem', md: '1.3rem' },
                      textAlign: 'center',
                      mb: { xs: 2, sm: 3 }
                      }}> Nombre de production par mois</Typography>
                  <Box mb={3}>
                    <Autocomplete
                      options={anneeOptions}
                      getOptionLabel={(option) => option.label}
                      onChange={handleAnneeChange}
                      renderInput={(params) => <TextField {...params} label="Année" color='success' size='small' />}
                      sx={{ width: 100 }} />
                  </Box>
                  <ResponsiveContainer width="100%" sx={{  maxWidth: {
                    xs: '100%',  
                    sm: '100%', 
                    md:'800px'
                  }}} 
                  height={400}> 
                  <LineChart data={chartData.data.map((Production, index) => ({ month: chartData.labels[index], Production }))}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="Production" stroke="#F6CA11" activeDot={{ r: 6 }}/>
                  </LineChart>
                  </ResponsiveContainer>
                </Grid>
                </>
          )}

          {selectedVague === "Puissance motrice" && (
            <>
                  <div style={{ textAlign: 'center'}}>
                  <span style={{ marginLeft: '10px', color: '#02834A', fontWeight: 'bold', fontSize:isXs ? '1rem' : '2.5rem', }}>{formattedDateWithCapitalizedMonth}</span>
                </div>
                <Grid container spacing={3}>
               <Grid item xs={12} md={6}>
                  <Paper  elevation={3} 
                  sx={{width: { xs: '100%', sm: '90%', md: 'auto' }, maxWidth: { xs: 350, sm: 500, md: isXl ? 800 :600 }, height: { xs: 380, sm: 400, md: 400 },        
                  padding: 2,
                  borderRadius: 3,
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)'}}
                  >
                    <div style={{ textAlign: 'center' }}>
                      <span style={{ fontWeight: 'bold', fontSize: isXs ? '1rem' : '1.5rem'}}>Equipements motorisés</span>
                      <span style={{ marginLeft: '10px', color: '#02834A', fontWeight: 'bold', fontSize: isXs ? '1rem' : '2.5rem',}}>({total_tracteur})</span>
                    </div>
                    {isXs && <div style={{ marginTop: '20px' }} />}

                    {dataPuissance&& dataPuissance.length > 0 ? (
                      <>
                        <ResponsiveContainer width="100%" height={300}>
                          <BarChart
                            layout="vertical"
                            data={dataPuissance}
                            margin={isXs ? {} : { top: 5, left: 10, bottom: 2 }} 
                            >
                            <XAxis type="number" axisLine={false} tick={false} />
                            <YAxis
                              type="category"
                              dataKey="name"
                              axisLine={false}
                              tickMargin={isXs ? 10 : 20}
                              tickLine={false}
                              width={isXs ? 80 : 100}
                              tick={{fontSize: 16, fontFamily: 'Times New Roman', fontWeight: 'bold' }}
                            />
                            <Tooltip />
                            <Bar dataKey="Production" fill="#F6CA11" radius={[5, 5, 5, 5]} minPointSize={60}>
                              <LabelList
                                dataKey="Production"
                                position="insideEnd"
                                fill="#fff"
                                fontSize={18}
                                offset={10}
                              />
                            </Bar>
                          </BarChart>
                        </ResponsiveContainer>
                        {!isXs && <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '-20px'}}>
                          <FlecheComponent currentMonthValue={total_tracteur} previousMonthValue={200} />
                        </div>}
                      </>
                    ) : (
                      <Typography sx={{ textAlign: 'center', padding: 2 }}>Aucune donnée</Typography>
                    )}
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                <Paper  elevation={3} 
                  sx={{width: { xs: '100%', sm: '90%', md: 'auto' }, maxWidth: { xs: 350, sm: 500, md: isXl ? 800 : 600 }, height: { xs: 380, sm: 400, md: 400 },        
                  padding: 2,
                  borderRadius: 3,
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)'}}
                  >
                   <div style={{ textAlign: 'center' }}>
                      <span style={{ fontWeight: 'bold', fontSize: isXs ? '1rem' : '1.5rem' }}>Conformité des tracteurs</span>
                      <span style={{ marginLeft: '10px', color: '#02834A', fontWeight: 'bold', fontSize: isXs ? '1rem' : '2.5rem', }}>({`${conformite}%`})</span>
                    </div>
                    {isXs && <div style={{ marginTop: '15px' }} />}
                    {trackConf && trackConf.length > 0 ? (
                      <>
                      <ResponsiveContainer width="100%" height={300}>
                        <BarChart
                          layout="vertical"
                          data={trackConf}
                          margin={isXs ? {} : { top: 5, left: 20, bottom: 2 }} 
                          >
                          <XAxis type="number" axisLine={false} tick={false} />
                          <YAxis
                            type="category"
                            dataKey="name"
                            axisLine={false}
                            tickMargin={isXs ? 10 : 20}
                            tickLine={false}
                            width={isXs ? 80 : 100}
                            tick={{ fontSize: 16, fontFamily: 'Times New Roman', fontWeight:'bold' }}
                             />
                          <Tooltip />
                          <Bar dataKey="Conformite" fill="#F6CA11" radius={[5, 5, 5, 5]} minPointSize={60}>
                            <LabelList
                              dataKey="ConformiteLabel"
                              position="insideEnd"
                              fill="#fff"
                              fontSize={18} />
                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>
                      {!isXs && <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '-20px'}}>
                      <FlecheComponent currentMonthValue={conformite} previousMonthValue={'100%'}/>
                     </div>}
                      </>
                    ) : (
                      <Typography sx={{ textAlign: 'center', padding: 2 }}>Aucune donnée</Typography>
                    )}
                  </Paper>
                </Grid>
              </Grid>
              <Modal
              open={showMore}
              onClose={handleShowMore}
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft:20 }}
            >
              <Box 
                sx={{ 
                  bgcolor: 'background.paper', 
                  boxShadow: 24, 
                  p: 2, 
                  borderRadius: 2, 
                  outline: 'none',
                  width:900,
                  height:500,
                  overflow:'auto'
                }}
              >
                <div style={{ textAlign: 'center' }}>
                <span style={{ fontWeight: 'bold', fontSize: isXs ? '1rem': '1.5rem' }}>Equipements non motorisés</span>
                <span style={{ marginLeft: '10px', color: '#02834A', fontWeight: 'bold', fontSize: isXs ? '1rem' : '2.5rem', }}>({barData.totalAUTRES})</span>
                          </div>
                {barData.AUTRES && barData.AUTRES.length > 0 ? (
                  <ResponsiveContainer width={'100%'} height={400}>
                    <BarChart
                      layout="horizontal"
                      data={barData.AUTRES}
                      width={500}
                      height={300}
                      margin={{ right: 20, left: 10, bottom: 50 }}
                    >
                      <YAxis type="number" axisLine={false} tick={false} />
                      <XAxis type="category" dataKey="name" axisLine={false} tickMargin={25} tickLine={false} interval={2} tick={{ angle: -15, fontSize: 14 }} />
                      <Tooltip />
                      <Bar dataKey="Production" fill="#F6CA11" radius={[5, 5, 5, 5]} minPointSize={60}>
                        <LabelList
                          dataKey="Production"
                          position="top"
                          fill="#F6CA11"
                          fontSize={14} />
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                ) : (
                  <Typography sx={{ textAlign: 'center', padding: 2 }}>Aucune donnée</Typography>
                )}
              </Box>
               </Modal>        
               <Grid item xs={12} md={6} mt={3} lg={12}>
               <Paper  elevation={3} 
                  sx={{width: { xs: '100%', sm: '90%', md: 'auto' }, maxWidth: { xs: 350, sm: 500, md: isXl ? '100%' : 1000 }, height: { xs: 380, sm: 420, md: isXl ? 520: 420 },        
                  padding: 2,
                  borderRadius: 3,
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                  alignContent:'center'
                }}
                  >
                  <div style={{ textAlign: 'center' }}>
                  <span style={{ fontWeight: 'bold', fontSize: isXs ? '1rem': '1.5rem' }}>Equipements non motorisés par catégorie</span>
                  <span style={{ marginLeft: '10px', color: '#02834A', fontWeight: 'bold', fontSize: isXs ? '1rem' : '2.5rem', }}>({barData.totalAUTRES})</span>
                    </div>
                    {articlesParCategorie && articlesParCategorie.length > 0 ? (
                      <ResponsiveContainer width="100%" height={isXl ? 400:300}>
                        <BarChart
                          data={articlesParCategorie}
                          margin={isXs ? {} : { top: 5, right: 30, left: 20, bottom: 5 }} 
                        >
                          <XAxis type="category" dataKey="name" tick={{fontSize: 14, fontWeight:'bold' }}/>
                          <YAxis tick={{fontSize: 14, fontWeight:'bold' }}/>
                          <Tooltip />
                          <Bar dataKey="production" fill="#F6CA11" radius={[5, 5, 5, 5]} minPointSize={60}>
                            <LabelList dataKey="production" position="insideEnd" fill="#fff" fontSize={18}/>
                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>
                    ) : (
                      <Typography sx={{ textAlign: 'center', padding: 2 }}>Aucune donnée</Typography>
                    )}
                 {!isXs && <Box textAlign="center" mt={2}>
                    <Typography 
                      component="span" 
                      onClick={handleShowMore} 
                      sx={{ 
                        cursor: 'pointer', 
                        color: '#F6CA11', 
                        textDecoration: 'underline',
                        fontFamily:'Times New Roman',
                        fontSize:'1.2rem'
                      }}
                    >
                      {showMore ? 'Voir moins...' : 'Voir plus...'}
                    </Typography>
                  </Box>}
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6} mt={2}>
                <Typography variant="h6"  sx={{ 
                      fontWeight: 'bold', 
                      fontSize: { xs: '1rem', sm: '1.2rem', md: '1.3rem' },
                      textAlign: 'center',
                      mb: { xs: 2, sm: 3 }
                      }}> Nombre de production par mois</Typography>
                  <Box mb={3}>
                    <Autocomplete
                      options={anneeOptions}
                      getOptionLabel={(option) => option.label}
                      onChange={handleAnneeChange}
                      renderInput={(params) => <TextField {...params} label="Année" color='success' size='small' />}
                      sx={{ width: 100 }} />
                  </Box>
                  <ResponsiveContainer width="100%" sx={{  maxWidth: {
                    xs: '100%',  
                    sm: '100%', 
                    md:'800px'
                  }}} 
                  height={400}> 
                  <LineChart data={chartData.data.map((Production, index) => ({ month: chartData.labels[index], Production }))}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="Production" stroke="#F6CA11" activeDot={{ r: 6 }}/>
                  </LineChart>
                  </ResponsiveContainer>
                </Grid>
                </>
          )}
          
          </TabPanel>
          <TabPanel value="2">
          <div style={{ textAlign: 'center', marginBottom: isXs ? '15px': ''}}>
                  <span style={{ marginLeft: '10px', color: '#02834A', fontWeight: 'bold', fontSize: isXs ? '1rem' : '2.5rem', }}>{formattedDateWithCapitalizedMonth}</span>
                </div>
          <Grid container spacing={4}>
                <Grid item xs={12} sm={6} md={3}>
                  <CustomCard title="Conformité" value={`${Math.trunc(generalConformity)}%`} color="#ffff" icon={<AgricultureIcon />} svalue={'+12%'}/>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CustomCard title="Opérateurs" value={`${generalOperator}`} color="#ffff" icon={<RvHookupIcon />} svalue={'+05'}/>
                </Grid>
              </Grid>
          <Box display="flex" alignItems="center" mb={2} mt={2}>
                <Autocomplete
                  options={vagueOptions}
                  onChange={handleVagueChange}
                  renderInput={(params) => <TextField {...params} label="Filtre" color='success' size='small' />}
                  sx={{ width: 200, ml: 2 }} />
                
               </Box>
              {selectedVague === "" && (
                 <>
                <Grid container spacing={2} mt={3}>
                <Grid item xs={12} md={6}>
                  <Paper
                    elevation={3}
                    sx={{
                      width: { xs: '100%', sm: '90%', md: 'auto' },
                      maxWidth: { xs: 350, sm: 500, md: 600 },
                      height: { xs: 380, sm: 400, md: 400 },
                      padding: 2,
                      borderRadius: 3,
                      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)'
                    }}
                  >
                    <div style={{ textAlign: 'center' }}>
                      <span style={{ fontWeight: 'bold', fontSize: isXs ? '1rem' : '1.5rem' }}>Conformité par puissance</span>
                    </div>
                    {isXs && <div style={{ marginTop: '15px' }} />}
                    {conformityData && conformityData.length > 0 ? (
                      <ResponsiveContainer width="100%" height={300}>
                        <BarChart
                          layout="vertical"
                          data={conformityData}
                          margin={isXs ? {} : { top: 5, left: 20, bottom: 2 }}
                        >
                          <XAxis type="number" axisLine={false} tick={false} />
                          <YAxis
                            type="category"
                            dataKey="label"
                            axisLine={false}
                            tickMargin={isXs ? 10 : 20}
                            tickLine={false}
                            width={isXs ? 80 : 100}
                            tick={{ fontSize: 16, fontFamily: 'Times New Roman', fontWeight: 'bold' }}
                          />
                          <Tooltip />
                          <Bar dataKey="value" fill="#F6CA11" radius={[5, 5, 5, 5]} minPointSize={60}>
                            <LabelList
                              dataKey="labelBar"
                              position="insideEnd"
                              fill="#fff"
                              fontSize={18}
                            />
                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>
                    ) : (
                      <Typography sx={{ textAlign: 'center', padding: 2 }}>Aucune donnée</Typography>
                    )}
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Paper
                    elevation={3}
                    sx={{
                      width: { xs: '100%', sm: '90%', md: 'auto' },
                      maxWidth: { xs: 350, sm: 500, md: 600 },
                      height: { xs: 380, sm: 400, md: 400 },
                      padding: 2,
                      borderRadius: 3,
                      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)'
                    }}
                  >
                    <div style={{ textAlign: 'center' }}>
                      <span style={{ fontWeight: 'bold', fontSize: isXs ? '1rem' : '1.5rem' }}>Nombre moyen d'opérateurs</span>
                    </div>
                    {isXs && <div style={{ marginTop: '15px' }} />}
                    {averageOperators && Object.keys(averageOperators).length > 0 ? (
                      <ResponsiveContainer width="100%" height={300}>
                        <BarChart
                          layout="vertical"
                          data={Object.keys(averageOperators).map(puissance => ({
                            label: puissance,
                            value: parseFloat(averageOperators[puissance])
                          })).sort((a, b) => b.value - a.value)}
                          margin={isXs ? {} : { top: 5, left: 20, bottom: 2 }}
                        >
                          <XAxis type="number" axisLine={false} tick={false} />
                          <YAxis
                            type="category"
                            dataKey="label"
                            axisLine={false}
                            tickMargin={isXs ? 10 : 20}
                            tickLine={false}
                            width={isXs ? 80 : 100}
                            tick={{ fontSize: 16, fontFamily: 'Times New Roman', fontWeight: 'bold' }}
                          />
                          <Tooltip />
                          <Bar dataKey="value" fill="#F6CA11" radius={[5, 5, 5, 5]} minPointSize={60}>
                            <LabelList
                              dataKey="value"
                              position="insideEnd"
                              fill="#fff"
                              fontSize={18}
                            />
                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>
                    ) : (
                      <Typography sx={{ textAlign: 'center', padding: 2 }}>Aucune donnée</Typography>
                    )}
                  </Paper>
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={3}>
                  <Grid item xs={12} md={6}>
                    <Paper
                      elevation={3}
                      sx={{
                        width: { xs: '100%', sm: '90%', md: 'auto' },
                        maxWidth: { xs: 350, sm: 500, md: 600 },
                        height: { xs: 380, sm: 300, md: 300 },
                        padding: 2,
                        borderRadius: 3,
                        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)'
                      }}
                    >
                      <div style={{ textAlign: 'center' }}>
                        <span style={{ fontWeight: 'bold', fontSize: isXs ? '1rem' : '1.5rem' }}>Conformité par marque</span>
                      </div>
                      {isXs && <div style={{ marginTop: '15px' }} />}
                      {conformityDataMarque && conformityDataMarque.length > 0 ? (
                        <ResponsiveContainer width="100%" height={250}>
                          <BarChart
                            layout="vertical"
                            data={conformityDataMarque}
                            margin={isXs ? {} : { top: 5, left: 20, bottom: 2 }}
                          >
                            <XAxis type="number" axisLine={false} tick={false} />
                            <YAxis
                              type="category"
                              dataKey="label"
                              axisLine={false}
                              tickMargin={isXs ? 10 : 20}
                              tickLine={false}
                              width={isXs ? 80 : 120}
                              tick={{ fontSize: 16, fontFamily: 'Times New Roman', fontWeight: 'bold' }}
                            />
                            <Tooltip />
                            <Bar dataKey="value" fill="#F6CA11" radius={[5, 5, 5, 5]} barSize={40} minPointSize={60}>
                              <LabelList
                                dataKey="labelBar"
                                position="insideEnd"
                                fill="#fff"
                                fontSize={18}
                              />
                            </Bar>
                          </BarChart>
                        </ResponsiveContainer>
                      ) : (
                        <Typography sx={{ textAlign: 'center', padding: 2 }}>Aucune donnée</Typography>
                      )}
                    </Paper>
                  </Grid>
                <Grid item xs={12} md={6}>
                  <Paper
                    elevation={3}
                    sx={{
                      width: { xs: '100%', sm: '90%', md: 'auto' },
                      maxWidth: { xs: 350, sm: 500, md: 600 },
                      height: { xs: 380, sm: 300, md: 300 },
                      padding: 2,
                      borderRadius: 3,
                      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)'
                    }}
                  >
                    <div style={{ textAlign: 'center' }}>
                      <span style={{ fontWeight: 'bold', fontSize: isXs ? '1rem' : '1.5rem' }}>Nombre moyen d'opérateurs</span>
                    </div>
                    {isXs && <div style={{ marginTop: '15px' }} />}
                    {averageOperatorsMarque && Object.keys(averageOperatorsMarque).length > 0 ? (
                      <ResponsiveContainer width="100%" height={250}>
                        <BarChart
                          layout="vertical"
                          data={Object.keys(averageOperatorsMarque).map(puissance => ({
                            label: puissance,
                            value: parseFloat(averageOperatorsMarque[puissance])
                          })).sort((a, b) => b.value - a.value)}
                          margin={isXs ? {} : { top: 5, left: 20, bottom: 2 }}
                        >
                          <XAxis type="number" axisLine={false} tick={false} />
                          <YAxis
                            type="category"
                            dataKey="label"
                            axisLine={false}
                            tickMargin={isXs ? 10 : 20}
                            tickLine={false}
                            width={isXs ? 80 : 120}
                            tick={{ fontSize: 16, fontFamily: 'Times New Roman', fontWeight: 'bold' }}
                          />
                          <Tooltip />
                          <Bar dataKey="value" fill="#F6CA11" radius={[5, 5, 5, 5]} minPointSize={60} barSize={40}>
                            <LabelList
                              dataKey="value"
                              position="insideEnd"
                              fill="#fff"
                              fontSize={18}
                            />
                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>
                    ) : (
                      <Typography sx={{ textAlign: 'center', padding: 2 }}>Aucune donnée</Typography>
                    )}
                  </Paper>
                </Grid>
              </Grid>
              <Container fluid className="mt-3">
                  <Box
                    sx={{
                      width: '100%', 
                      height: '400px',
                      overflow: 'auto',
                      margin: '0 auto', // Centrage
                      maxWidth: {
                        xs: '325px',  
                        sm: '600px',   // Small
                        md: '800px',   // Medium
                        lg: '1000px',  // Large
                        xl: '1200px',  // Extra Large
                      },
                    }}
                  >
                    <TableComponent
                      data={ProdData}
                      columns={columns}
                      sx={{
                        '& .MuiTableCell-root': {
                          padding: '4px',
                          fontSize: { xs: '0.8rem', sm: '1rem' }, // Ajustement de la taille de police
                        },
                      }}
                    />
                  </Box>
                </Container>
            </>
             )}
               {selectedVague === "Puissance motrice" && (
                 <>
                <Grid container spacing={2} mt={3}>
                  <Grid item xs={12} md={6}>
                    <Paper
                      elevation={3}
                      sx={{
                        width: { xs: '100%', sm: '90%', md: 'auto' },
                        maxWidth: { xs: 350, sm: 500, md: 600 },
                        height: { xs: 380, sm: 400, md: 400 },
                        padding: 2,
                        borderRadius: 3,
                        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)'
                      }}
                    >
                      <div style={{ textAlign: 'center' }}>
                      <span style={{ fontWeight: 'bold', fontSize: isXs ? '1rem' : '1.5rem' }}>Conformité par puissance</span>
                     </div>
                      {isXs && <div style={{ marginTop: '15px' }} />}
                      {conformityData && conformityData.length > 0 ? (
                        <ResponsiveContainer width="100%" height={300}>
                          <BarChart
                            layout="vertical"
                            data={conformityData}
                            margin={isXs ? {} : { top: 5, left: 20, bottom: 2 }}
                          >
                            <XAxis type="number" axisLine={false} tick={false} />
                            <YAxis
                              type="category"
                              dataKey="label"
                              axisLine={false}
                              tickMargin={isXs ? 10 : 20}
                              tickLine={false}
                              width={isXs ? 80 : 100}
                              tick={{ fontSize: 16, fontFamily: 'Times New Roman', fontWeight: 'bold' }}
                            />
                            <Tooltip />
                            <Bar dataKey="value" fill="#F6CA11" radius={[5, 5, 5, 5]} minPointSize={60}>
                              <LabelList
                                dataKey="labelBar"
                                position="insideEnd"
                                fill="#fff"
                                fontSize={18}
                              />
                            </Bar>
                          </BarChart>
                        </ResponsiveContainer>
                      ) : (
                        <Typography sx={{ textAlign: 'center', padding: 2 }}>Aucune donnée</Typography>
                      )}
                    </Paper>
                  </Grid>
                <Grid item xs={12} md={6}>
                  <Paper
                    elevation={3}
                    sx={{
                      width: { xs: '100%', sm: '90%', md: 'auto' },
                      maxWidth: { xs: 350, sm: 500, md: 600 },
                      height: { xs: 380, sm: 400, md: 400 },
                      padding: 2,
                      borderRadius: 3,
                      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)'
                    }}
                  >
                    <div style={{ textAlign: 'center' }}>
                    <span style={{ fontWeight: 'bold', fontSize: isXs ? '1rem' : '1.5rem' }}>Nombre moyen d'opérateurs</span>
                      </div>
                    {isXs && <div style={{ marginTop: '15px' }} />}
                    {averageOperators && Object.keys(averageOperators).length > 0 ? (
                      <ResponsiveContainer width="100%" height={300}>
                        <BarChart
                          layout="vertical"
                          data={Object.keys(averageOperators).map(puissance => ({
                            label: puissance,
                            value: parseFloat(averageOperators[puissance])
                          })).sort((a, b) => b.value - a.value)}
                          margin={isXs ? {} : { top: 5, left: 20, bottom: 2 }}
                        >
                          <XAxis type="number" axisLine={false} tick={false} />
                          <YAxis
                            type="category"
                            dataKey="label"
                            axisLine={false}
                            tickMargin={isXs ? 10 : 20}
                            tickLine={false}
                            width={isXs ? 80 : 100}
                            tick={{ fontSize: 16, fontFamily: 'Times New Roman', fontWeight: 'bold' }}
                          />
                          <Tooltip />
                          <Bar dataKey="value" fill="#F6CA11" radius={[5, 5, 5, 5]} minPointSize={60}>
                            <LabelList
                              dataKey="value"
                              position="insideEnd"
                              fill="#fff"
                              fontSize={18}
                            />
                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>
                    ) : (
                      <Typography sx={{ textAlign: 'center', padding: 2 }}>Aucune donnée</Typography>
                    )}
                  </Paper>
                </Grid>
              </Grid>

          <Container fluid className="mt-3">
              <Box
                sx={{
                  width: '100%', 
                  height: '400px',
                  overflow: 'auto',
                  margin: '0 auto', // Centrage
                  maxWidth: {
                    xs: '325px',  
                    sm: '600px',   // Small
                    md: '800px',   // Medium
                    lg: '1000px',  // Large
                    xl: '1200px',  // Extra Large
                  },
                }}
              >
                <TableComponent
                  data={ProdData}
                  columns={columns}
                  sx={{
                    '& .MuiTableCell-root': {
                      padding: '4px',
                      fontSize: { xs: '0.8rem', sm: '1rem' }, // Ajustement de la taille de police
                    },
                  }}
                />
              </Box>
            </Container>
            </>
             )}
               {selectedVague === "Marques" && (
                 <>
                <Grid container spacing={2} mt={3}>
                  <Grid item xs={12} md={6}>
                    <Paper
                      elevation={3}
                      sx={{
                        width: { xs: '100%', sm: '90%', md: 'auto' },
                        maxWidth: { xs: 350, sm: 500, md: 600 },
                        height: { xs: 380, sm: 300, md: 300 },
                        padding: 2,
                        borderRadius: 3,
                        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)'
                      }}
                    >
                      <div style={{ textAlign: 'center' }}>
                      <span style={{ fontWeight: 'bold', fontSize: isXs ? '1rem' : '1.5rem' }}>Conformité par marque</span>
                      </div>
                      {isXs && <div style={{ marginTop: '15px' }} />}
                      {conformityDataMarque && conformityDataMarque.length > 0 ? (
                        <ResponsiveContainer width="100%" height={250}>
                          <BarChart
                            layout="vertical"
                            data={conformityDataMarque}
                            margin={isXs ? {} : { top: 5, left: 20, bottom: 2 }}
                          >
                            <XAxis type="number" axisLine={false} tick={false} />
                            <YAxis
                              type="category"
                              dataKey="label"
                              axisLine={false}
                              tickMargin={isXs ? 10 : 20}
                              tickLine={false}
                              width={isXs ? 80 : 120}
                              tick={{ fontSize: 16, fontFamily: 'Times New Roman', fontWeight: 'bold' }}
                            />
                            <Tooltip />
                            <Bar dataKey="value" fill="#F6CA11" radius={[5, 5, 5, 5]} barSize={40} minPointSize={60}>
                              <LabelList
                                dataKey="labelBar"
                                position="insideEnd"
                                fill="#fff"
                                fontSize={18}
                              />
                            </Bar>
                          </BarChart>
                        </ResponsiveContainer>
                      ) : (
                        <Typography sx={{ textAlign: 'center', padding: 2 }}>Aucune donnée</Typography>
                      )}
                    </Paper>
                  </Grid>
                <Grid item xs={12} md={6}>
                  <Paper
                    elevation={3}
                    sx={{
                      width: { xs: '100%', sm: '90%', md: 'auto' },
                      maxWidth: { xs: 350, sm: 500, md: 600 },
                      height: { xs: 380, sm: 300, md: 300 },
                      padding: 2,
                      borderRadius: 3,
                      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)'
                    }}
                  >
                    <div style={{ textAlign: 'center' }}>
                    <span style={{ fontWeight: 'bold', fontSize: isXs ? '1rem' : '1.5rem' }}>Nombre moyen d'opérateurs</span>
                   </div>
                    {isXs && <div style={{ marginTop: '15px' }} />}
                    {averageOperatorsMarque && Object.keys(averageOperatorsMarque).length > 0 ? (
                      <ResponsiveContainer width="100%" height={250}>
                        <BarChart
                          layout="vertical"
                          data={Object.keys(averageOperatorsMarque).map(puissance => ({
                            label: puissance,
                            value: parseFloat(averageOperatorsMarque[puissance])
                          })).sort((a, b) => b.value - a.value)}
                          margin={isXs ? {} : { top: 5, left: 20, bottom: 2 }}
                        >
                          <XAxis type="number" axisLine={false} tick={false} />
                          <YAxis
                            type="category"
                            dataKey="label"
                            axisLine={false}
                            tickMargin={isXs ? 10 : 20}
                            tickLine={false}
                            width={isXs ? 80 : 120}
                            tick={{ fontSize: 16, fontFamily: 'Times New Roman', fontWeight: 'bold' }}
                          />
                          <Tooltip />
                          <Bar dataKey="value" fill="#F6CA11" radius={[5, 5, 5, 5]} barSize={40} minPointSize={60}>
                            <LabelList
                              dataKey="value"
                              position="insideEnd"
                              fill="#fff"
                              fontSize={18}
                            />
                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>
                    ) : (
                      <Typography sx={{ textAlign: 'center', padding: 2 }}>Aucune donnée</Typography>
                    )}
                  </Paper>
                </Grid>
              </Grid>
          <Container fluid className="mt-3">
              <Box
                sx={{
                  width: '100%', 
                  height: '400px',
                  overflow: 'auto',
                  margin: '0 auto', // Centrage
                  maxWidth: {
                    xs: '325px',  
                    sm: '600px',   // Small
                    md: '800px',   // Medium
                    lg: '1000px',  // Large
                    xl: '1200px',  // Extra Large
                  },
                }}
              >
                <TableComponent
                  data={ProdData}
                  columns={columns}
                  sx={{
                    '& .MuiTableCell-root': {
                      padding: '4px',
                      fontSize: { xs: '0.8rem', sm: '1rem' }, // Ajustement de la taille de police
                    },
                  }}
                />
              </Box>
            </Container>
            </>
             )}
        </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );
}

export default RoleCheck(['Directeur'])(FormationComponent);