import React from 'react';
import { LineChart } from '@mui/x-charts/LineChart';

import * as d3 from 'd3';

const LineChartComponent = (props) => {

  const {xAxisDatas,yAxisDatas,xAxisLabels,yAxisLabels,chartTitle,xAxisDatasFormater,yAxisDatasFormaters,yAxisLegends,datasColors}=props
  const scaleTypes={
    linear:"linear",
    band: "band",
    time:"time",
    utc: "utc" 
  }
  let xAxisConfig=null
  let yAxisConfig=null
  let seriesConfig=null
  xAxisConfig=[]
  xAxisConfig.push({id:`xAxis1`,data:xAxisDatas,scaleType:'point',label:String(xAxisLabels).toLocaleLowerCase(),valueFormatter:(value)=>xAxisDatasFormater(value)});
  seriesConfig=[]
  yAxisConfig=[]
  yAxisDatas.forEach((yAxisData,index) => {
    seriesConfig.push({ yAxisKey:`yAxis${index}`,data:yAxisDatas[index].map((item)=>item),valueFormatter:(value,context)=>yAxisDatasFormaters[index](value,context),label:yAxisLegends[index],color:datasColors[index]})
    yAxisConfig.push({id:`yAxis${index}`,data:yAxisData,scaleType:scaleTypes[typeof(yAxisData)],label:String(yAxisLabels[index]).toLocaleLowerCase(),valueFormatter:(value,context)=>yAxisDatasFormaters[index](value,context)})
  });

  return (
    <>
      {
        xAxisConfig!=null?
        <LineChart
      xAxis={xAxisConfig}
      yAxis={yAxisConfig}
      series={seriesConfig}
      />:''
      }
    </>
  );
};

export default LineChartComponent;
