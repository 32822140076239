import React from 'react'
import * as d3 from 'd3'
import * as topojson from 'topojson-client';
import { useState,useEffect,useRef } from 'react';
import '../../../App.css';
import { baseUrl } from '../../../api/auth_constant';
import colors from './colors';

export default function MapChartClient() {
  const [state, setState] = useState({ country: null,datas:[]});
  const svgRef = useRef(null);
  const height=850;
  
  const jsonToData = async (data) => {
    try {
      const responses= await Promise.all([
        d3.json(String(data.countries)),
        d3.json(String(`${baseUrl}/client`))
      ]);
      const countriesGeoJSON = topojson.feature(responses[0], responses[0].objects.collection);
      console.log(responses[1])
      setState({ country: countriesGeoJSON,datas:responses[1]});
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchData = async () => {
    await jsonToData({
      countries: new URL("https://raw.githubusercontent.com/oscarduzer/countries-states-cities-database/master/country.topojson"),
    });
  };

  function removeAccents(str) {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
  }
  useEffect(() => {
    fetchData();
  },[]);

  useEffect(() => {
    if (state.country && svgRef.current) {
      
      const svg = d3.select(svgRef.current)
      .attr("viewBox", [0, 0, height/2])
      .attr("height", height)
      .on("click", reset);

      const tooltip=d3.select("#tooltip").style('pointer-events','none').style("visibility","hidden")

      const datacolor = [
        "0",
        "1-10",
        "11-30",
        ">30"
      ];
      const xAxisScale = d3.scaleBand()
      .domain(datacolor)
      .range([30, height/3])
      .padding(0.04);



      const projection = d3.geoProjection(function(x, y) {
        return [x, Math.log(Math.tan(Math.PI / 4 + y / 2))];
      }).scale(5800).translate([-20,1370]);

      const path = d3.geoPath().projection(projection);

      svg.selectAll("*").remove();
      const g = svg.append("g")
      .attr("fill", "#444");

      g.selectAll("path")
        .data(state.country.features)
        .join('path')
        .attr('fill', function(d,i){
          const data = state.datas.filter((item) => {
            // Vérifie et remplace "Pehunco" par "Pehonko" et "Ouesse" par "Ouèsè" dans CT_Adresse
            const adresse = item["CT_Adresse"] === "Pehunco"
              ? "Pehonko"
              : item["CT_Adresse"] === "OUESSE"
              ? "Ouèsè"
              : item["CT_Adresse"];
            
            // Applique la logique de filtrage avec la valeur modifiée
            return (
              removeAccents(String(adresse)).includes(removeAccents(d.properties.name)) ||
              removeAccents(String(item["CT_Ville"])).includes(removeAccents(d.properties.name))
            );
          });
          
                   if(data.length==0)
          {
              return String(colors[0])
          }
          else if(data.length>=0 && data.length<=10)
          {
            return String(colors[1])
          }
          else if(data.length>=11 && data.length<=30)
          {
            return String(colors[2])
          }
          return String(colors[3])
          
        }
        )
        .attr('d', path)
        .attr('title',(d)=>d.properties?.name)
        .attr('stroke', 'gray')
        .attr('cursor',"pointer")
         .on("mouseover",(event,d)=>{
          const data = state.datas.filter((item) => {
            // Vérifie et remplace "Pehunco" par "Pehonko" et "Ouesse" par "Ouèsè" dans CT_Adresse
            const adresse = item["CT_Adresse"] === "Pehunco"
              ? "Pehonko"
              : item["CT_Adresse"] === "OUESSE"
              ? "Ouèsè"
              : item["CT_Adresse"];
            
            // Applique la logique de filtrage avec la valeur modifiée
            return (
              removeAccents(String(adresse)).includes(removeAccents(d.properties.name)) ||
              removeAccents(String(item["CT_Ville"])).includes(removeAccents(d.properties.name))
            );
          });
          
                   tooltip.html(`<em style="text-align:center;font-weight:bold;">
                    ${d.properties.name}</em><br/><em style="font-weight:bold; text-wrap:nowrap;">
                    Nombre de client : </em>${data.length==0?"Aucun Client":data.length}`).style('left',`${event.pageX/2}px`).style('top',`${event.pageY/2}px`).style("visibility","visible")
         })
         .on("mouseout",(event,i)=>{
             tooltip.html("").style("visibility","hidden").style("top",0)
        })
        .on("click", clicked);
        const xAxis = svg.append("g")
        .attr("transform", `translate(0,30)`)
        .call(d3.axisBottom(xAxisScale).tickSizeOuter(0))
        .selectAll("text")
        .style("text-anchor", "middle")
      svg.selectAll(".bar")
        .data(datacolor)
        .enter().append("rect")
        .attr("class", "bar")
        .attr("x", d => xAxisScale(d))
        .attr("width", xAxisScale.bandwidth())
        .attr("y", 10)
        .attr("height", 20)
        .attr("fill", d => {
          switch (d) {
            case "0":
              return colors[0];
            case "1-10":
              return colors[1];
            case "11-30":
              return colors[2];
            case ">30":
              return colors[3]
          }
        });

      function clicked(event, d) {
        const [[x0, y0], [x1, y1]] = path.bounds(d);
        event.stopPropagation();
      }

      function reset() {
      }

    }
  }, [state]);

  return (
    <>
      <svg width={"100%"} ref={svgRef}/>
      <div id="tooltip"></div>
    </>
  );
}
