export const baseUrl = "https://databackend.vallis.tech/api/";
export const registerUrl = baseUrl  + "signup";
export const loginUrl = baseUrl  + "login";
export const financeUrl = baseUrl + "financier/finance";
export const beneficeUrl = baseUrl + "financier/benefice";
export const cardUrl = baseUrl + "financier/ca_periode";
export const commercialUrl = baseUrl + "commercial/liste_vente";
export const commercialTime = baseUrl + "commercial/vente_mois";
export const statistiqueUrl = baseUrl + "agriculture/statistiques_mois";
export const heuremoteurUrl = baseUrl + "agriculture/tracteurs/evolution_heure_moteur";
export const panne = baseUrl + "mécanicien/pannes";
export const visit = baseUrl + "mécanicien/visites";
export const culture = baseUrl + "culture";
export const stastiquePanne = baseUrl + "mécanicien/statistiques_pannes";
export const stastiqueVisite = baseUrl + "mécanicien/statistiques_visites";
export const prodAgricol = baseUrl + "agriculture/production_agricole";
export const prodIndust = baseUrl + "production_industrielle";
export const formation = baseUrl + "formation";
export const formation_tractoristes = baseUrl + "formation/formation_tractoristes";
export const formation_irrigations = baseUrl + "formation/formation_irrigations";
export const statformation = baseUrl + "statistique_formation";
export const report = baseUrl + "reports";
export const reportMensuel = baseUrl + "reports_mois";
export const tractor = baseUrl + "tractors";
export const logout = baseUrl + "logout";
