import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

const BarChar = ({ data }) => {
  const [chartSize, setChartSize] = useState({ width: 1000, height: 400 });

  const updateChartSize = () => {
    if (window.innerWidth < 600) {
      setChartSize({ width: 350, height: 400 });
    } else if (window.innerWidth >= 600 && window.innerWidth < 960) {
      setChartSize({ width: 400, height: 400 });
    }
      else if (window.innerWidth >= 1200) {
        setChartSize({ width: 1000, height: 500 });
    } else {
      setChartSize({ width: 1000, height: 500 });
    }
  };

  useEffect(() => {
    updateChartSize();
    window.addEventListener('resize', updateChartSize);
    return () => {
      window.removeEventListener('resize', updateChartSize);
    };
  }, []);

  const chartData = {
    labels: data.labels,
    datasets: [
      {
        label: 'Hommes',
        data: data.masculins,
        backgroundColor: '#F6CA11', // Bleu plus attractif
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
        borderRadius: 5, // Arrondi des coins des barres
        hoverBackgroundColor: '#F6CA11', // Effet au hover
      },
      {
        label: 'Femmes',
        data: data.feminin,
        backgroundColor: 'rgba(255, 99, 132, 0.5)', // Rouge plus doux
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
        borderRadius: 5,
        hoverBackgroundColor: 'rgba(255, 99, 132, 0.7)',
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          font: {
            size: 14,
          },
          color: '#333',
        },
      },
      tooltip: {
        backgroundColor: 'rgba(0,0,0,0.7)',
        titleFont: { size: 16 },
        bodyFont: { size: 14 },
        padding: 10,
        cornerRadius: 5,
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Masquer les lignes de la grille sur l'axe X
        },
        ticks: {
          font: {
            size: 14,
          },
          color: '#333',
        },
      },
      y: {
        grid: {
          color: 'rgba(0, 0, 0, 0.1)', // Grille légère sur l'axe Y
        },
        ticks: {
          font: {
            size: 14,
          },
          color: '#333',
          beginAtZero: true, // Démarrer à 0 sur l'axe Y
        },
      },
    },
  };

  return (
    <div style={{ width: `${chartSize.width}px`, height: `${chartSize.height}px` }}>
      <Bar data={chartData} options={chartOptions} />
    </div>
  );
};

export default BarChar;
