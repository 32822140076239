// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-popper {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-left: 950px; 
    margin-top: 180px;
    z-index: 1500; /* Ensure the popper is on top */
  }
  
  .react-datepicker {
    border: 1px solid #02834A;
    border-radius: 10px;
  }
  
  .react-datepicker__header {
    background-color: #02834A;
    border-bottom: none;
    border-radius: 10px 10px 0 0;
  }
  
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: #02834A;
  }
  
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    color: white;
  }
  
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range {
    background-color: #02834A;
    color: white;
  }
  
  .react-datepicker__day--keyboard-selected {
    background-color: #02834A;
    color: white;
  }
  
  /* Style for grayed out days */
  .react-datepicker__day--grayed-out {
    color: lightgray;
    pointer-events: none; /* Prevent selection */
  }
  
  .react-datepicker__day--grayed-out:hover {
    background-color: transparent;
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/CustomCalendar.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,aAAa;IACb,mBAAmB;IACnB,yCAAyC;IACzC,kBAAkB;IAClB,iBAAiB;IACjB,aAAa,EAAE,gCAAgC;EACjD;;EAEA;IACE,yBAAyB;IACzB,mBAAmB;EACrB;;EAEA;IACE,yBAAyB;IACzB,mBAAmB;IACnB,4BAA4B;EAC9B;;EAEA;;;IAGE,cAAc;EAChB;;EAEA;;;IAGE,YAAY;EACd;;EAEA;;;IAGE,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd;;EAEA,8BAA8B;EAC9B;IACE,gBAAgB;IAChB,oBAAoB,EAAE,sBAAsB;EAC9C;;EAEA;IACE,6BAA6B;EAC/B","sourcesContent":[".custom-popper {\n    background-color: white;\n    padding: 20px;\n    border-radius: 10px;\n    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n    margin-left: 950px; \n    margin-top: 180px;\n    z-index: 1500; /* Ensure the popper is on top */\n  }\n  \n  .react-datepicker {\n    border: 1px solid #02834A;\n    border-radius: 10px;\n  }\n  \n  .react-datepicker__header {\n    background-color: #02834A;\n    border-bottom: none;\n    border-radius: 10px 10px 0 0;\n  }\n  \n  .react-datepicker__day-name,\n  .react-datepicker__day,\n  .react-datepicker__time-name {\n    color: #02834A;\n  }\n  \n  .react-datepicker__current-month,\n  .react-datepicker-time__header,\n  .react-datepicker-year-header {\n    color: white;\n  }\n  \n  .react-datepicker__day--selected,\n  .react-datepicker__day--in-selecting-range,\n  .react-datepicker__day--in-range {\n    background-color: #02834A;\n    color: white;\n  }\n  \n  .react-datepicker__day--keyboard-selected {\n    background-color: #02834A;\n    color: white;\n  }\n  \n  /* Style for grayed out days */\n  .react-datepicker__day--grayed-out {\n    color: lightgray;\n    pointer-events: none; /* Prevent selection */\n  }\n  \n  .react-datepicker__day--grayed-out:hover {\n    background-color: transparent;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
