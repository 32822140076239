const colors=[
    "grey",
    "#CD26BB",
    "#936D8B",
    "#266CAB",
    "#631B57",
    "#689D71",
    "#9D7153"
]

export default colors;