
export default function CurrencyFormater(value,context) {
   if(value!=null)
    {
        if(context.location==='tick')
            {
              if(Math.abs(value) >= 1000) {
                 const power = Math.floor(Math.log10(Math.abs(value)) / 3);
                 const valueFormated = (value / Math.pow(1000, power)).toFixed(1);
                 const suffixe = ["", "k", "M", "B", "BB"][power];
                 return `${valueFormated}${suffixe}`;
             } else {
                 return value.toString();
             }
         }
         else
         {
             
                 return `${value.toLocaleString()} Fcfa`;  
         }
    }
    else{
        return ''
    }

}
