import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { login } from '../api/auth_client';
import { useCookies } from 'react-cookie';
import validateLogin from './Validatelogin';

const Login = ({ onLogin }) => {
  const [form, setForm] = useState({
    mail: '',
    password: ''
  });
  const [errors, setErrors] = useState({});
  const [cookies, setCookie] = useCookies(['user', 'email']);
  const navigate = useNavigate();

  useEffect(() => {
    // Pré-remplir l'email si un cookie existe
    if (cookies.email) {
      setForm((prevForm) => ({
        ...prevForm,
        mail: cookies.email
      }));
    }
  }, [cookies.email]);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
        // Valider les données du formulaire
        const validationErrors = validateLogin({
          email: form.mail,
          password: form.password
        });

    try {
        const response = await login(form);
        if (response.data.error) {
          alert('Something went wrong');
        } else {
          const userData = {
            token: 'Bearer ' + response.data.login.token,
            usertype: response.data.login.usertype
          };
          setCookie('user', userData);
          setCookie('email', form.mail);
          localStorage.setItem('@usertype', response.data.login.usertype);
          onLogin(userData);

          if (response.data.login.usertype === 'Directeur') {
            navigate('/dashboard_director');
          } else if (response.data.login.usertype === 'Commercial') {
            navigate('/dashboard_sales');
          } else if (response.data.login.usertype === 'Mecanicien') {
            navigate('/dashboard_mechanic');
          } else if (response.data.login.usertype === 'Financier') {
            navigate('/dashboard_fin');
          } else if (response.data.login.usertype === 'Informaticien') {
            navigate('/dashboard_it');
          }
        }
    } catch (error) {
        console.error(error);
        alert('Something went wrong !');
    }
  };

  return (
    <div className='login template d-flex justify-content-center align-items-center vh-100 bg-success'>
      <div className='form_container p-5 rounded bg-white'>
        <form onSubmit={handleSubmit}>
          <h3 className='text-center'>Sign In</h3>
          <div className='mb-2'>
            <label htmlFor='mail'>Email</label>
            <input type='text' placeholder='Enter Email' name='mail' value={form.mail} onChange={handleChange} 
                className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                />
                {errors.email && <div className='invalid-feedback'>{errors.email}</div>}
              </div>
          <div className='mb-2'>
            <label htmlFor='password'>Password</label>
            <input type='password' placeholder='Enter Password' 
             name='password' 
             onChange={handleChange} 
             className={`form-control ${errors.password ? 'is-invalid' : ''}`}
             />
             {errors.password && <div className='invalid-feedback'>{errors.password}</div>}
           </div>
          <div className='d-grid'>
            <button type='submit' className='btn btn-success'>Sign In</button>
          </div>
          <p className='text-end mt-2'>
            Forgot <a href=''>Password?</a><Link to="/signup" className='ms-2'>Sign up?</Link>
          </p>
        </form>
      </div>
    </div>
  );
}

export default Login;
