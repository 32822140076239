import React, { useState, useEffect } from 'react';
import { reportMensuel, report, tractor } from '../api/auth_constant';
import CalendarButton from './CalendarButton';
import ButtonComponent from './ButtonComponent';
import dayjs from 'dayjs';
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, CartesianGrid, LabelList, Tooltip } from 'recharts';
import { BarChart as MUIBarChart } from '@mui/x-charts';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { heuremoteurUrl } from '../api/auth_constant'
import RoleCheck from './Dashboards/RoleCheck';
import SideBar from './SideBar';
import { AppBar, Toolbar, Typography, Grid, Box, Paper, Autocomplete, TextField, FormControl, InputLabel, MenuItem, Select} from '@mui/material';
import { Tab, Tabs, Nav, Row, Col, Container  } from 'react-bootstrap';
import MapChart from "../Components/Dashboards/DashBoardDriversUtils/MapChart";
import { prodAgricol } from '../api/auth_constant';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import MapChartCulture from './Dashboards/DashBoardSavUtils/MapChartCulture'
import MapChartRendement from './Dashboards/DashBoardSavUtils/MapChartRendement';
import MapChartTracteur from './Dashboards/DashBoardSavUtils/MapChartTracteur';
import MapChartSol from './Dashboards/DashBoardSavUtils/MapChartSol';
import MapChartCulture2 from './Dashboards/DashBoardSavUtils/MapChartCulture2';
import { useMediaQuery } from '@mui/material';
import CustomCard from './CustomCard';
import RvHookupIcon from '@mui/icons-material/RvHookup';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import MapChartReports from './Dashboards/DashBoardSavUtils/MapChartReports';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import LinkIcon from '@mui/icons-material/Link';

const communeOptions = [
  { label: "ABOMEY" },
  { label: "ABOMEY-CALAVI" },
  { label: "ADJA-OUERE" },
  { label: "ADJARRA" },
  { label: "ADJOHOUN" },
  { label: "AGBANGNIZOUN" },
  { label: "AGUEGUES" },
  { label: "AKPRO-MISSERETE" },
  { label: "ALLADA" },
  { label: "APLAHOUE" },
  { label: "ATHIEME" },
  { label: "AVRANKOU" },
  { label: "BANIKOARA" },
  { label: "BANTE" },
  { label: "BASSILA" },
  { label: "BEMBEREKE" },
  { label: "BOHICON" },
  { label: "BONOU" },
  { label: "BOPA" },
  { label: "BOUKOUMBE" },
  { label: "COBLY" },
  { label: "COME" },
  { label: "COPARGO" },
  { label: "COTONOU" },
  { label: "COVE" },
  { label: "DANGBO" },
  { label: "DASSA-ZOUME" },
  { label: "DJAKOTOMEY" },
  { label: "DJIDJA" },
  { label: "DJOUGOU" },
  { label: "DOGBO" },
  { label: "GLAZOUE" },
  { label: "GOGOUNOU" },
  { label: "GRAND-POPO" },
  { label: "HOUEYOGBE" },
  { label: "IFANGNI" },
  { label: "KALALE" },
  { label: "KANDI" },
  { label: "KARIMAMA" },
  { label: "KEROU" },
  { label: "KETOU" },
  { label: "KLOUEKANME" },
  { label: "KOUANDE" },
  { label: "KPOMASSE" },
  { label: "LALO" },
  { label: "LOKOSSA" },
  { label: "LOKOSSA " },
  { label: "MALANVILLE" },
  { label: "MATERI" },
  { label: "N'DALI" },
  { label: "NATITINGOU" },
  { label: "NIKKI" },
  { label: "OUAKE" },
  { label: "OUESSE" },
  { label: "OUIDAH" },
  { label: "OUINHI" },
  { label: "PARAKOU" },
  { label: "PEHOUNCO" },
  { label: "PERERE" },
  { label: "POBE" },
  { label: "PORTO-NOVO" },
  { label: "SAKETE" },
  { label: "SAVALOU" },
  { label: "SAVE" },
  { label: "SEGBANA" },
  { label: "SEME-PODJI" },
  { label: "SINENDE" },
  { label: "SO-AVA" },
  { label: "TANGUIETA" },
  { label: "TCHAOUROU" },
  { label: "TOFFO" },
  { label: "TORI-BOSSITO" },
  { label: "TOUCOUNTOUNA" },
  { label: "TOVIKLIN" },
  { label: "ZA-KPOTA" },
  { label: "ZAGNANADO" },
  { label: "ZE" },
  { label: "ZOGBODOMEY" },
];

const cultureOptions = [
  { label: "Anacarde" },
  { label: "Ananas" },
  { label: "Arachide" },
  { label: "Carotte" },
  { label: "Choux" },
  { label: "Citulus" },
  { label: "Concombre" },
  { label: "Coton" },
  { label: "CrinCrin" },
  { label: "Fonio" },
  { label: "Gboma" },
  { label: "Gombo" },
  { label: "Goussi" },
  { label: "Haricot Vert" },
  { label: "Igname" },
  { label: "Laitue" },
  { label: "Manioc" },
  { label: "Mais" },
  { label: "Niébé" },
  { label: "Oignon" },
  { label: "Pastèque" },
  { label: "Patate Douce" },
  { label: "Petit mil" },
  { label: "Piment" },
  { label: "Poids d'angole" },
  { label: "Pomme de terre" },
  { label: "Riz" },
  { label: "Soja" },
  { label: "Sorgho" },
  { label: "Sésame" },
  { label: "Taro" },
  { label: "Tomate" },
  { label: "Voandzou" },
];

const anneeOptions = [
  { label: "2022" },
  { label: "2023" },
  { label: "2024" },

];
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const currentDate = new Date();
const formattedDate = currentDate.toLocaleDateString('fr-FR', { year: 'numeric', month: 'long' });
const formattedDateWithCapitalizedMonth = capitalizeFirstLetter(formattedDate);

const ProductionAgricoleComponent = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [tabValue, setTabValue] = useState('1');
  const [selectedCommune, setSelectedCommune] = useState("ABOMEY"); // Valeur par défaut
  const [selectedCulture, setSelectedCulture] = useState("Anacarde");
  const [agricultureData, setAgricultureData] = useState(null);
  const [Total, setTotal] = useState(0);
  const [Distance, setDistance] = useState(0);
  const [Heure_moteur, setHeure_moteur] = useState(0);
  const [data, setData] = useState(null);
  const [data2, setData2] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [dateDebut, setDateDebut] = useState(null);
  const [dateFin, setDateFin] = useState(null);
  const [totalPuissance, settotalPuissance] = useState(0);
  const [totalPuissanceMarque, settotalPuissanceMarque] = useState(0);
  const [heurePuissanceTotal, setheurePuissanceTotal] = useState(0);
  const [heureMarqueTotal, setheureMarqueTotal] = useState(0);
  const [heuremoteurData, setheuremoteurData] = useState(null);
  const [hoursByDesignation, setHoursByDesignation] = useState({});
  const [AgriData, setAgriData] = useState([]);
  const [conformityData, setConformityData] = useState([]);
  const [distanceMarque, setDistanceMarque] = useState([]);
  const [heurePuissance, setheurePuissance] = useState([]);
  const [heurePuissanceMarque, setheurePuissanceMarque] = useState([]);
  const [year, setYear] = useState('');
  const [trackMark, settrackMark] = useState(0);
  const [prod, setTotalProductions] = useState(0);
  const [selectedAnnee, setSelectedAnnee] = useState("2024");

  const [period, setPeriod] = useState([]);

  const isValidNumber = (valeur) => {
    // Vérifie si la valeur est un nombre valide
    return !isNaN(parseFloat(valeur)) && isFinite(valeur);
  };

const filterByYear = (data) => {
    // Crée un ensemble des années uniques à partir des données
    const years = [...new Set(data.map(item => item["Année"] || "Non défini"))];

    // Initialise les objets de comptage pour chaque année
    const yearCounts = years.reduce((acc, year) => {
        acc[year] = { Production: 0, Superficie: 0, Rendement: 0 };
        return acc;
    }, {});

    // Accumule les valeurs pour chaque année
    data.forEach(item => {
        const year = item["Année"];
        if (year) {
            // Vérifie et accumule la production
            const production = isValidNumber(item.Production) ? parseFloat(item.Production) : 0;
            yearCounts[year].Production += production;

            // Vérifie et accumule la superficie
            const superficie = isValidNumber(item.Superficie) ? parseFloat(item.Superficie) : 0;
            yearCounts[year].Superficie += superficie;

            // Vérifie et accumule le rendement
            const rendement = isValidNumber(item.Rendement) ? parseFloat(item.Rendement) : 0;
            yearCounts[year].Rendement += rendement;
        }
    });

    return yearCounts;
};
const TrackData = (data) => {
  // Initialiser un objet pour stocker les compteurs pour chaque marque de tracteur
  const productions = {};
  let totalProductions = 0; // Initialiser le compteur total

  // Parcourir les données pour compter les tracteurs par marque
  data.forEach(item => {
    const tractorMark = item.tractorMark;
    if (tractorMark) {
      productions[tractorMark] = (productions[tractorMark] || 0) + 1;
      totalProductions++; // Incrémenter le compteur total
    }
  });

  // Convertir les compteurs en format attendu pour les graphiques
  const formattedData = Object.keys(productions).map(tractorMark => ({
    tractorMark: tractorMark,
    Production: productions[tractorMark]
  }));
  
  // Trier les données par ordre décroissant de production
  const sortedData = formattedData.sort((a, b) => b.Production - a.Production);

  // Garder les marques principales et regrouper les autres dans une catégorie "Autres"
  const filteredData = filterTopBrands(sortedData);

  // Retourner les données formatées et le total
  return {
    data: filteredData,
    totalProductions: totalProductions // Inclure le total dans le retour
  };
};
const filterTopBrands = (data, topN = 10) => {
  const topBrands = data.slice(0, topN);
  const otherBrandsTotal = data.slice(topN).reduce((acc, curr) => acc + curr.Production, 0);
  
  if (otherBrandsTotal > 0) {
    topBrands.push({ tractorMark: "Autres", Production: otherBrandsTotal });
  }
  
  return topBrands;
};
    useEffect(() => {
      if (selectedCommune && selectedCulture) {
        fetchAgricultureData(selectedCommune, selectedCulture);
      }
    }, [selectedCommune, selectedCulture]);

    useEffect(() => {
      fetchData(reportMensuel);
  });
  
    useEffect(() => {
      if (dateDebut && dateFin) {
        fetchheuremoteurData(dateDebut, dateDebut);
      }
    }, [dateDebut, dateFin]);

    useEffect(() => {
      fetchData4(tractor);
    }, []);

    const fetchData = async () => {
      try {
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth() + 1;
        const response = await fetch(reportMensuel, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            annee: currentYear,
            mois:currentMonth
          }),
      });
      if (!response.ok) {
        throw new Error('Erreur lors de la récupération des données');
    }
      const data = await response.json();
      const firstItem = data[0];
      setheurePuissanceTotal(firstItem.Total_Engine_hours_global);
      settotalPuissance(firstItem.Total_Mileage_global);
      setTotalProductions(firstItem.Moyenne_Tracteurs_actifs_total);
      const formattedDataPuissance = data
      .filter(item => item.Puissance) // Filtrer pour ne garder que les données avec une puissance définie
      .map(item => ({
        name: item.Puissance,
        Distance: item.Total_Mileage,
      })) .sort((a, b) => b.Distance - a.Distance);
      const formattedDataMarque = data
      .filter(item => item.Marque) // Filtrer pour ne garder que les données avec une puissance définie
      .map(item => ({
        name: item.Marque,
        Distance: item.Total_Mileage,
      })).sort((a, b) => b.Distance - a.Distance);

      const formattedDataPuissanceHeure = data
      .filter(item => item.Puissance) // Filtrer pour ne garder que les données avec une puissance définie
      .map(item => ({
        name: item.Puissance,
        heures: item.Total_Engine_hours,
      })) .sort((a, b) => b.heures - a.heures);

      const formattedDataMarqueHeure = data
      .filter(item => item.Marque) // Filtrer pour ne garder que les données avec une puissance définie
      .map(item => ({
        name: item.Marque,
        heures: item.Total_Engine_hours,
      })).sort((a, b) => b.heures - a.heures);

      setDistanceMarque(formattedDataMarque);
      setConformityData(formattedDataPuissance);
      setheurePuissance(formattedDataPuissanceHeure);
      setheurePuissanceMarque(formattedDataMarqueHeure);

        } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const fetchData4 = async (url) => {
    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error('Network response was not ok');
      const data = await response.json();
      
      // Appeler TrackData et déstructurer le résultat
      const { data: formattedData, totalProductions } = TrackData(data);
      
      // Mettre à jour l'état avec les données formatées et le total
      settrackMark(formattedData);      
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const fetchheuremoteurData = async () => {
    try {
      const requestBody = {
        date_debut: dateDebut,
        date_fin: dateFin
      };
      
      const response = await fetch(heuremoteurUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) throw new Error('Network response was not ok');
      const data = await response.json();
      const formattedData = data.Date.map((date, index) => ({
        date: date,
        heure: data.Heure_moteur[index],
      }));

      setheuremoteurData(formattedData);
    } catch (error) {
      console.error('Error fetching heure moteur data:', error);
    }
  };
    const fetchAgricultureData = async () => {
      try {
        const requestBody = {
          culture: selectedCulture,
          commune: selectedCommune
        };
  
        const response = await fetch(prodAgricol, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const jsondata = await response.json();
        const periods = [...new Set(jsondata.map(item => item['Année']))].filter(Boolean);
        setPeriod(periods);
        const filteredData = filterByYear(jsondata)
        const chartData = {
          Production: periods.map(period => filteredData[period]?.Production || 0),
          Superficie: periods.map(period => filteredData[period]?.Superficie || 0),
          Rendement: periods.map(period => filteredData[period]?.Rendement || 0),
        };
        setAgricultureData(chartData);
      } catch (error) {
        console.error('Error fetching agriculture data:', error);
      }
    };
    const handleDrawerOpen = () => {
      setSidebarOpen(true);
    };
  
    const handleDrawerClose = () => {
      setSidebarOpen(false);
    };
  
    const handleDateChange = (date) => {
      setSelectedDate(date);
    };
  
    const handleCommuneChange = (event, value) => {
      setSelectedCommune(value?.label || 'ABOMEY');
    };
  
    const handleCultureChange = (event, value) => {
      setSelectedCulture(value?.label || 'Anacarde');
    };
    const handleAnneeChange = (event, value) => {
      setSelectedAnnee(value?.label || null);
    };
    const handleTabChange = (event, newValue) => {
      setTabValue(newValue);
    };

    const shouldDisplayMessage = !selectedCommune || !selectedCulture;
    const isXs = useMediaQuery('(max-width:600px)');
    const isXl = useMediaQuery('(min-width:1200px)');
    return (
      <Box sx={{ display: 'flex' }}>
    <SideBar open={sidebarOpen} onClose={() => setSidebarOpen(false)} />
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Toolbar />
            <Box display="flex" justifyContent="flex-end" alignItems="center" mb={3}>
              <CalendarButton selectedDate={selectedDate} handleDateChange={handleDateChange} />
              <Box ml={2}>
                <ButtonComponent barChartData={data} lineChartData={data2} />
              </Box>
            </Box>
            <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 3, borderColor: 'divider' }}>
              <Nav variant="tabs" className="flex-column flex-sm-row">
                {[ 'Production Agricole',
                'Usage des tracteurs',
                'Sols et tracteurs',
                'Cartes'
                ].map((label, index) => (
                  <Nav.Item key={index}>
                    <Nav.Link
                      eventKey={`${index + 1}`}
                      active={tabValue === `${index + 1}`}
                      onClick={() => handleTabChange(null, `${index + 1}`)}
                      style={{
                        color: tabValue === `${index + 1}` ? '#F6CA11' : '#02834A',
                        fontSize: '1.2rem',
                        textTransform: 'none',
                      }}
                    >
                      {label}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Box>
          <TabPanel value="1">
          <Grid container display="flex" alignItems="center" spacing={2} mt={3}>
      <Grid item xs={12} md={8}>
        <Box display="flex" alignItems="center">
          <Autocomplete
            options={communeOptions}
            onChange={handleCommuneChange}
            renderInput={(params) => <TextField {...params} label="Commune" color="success" />}
            sx={{ width: 200 }}
            defaultValue={{ label: 'ABOMEY' }}
          />
          <Autocomplete
            options={cultureOptions}
            onChange={handleCultureChange}
            renderInput={(params) => <TextField {...params} label="Culture" color="success" />}
            sx={{ width: 200, marginLeft: '50px' }}
            defaultValue={{ label: 'Anacarde' }}
          />
        </Box>
      </Grid>

      <Grid item xs={12}>
        {agricultureData ? (
          <>
            <Paper elevation={3} sx={{ marginTop: 3, padding: 3 }}>
            <Typography variant="h6" textAlign="center" fontWeight="bold">
  Production en tonnes de {selectedCulture} à {selectedCommune}
</Typography>
              <ResponsiveContainer width="100%" height={400}>
                <BarChart data={period.map((year, index) => ({ year, valeur: agricultureData.Production[index] }))} margin={{ left: 25 }}>
                <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="year" tick={{fontSize: 16, fontFamily: 'Times New Roman', fontWeight: 'bold' }}
                  />
                  <YAxis tick={{ fontSize: 16, fontFamily: 'Times New Roman', fontWeight: 'bold' }}/>
                  <Tooltip />
                  <Bar dataKey="valeur" fill="#F6CA11" radius={[10, 10, 0, 0]} animationDuration={1500} barSize={80}/>
                </BarChart>
              </ResponsiveContainer>
            </Paper>

            <Paper elevation={3} sx={{ marginTop: 5, padding: 3 }}>
              <Typography variant="h6" textAlign="center" fontWeight="bold">
                Superficie en hectares de {selectedCulture} à {selectedCommune}
                </Typography>
              <ResponsiveContainer width="100%" height={400}>
                <BarChart data={period.map((year, index) => ({ year, valeur: agricultureData.Superficie[index] }))} margin={{ left: 25 }}>
                <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="year" tick={{ fontSize: 16, fontFamily: 'Times New Roman', fontWeight: 'bold' }}/>
                  <YAxis tick={{ fontSize: 16, fontFamily: 'Times New Roman', fontWeight: 'bold' }}/>
                  <Tooltip />
                  <Bar dataKey="valeur" fill="#F6CA11" radius={[10, 10, 0, 0]} animationDuration={1500} barSize={80}/>
                </BarChart>
              </ResponsiveContainer>
            </Paper>

            <Paper elevation={3} sx={{ marginTop: 5, padding: 3 }}>
              <Typography variant="h6" textAlign="center" fontWeight="bold">
                Rendement en kg/ha de {selectedCulture} à {selectedCommune}
                </Typography>
              <ResponsiveContainer width="100%" height={400}>
                <BarChart data={period.map((year, index) => ({ year, valeur: agricultureData.Rendement[index] }))} margin={{ left: 25 }}>
                <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="year" tick={{ fontSize: 16, fontFamily: 'Times New Roman', fontWeight: 'bold' }}/>
                  <YAxis tick={{ fontSize: 16, fontFamily: 'Times New Roman', fontWeight: 'bold' }}/>
                  <Tooltip />
                  <Bar dataKey="valeur" fill="#F6CA11" radius={[10, 10, 0, 0]} animationDuration={1500} barSize={80}/>
                </BarChart>
              </ResponsiveContainer>
            </Paper>
          </>
        ) : (
          <Typography variant="h6" sx={{ textAlign: 'center', marginTop: 8 }}>
            Veuillez choisir une commune et une culture
          </Typography>
        )}
      </Grid>
    </Grid>
          </TabPanel>
          <TabPanel value="2">
          <div style={{ textAlign: 'center', marginBottom: isXs ? '15px': ''}}>
                  <span style={{ marginLeft: '10px', color: '#02834A', fontWeight: 'bold', fontSize: isXs ? '1rem' : '2.5rem', }}>{formattedDateWithCapitalizedMonth}</span>
                </div>
                <Grid container spacing={4}>
                <Grid item xs={12} sm={6} md={3}>
                  <CustomCard title="Tracteurs" value={`${Math.trunc(prod)}`} color="#ffff" icon={<AgricultureIcon />} svalue={'+350'}/>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CustomCard title="Heure moteur" value={`${Math.trunc(heurePuissanceTotal)} h`} color="#ffff" icon={<RvHookupIcon />} svalue={'+50'}/>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CustomCard title="Distance" value={`${Math.trunc(totalPuissance)} km`} color="#ffff" icon={<AgricultureIcon />} svalue={'+500'}/>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CustomCard title="Superficie emblavée" value="-" color="#ffff" icon={<AgricultureIcon />}/>
                </Grid>
              </Grid>
              <Box mt={3} mb={2}>
        <Autocomplete
          options={anneeOptions}
          onChange={handleAnneeChange}
          renderInput={(params) => <TextField {...params} label="Année" color="success" size="small" />}
          sx={{ width:200, marginLeft: 2 }}
        />
        </Box>
              <Grid container spacing={3}>
               <Grid item xs={12} md={6}>
                  <Paper  elevation={3} 
                  sx={{width: { xs: '100%', sm: '90%', md: 'auto' }, maxWidth: { xs: 350, sm: 500, md: isXl ? 800: 600 }, height: { xs: 380, sm: 400, md: 400 },        
                  padding: 2,
                  borderRadius: 3,
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)'}}
                  >
                <div style={{ textAlign: 'center' }}>
                <span style={{ fontWeight: 'bold', fontSize: isXs ? '1rem' : '1.5rem' }}>Distance par puissance</span>
              </div>
                    {isXs && <div style={{ marginTop: '20px' }} />}

                    {conformityData&& conformityData.length > 0 ? (
                      <>
                        <ResponsiveContainer width="100%" height={300}>
                          <BarChart
                            layout="vertical"
                            data={conformityData}
                            margin={isXs ? {} : { top: 5, left: 10, bottom: 2 }} 
                            >
                            <XAxis type="number" axisLine={false} tick={false} />
                            <YAxis
                              type="category"
                              dataKey="name"
                              axisLine={false}
                              tickMargin={isXs ? 10 : 20}
                              tickLine={false}
                              width={isXs ? 80 : 100}
                              tick={{fontSize: 16, fontFamily: 'Times New Roman', fontWeight: 'bold' }}
                            />
                           <Tooltip    
                                  content={({ active, payload }) => {
                                 if (active && payload && payload.length) {
                                 return (
                                  <div style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '5px' }}>
                                 <p style={{ margin: 0, fontWeight: 'bold' }}>
                                    Distance parcourue: {payload[0].payload.DistanceLabel}
                                    </p>
                                </div>
                                );
                                 }
                              return null;
                           }}/>                           
                            <Bar dataKey="Distance" fill="#F6CA11" radius={[5, 5, 5, 5]} barSize={40} minPointSize={60}>
                              <LabelList
                                dataKey="Distance"
                                position="insideEnd"
                                fill="#fff"
                                fontSize={18}
                                offset={10}
                              />
                            </Bar>
                          </BarChart>
                        </ResponsiveContainer>
                      </>
                    ) : (
                      <Typography sx={{ textAlign: 'center', padding: 2 }}>Aucune donnée</Typography>
                    )}
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Paper  elevation={3} 
                  sx={{width: { xs: '100%', sm: '90%', md: 'auto' }, maxWidth: { xs: 350, sm: 500, md: isXl ? 800: 600 }, height: { xs: 380, sm: 400, md: 400 },        
                  padding: 2,
                  borderRadius: 3,
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)'}}
                  >
                <div style={{ textAlign: 'center' }}>
                <span style={{ fontWeight: 'bold', fontSize: isXs ? '1rem' : '1.5rem' }}>Heure par puissance</span>
              </div>
                    {isXs && <div style={{ marginTop: '20px' }} />}

                    {heurePuissance&& heurePuissance.length > 0 ? (
                      <>
                        <ResponsiveContainer width="100%" height={300}>
                          <BarChart
                            layout="vertical"
                            data={heurePuissance}
                            margin={isXs ? {} : { top: 5, left: 10, bottom: 2 }} 
                            >
                            <XAxis type="number" axisLine={false} tick={false} />
                            <YAxis
                              type="category"
                              dataKey="name"
                              axisLine={false}
                              tickMargin={isXs ? 10 : 20}
                              tickLine={false}
                              width={isXs ? 80 : 100}
                              tick={{fontSize: 16, fontFamily: 'Times New Roman', fontWeight: 'bold' }}
                            />
                               <Tooltip    content={({ active, payload }) => {
                                 if (active && payload && payload.length) {
                                 return (
                               <div style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '5px' }}>
                                 <p style={{ margin: 0, fontWeight: 'bold' }}>
                                    Heure moteur: {payload[0].payload.TotalEngineHours}
                                    </p>
                                </div>
                                );
                                 }
                        return null;
                           }}/>
                            <Bar dataKey="heures" fill="#F6CA11" radius={[5, 5, 5, 5]} barSize={40} minPointSize={60}>
                              <LabelList
                                dataKey="heures"
                                position="insideEnd"
                                fill="#fff"
                                fontSize={18}
                                offset={10}
                              />
                            </Bar>
                          </BarChart>
                        </ResponsiveContainer>
                      </>
                    ) : (
                      <Typography sx={{ textAlign: 'center', padding: 2 }}>Aucune donnée</Typography>
                    )}
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                <Paper  elevation={3} 
                  sx={{width: { xs: '100%', sm: '90%', md: 'auto' }, maxWidth: { xs: 350, sm: 500, md: isXl ? 800: 600 }, height: { xs: 380, sm: 300, md: 300 },        
                  padding: 2,
                  borderRadius: 3,
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)'}}
                  >
                                <div style={{ textAlign: 'center' }}>
                <span style={{ fontWeight: 'bold', fontSize: isXs ? '1rem' : '1.5rem' }}>Distance par marque</span>
              </div>
                    {isXs && <div style={{ marginTop: '15px' }} />}
                    {distanceMarque && distanceMarque.length > 0 ? (
                      <>
                      <ResponsiveContainer width="100%" height={250}>
                        <BarChart
                          layout="vertical"
                          data={distanceMarque}
                          margin={isXs ? {} : { top: 5, left: 20, bottom: 2 }} 
                          >
                          <XAxis type="number" axisLine={false} tick={false} />
                          <YAxis
                            type="category"
                            dataKey="name"
                            axisLine={false}
                            tickMargin={isXs ? 10 : 20}
                            tickLine={false}
                            width={isXs ? 100 : 120}
                            tick={{ fontSize: 16, fontFamily: 'Times New Roman', fontWeight:'bold' }}
                             />
                          <Tooltip    
                                  content={({ active, payload }) => {
                                 if (active && payload && payload.length) {
                                 return (
                                  <div style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '5px' }}>
                                 <p style={{ margin: 0, fontWeight: 'bold' }}>
                                    Distance parcourue: {payload[0].payload.DistanceLabel}
                                    </p>
                                </div>
                                );
                                 }
                              return null;
                           }}/> 
                          <Bar dataKey="Distance" fill="#F6CA11" radius={[5, 5, 5, 5]} barSize={40} minPointSize={60}>
                            <LabelList
                              dataKey="Distance"
                              position="insideEnd"
                              fill="#fff"
                              fontSize={18} />
                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>
                      </>
                    ) : (
                      <Typography sx={{ textAlign: 'center', padding: 2 }}>Aucune donnée</Typography>
                    )}
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                <Paper  elevation={3} 
                  sx={{width: { xs: '100%', sm: '90%', md: 'auto' }, maxWidth: { xs: 350, sm: 500, md: isXl ? 800: 600 }, height: { xs: 380, sm: 300, md: 300 },        
                  padding: 2,
                  borderRadius: 3,
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)'}}
                  >
              <div style={{ textAlign: 'center' }}>
                <span style={{ fontWeight: 'bold', fontSize: isXs ? '1rem' : '1.5rem' }}>Heure par marque</span>
              </div>
                    {isXs && <div style={{ marginTop: '15px' }} />}
                    {heurePuissanceMarque && heurePuissanceMarque.length > 0 ? (
                      <>
                      <ResponsiveContainer width="100%" height={250}>
                        <BarChart
                          layout="vertical"
                          data={heurePuissanceMarque}
                          margin={isXs ? {} : { top: 5, left: 20, bottom: 2 }} 
                          >
                          <XAxis type="number" axisLine={false} tick={false} />
                          <YAxis
                            type="category"
                            dataKey="name"
                            axisLine={false}
                            tickMargin={isXs ? 10 : 20}
                            tickLine={false}
                            width={isXs ? 80 : 100}
                            tick={{ fontSize: 16, fontFamily: 'Times New Roman', fontWeight:'bold' }}
                             />
                          <Tooltip    content={({ active, payload }) => {
                                 if (active && payload && payload.length) {
                                 return (
                               <div style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '5px' }}>
                                 <p style={{ margin: 0, fontWeight: 'bold' }}>
                                    Heure moteur: {payload[0].payload.TotalEngineHours}
                                    </p>
                                </div>
                                );
                                 }
                        return null;
                           }}/>
                          <Bar dataKey="heures" fill="#F6CA11" radius={[5, 5, 5, 5]} barSize={40} minPointSize={60}>
                            <LabelList
                              dataKey="heures"
                              position="insideEnd"
                              fill="#fff"
                              fontSize={18} />
                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>
                      </>
                    ) : (
                      <Typography sx={{ textAlign: 'center', padding: 2 }}>Aucune donnée</Typography>
                    )}
                  </Paper>
                </Grid>
              </Grid>
          <Grid item xs={12} md={6} mt={3}>
                <Paper  elevation={3} 
                  sx={{width: { xs: '100%', sm: '90%', md: 'auto' }, maxWidth: { xs: 350, sm: 500, md: isXl ? '100%' :1000 }, height: { xs: 400, sm: 400, md:isXl ? 500: 400 },        
                  padding: 2,
                  borderRadius: 3,
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)'}}
                  >
                   <div style={{ textAlign: 'center' }}>
                      <span style={{ fontWeight: 'bold', fontSize: isXs ? '1rem' : '1.5rem' }}>Tracteurs par marque</span>
                    </div>
                    <ResponsiveContainer width="100%" height={isXl ? 415:300}>
           <BarChart
             data={trackMark} 
             margin={isXs ? {} : { top: 20, right: 30, left: 20, bottom: 5 }}
           >
             <XAxis 
               type="category" 
               dataKey="tractorMark" 
               axisLine={false} 
               tickMargin={isXs ? 2 : 15} 
               tick={{ fontSize: isXs ? 15 : 18, fontFamily: 'Times New Roman', fontWeight: 'bold' }} 
             />
             <YAxis 
               type="number" 
               tick={{ fontSize: 14 }} 
             />
             <Tooltip />
             <Bar dataKey="Production" fill="#F6CA11" radius={[5, 5, 0, 0]} barSize={40}>
               <LabelList 
                 dataKey="Production" 
                 position="insideEnd" 
                 fill="#fff" 
                 fontSize={14} 
                 offset={10} 
               />
             </Bar>
           </BarChart>
         </ResponsiveContainer>
                  </Paper>
                </Grid>
        <Grid container display="flex" alignItems="center" spacing={1} mt={3}>
          <Grid item xs={12} md={6}>
            <Box display="flex" alignItems="center" flexWrap="wrap">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box sx={{ flex: 1, minWidth: '150px', mb: { xs: 2, md: 0 } }}> 
                  <DatePicker
                    label="Date de début"
                    value={dateDebut}
                    onChange={(newValue) => setDateDebut(newValue)}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                  />
                </Box>
                <Box sx={{ flex: 1, minWidth: '150px', ml: { xs: 0, md: 2 }, mt: { xs: 2, md: 0 } }}>
                  <DatePicker
                    label="Date de fin"
                    value={dateFin}
                    onChange={(newValue) => setDateFin(newValue)}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                  />
                </Box>
              </LocalizationProvider>
            </Box>
          </Grid>
        </Grid>
        <Box>
      <Typography
        variant="h6"
        sx={{
          fontWeight: 'bold',
          fontSize: { xs: '1.2rem', sm: '1.4rem', md: '1.7rem' },
          textAlign: 'center',
          mb: { xs: 2, sm: 3 },
          mt: { xs: 2, sm: 3, md: 4 },
          color: '#333',
        }}
      >
        Heures de fonctionnement du moteur
      </Typography>
      <Paper
        elevation={3}
        sx={{
          width: { xs: '100%', sm: '90%', md: 'auto' },
          maxWidth: { xs: 350, sm: 500, md: 1000 },
          height: { xs: 380, sm: 400, md: 400 },
          padding: 2,
          borderRadius: 3,
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        }}
      >
        {heuremoteurData && (
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={heuremoteurData} margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="heure" fill="#F6CA11" radius={[10, 10, 0, 0]} animationDuration={1500} barSize={80} minPointSize={60}/>
            </BarChart>
          </ResponsiveContainer>
        )}
      </Paper>
    </Box>
               </TabPanel>
               <TabPanel value="3">
                   <Grid xs={12} md={6}>
                  <Typography sx={{fontWeight:'bold', fontSize:'1.2rem'}}>Nombre de tracteur et pannes</Typography>
                  <Paper square={false}  elevation={4} sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    height: 'auto',
                    width: 'auto',
                    position: 'relative',
                  }}>
                    <MapChartTracteur/>
                  </Paper>
                  <Typography mt={3} sx={{fontWeight:'bold', fontSize:'1.2rem'}}>Sols par ville</Typography>
                  <Paper square={false}  elevation={4} sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    height: 'auto',
                    width: 'auto',
                    position: 'relative',
                  }}>
                    <MapChartSol/>
                  </Paper>
                  <Typography mt={3} sx={{fontWeight:'bold', fontSize:'1.2rem'}}>Cultures par ville</Typography>
                  <Paper square={false}  elevation={4} sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    height: 'auto',
                    width: 'auto',
                    position: 'relative',
                  }}>
                    <MapChartCulture2/>
                  </Paper>
                  </Grid>
                  </TabPanel>
          <TabPanel value="4">
            <Grid container spacing={2}>
              <Grid xs={12} md={6}>
          <Typography sx={{fontWeight:'bold', fontSize:'1.2rem'}}>Production par ville</Typography>
                  <Paper elevation={4} sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    height: 'auto',
                    width: { xs: '100%', sm: 300, md: 400 },
                    position: 'relative'
                  }}>
                    <MapChartCulture/>
                  </Paper>
                  </Grid>
                  <Grid xs={12} md={6}>
                  <Typography sx={{fontWeight:'bold', fontSize:'1.2rem'}}>Rendement par ville</Typography>
                  <Paper elevation={4} sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    height: 'auto',
                    width: { xs: '100%', sm: 300, md: 400 },
                    position: 'relative'
                  }}>
                    <MapChartRendement/>
                  </Paper>
                  </Grid>
                  <Grid xs={12} md={6}>
                  <Typography sx={{fontWeight:'bold', fontSize:'1.2rem'}}>Conducteurs par ville</Typography>                  <Paper 
                  elevation={2} 
                  sx={{ 
                    display: 'flex', justifyContent: 'center', flexDirection: 'column', 
                    height: 'auto', width: '100%',                     
                    maxWidth: { xs: '100%', sm: 300, md: 400 },
                    position: 'relative' 
                  }}
                >
                  <MapChart />
                </Paper>
                </Grid>
                <Grid xs={12} md={6}>
                  <Typography sx={{fontWeight:'bold', fontSize:'1.2rem'}}>Activité des tracteurs par zone</Typography>                 
                   <Paper 
                  elevation={2} 
                  sx={{ 
                    display: 'flex', justifyContent: 'center', flexDirection: 'column', 
                    height: 'auto', width: '100%',                     
                    maxWidth: { xs: '100%', sm: 300, md: 400 },
                    position: 'relative' 
                  }}
                >
                  <MapChartReports />
                </Paper>
                </Grid>
                  </Grid>
                   </TabPanel>
        </TabContext>
        </Box>
      </Box>
    );
  };
  
  export default ProductionAgricoleComponent;
  