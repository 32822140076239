import React from 'react';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';

const TypeChart = ({ data }) => {
  return (
    <PieChart
      series={[
        {
          arcLabel: (item) => `${item.value} (${((item.value / data.reduce((acc, curr) => acc + curr.value, 0)) * 100).toFixed(1)}%)`,
          arcLabelMinAngle: 10, // Réduit l'angle minimum pour afficher plus de labels
          data,
          highlightScope: { faded: 'global', highlighted: 'item' },
          faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' }, // Effet "fade" sur les éléments non survolés
          highlight: { outerRadius: 120, strokeWidth: 3, color: 'black' }, // Met en évidence l'élément survolé
          startAngle: 90, // Tourne le graphique pour un meilleur alignement visuel
          endAngle: -270, // Crée un aspect circulaire uniforme
          labelPosition: 'inside', // Ajoute cette ligne pour positionner les labels à l'intérieur du cercle
        },
      ]}
      sx={{
        [`& .${pieArcLabelClasses.root}`]: {
          fill: 'black', // Labels en noir pour plus de contraste
          fontWeight: 'bold',
          textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)', // Ajout d'une ombre portée aux labels
        },
      }}
      height={500}
      width={400}
      slotProps={{
        legend: { hidden: true, position: 'right' },
      }}
      colors={['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40']} // Palette de couleurs plus attrayante
      animation // Animation activée pour un rendu fluide
    />
  );
};

export default TypeChart;
