import { useEffect, useRef, useState } from "react";
import * as topojson from "topojson-client";
import * as d3 from "d3";
import colors from "./colors";
import { baseUrl } from "../../../api/auth_constant";

export function MapChart() {
  const [state, setState] = useState({ country: null, salesByCity: {} });
  const [svgRef, svgRef2] = [useRef(null), useRef(null)];
  const height = 850;

  const jsonToData = async (data) => {
    try {
      const responses = await Promise.all([
        d3.json(String(data.countries)),
        d3.json(`${baseUrl}commercial/liste_vente`),
        d3.json(`${baseUrl}client`),
      ]);

      const countriesGeoJSON = topojson.feature(responses[0], responses[0].objects.collection);
      const commercials = responses[1];
      const clients = responses[2];

      const salesByCity = clients.reduce((acc, client) => {
        const clientVentes = commercials.filter(
          (commercial) => commercial.CT_Num === client.CT_Num
        );

        if (clientVentes.length > 0) {
          const city = client.CT_Adresse.toLowerCase();
          acc[city] = (acc[city] || 0) + clientVentes.length;
        }
        return acc;
      }, {});

      setState({
        country: countriesGeoJSON,
        salesByCity,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchData = async () => {
    await jsonToData({
      countries: new URL("https://raw.githubusercontent.com/oscarduzer/countries-states-cities-database/master/country.topojson"),
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (state.country && svgRef.current) {
      const svg = d3.select(svgRef.current)
        .attr("viewBox", [0, 0, height / 2])
        .attr("height", height)
        .on("click", reset);

      const tooltip = d3.select("#tooltip")
        .style("pointer-events", "none")
        .style("visibility", "hidden");

      const datacolor = ["0", "1-10", "11-30", ">30"];
      const xAxisScale = d3.scaleBand()
        .domain(datacolor)
        .range([30, height / 3])
        .padding(0.04);

      const projection = d3.geoProjection(function (x, y) {
        return [x, Math.log(Math.tan(Math.PI / 4 + y / 2))];
      }).scale(5800).translate([-20, 1370]);

      const path = d3.geoPath().projection(projection);

      svg.selectAll("*").remove();
      const g = svg.append("g").attr("fill", "#444");

      g.selectAll("path")
        .data(state.country.features)
        .join("path")
        .attr("fill", (d) => {
          const city = d.properties.name.toLowerCase();
          const salesCount = state.salesByCity[city] || 0;

          if (salesCount === 0) {
            return colors[0];
          } else if (salesCount >= 1 && salesCount <= 10) {
            return colors[1];
          } else if (salesCount >= 11 && salesCount <= 30) {
            return colors[2];
          }
          return colors[3];
        })
        .attr("d", path)
        .attr("title", (d) => d.properties?.name)
        .attr("stroke", "gray")
        .attr("cursor", "pointer")
        .on("mouseover", (event, d) => {
          const city = d.properties.name.toLowerCase();
          const salesCount = state.salesByCity[city] || 0;

          tooltip.html(`<em style="text-align:center;font-weight:bold;">${d.properties.name}</em><br/><em style="font-weight:bold; text-wrap:nowrap;">Nombre de vente : </em>${salesCount === 0 ? "Aucune vente" : salesCount}`)
          .style('left',  `${event.clientX}px`)
          .style('top', `${event.clientY}px`)
          .style("position", "fixed")
          .style("z-index", "1000")
          .style("visibility", "visible");
        })
        .on("mouseout", () => {
          tooltip.html("").style("visibility", "hidden").style("top", 0);
        })
        .on("click", clicked);

      const xAxis = svg.append("g")
        .attr("transform", `translate(0,30)`)
        .call(d3.axisBottom(xAxisScale).tickSizeOuter(0))
        .selectAll("text")
        .style("text-anchor", "middle");

      svg.selectAll(".bar")
        .data(datacolor)
        .enter().append("rect")
        .attr("class", "bar")
        .attr("x", d => xAxisScale(d))
        .attr("width", xAxisScale.bandwidth())
        .attr("y", 10)
        .attr("height", 20)
        .attr("fill", d => {
          switch (d) {
            case "0":
              return colors[0];
            case "1-10":
              return colors[1];
            case "11-30":
              return colors[2];
            case ">30":
              return colors[3];
          }
        });

      function clicked(event, d) {
        const [[x0, y0], [x1, y1]] = path.bounds(d);
        event.stopPropagation();
      }

      function reset() {
      }
    }
  }, [state]);

  return (
    <>
      <svg width={"100%"} ref={svgRef} />
      <div id="tooltip"></div>
    </>
  );
}
