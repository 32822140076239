import axios from "axios";
import { registerUrl, loginUrl } from '../api/auth_constant';


const storeToken = async (token) => {
  try {
    localStorage.setItem('token', token);
  } catch (e) {
    console.error("Error storing token:", e);
  }
};


export const register = async (body, usertype) => {
  try {
    const res = await axios.post(registerUrl, body);
    console.log(res.data, res.status);
      storeToken(res.data.token);

    
    return res;
  } catch (e) {
    console.error("Error registering:", e);
    throw e;
  }
};


export const login = async (body) => {
  try {
    const res = await axios.post(loginUrl, body);
    console.log(res.data, res.status);

    // Stockez le token après une connexion réussie
    if (res.data.token) {
      storeToken(res.data.token);
    }

    return res;
  } catch (e) {
    console.error("Error logging in:", e);
    throw e;
  }
};
