import React, { useState, useEffect } from 'react';
import RoleCheck from './RoleCheck';
import SideBar from '../SideBar';
import { AppBar, Toolbar, IconButton, Typography, Container, Grid, Box, Paper, CircularProgress } from '@mui/material';
import BarChartComponent from '../BarChartComponent';
import LineChartComponent from '../LineChartComponent';
import CustomCard from '../CustomCard';
import { financeUrl, beneficeUrl, cardUrl } from '../../api/auth_constant';
import DateFormater from '../Utils/DateFormater';
import CurrencyFormater from '../Utils/CurrencyFormater';
import { LoadingButton } from '@mui/lab';
import CalendarButton from '../CalendarButton';
import ButtonComponent from '../ButtonComponent';

function DashboardFinancier() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [benefice, setBenefice] = useState(0);
  const [chiffreAffaire, setChiffreAffaire] = useState(0);
  const [depenses, setDepenses] = useState(0);
  const [data, setData] = useState(null);
  const [data2, setData2] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    fetchData1(financeUrl);
  }, []);

  const fetchData1 = async (url) => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setBenefice(data["Bénéfice"]);
      setChiffreAffaire(data["CA"]);
      setDepenses(data["Dépenses"]);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(cardUrl);
      if (!response.ok) {
        throw new Error('Erreur lors de la récupération des données');
      }
      const jsonData = await response.json();

      // Agrégation par mois
      const aggregatedData = {};
      jsonData.Date.forEach((date, index) => {
        const monthYear = new Date(date).toLocaleString('default', { month: 'long', year: 'numeric' });
        if (!aggregatedData[monthYear]) {
          aggregatedData[monthYear] = [];
        }
        aggregatedData[monthYear].push(jsonData.CA[index]);
      });

      // Calculer le chiffre d'affaires moyen par mois
      const aggregatedCA = Object.keys(aggregatedData).map(monthYear => ({
        MonthYear: monthYear,
        AverageCA: aggregatedData[monthYear].reduce((acc, ca) => acc + ca, 0) / aggregatedData[monthYear].length,
      }));

      setData({ Date: aggregatedCA.map(entry => entry.MonthYear), CA: aggregatedCA.map(entry => entry.AverageCA) });

    } catch (error) {
      console.error('Erreur:', error);
    }
  };

  useEffect(() => {
    fetchData2();
  }, []);

  const fetchData2 = async () => {
    try {
      const response = await fetch(beneficeUrl);
      if (!response.ok) {
        throw new Error('Erreur lors de la récupération des données');
      }

      const jsonData2 = await response.json();
      const avantData2 = Object.entries(jsonData2.Avant).map(([date, value]) => ({ date: new Date(date), value }));
      const apresData2 = Object.entries(jsonData2.Après).map(([date, value]) => ({ date: new Date(date), value }));

      const allDates = Array.from(new Set([...avantData2, ...apresData2].map(d => d.date))).sort((a, b) => a - b);

      const data2Avant = allDates.map(date => {
        const entry = avantData2.find(d => d.date.getTime() === date.getTime());
        return entry ? entry.value : null;
      });

      const data2Apres = allDates.map(date => {
        const entry = apresData2.find(d => d.date.getTime() === date.getTime());
        return entry ? entry.value : null;
      });

      setData2({ dates: allDates, avant: data2Avant, apres: data2Apres });
    } catch (error) {
      console.error('Erreur:', error);
    }
  };

  const handleDrawerOpen = () => {
    setSidebarOpen(true);
  };

  const handleDrawerClose = () => {
    setSidebarOpen(false);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <SideBar open={sidebarOpen} onClose={handleDrawerClose} />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <Container>
          <Box display="flex" justifyContent="flex-end" alignItems="center" mb={3}>
            <CalendarButton selectedDate={selectedDate} handleDateChange={handleDateChange} />
            <Box ml={2}>
              <ButtonComponent barChartData={data} lineChartData={data2} />
            </Box>
          </Box>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <CustomCard title="Chiffres d'affaires" value={`+${chiffreAffaire} CFA`} color="#ffff" />
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomCard title="Bénéfice" value={`+${benefice} CFA`} color="#ffff" />
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomCard title="Dépenses" value={`+${depenses} CFA`} color="#ffff" />
            </Grid>
            <Grid item>
              <Paper sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 800, height: 500 }}>
                {data ? (
                  <BarChartComponent
                    xAxisDatas={data.Date.map(date => new Date(date))}
                    xAxisDatasFormater={DateFormater}
                    yAxisDatas={[data.CA]}
                    xAxisLabels="Mois"
                    yAxisDatasFormaters={[CurrencyFormater]}
                    yAxisLabels={["Chiffre d'affaire"]}
                    yAxisLegends={["CA"]}
                  />
                ) : (
                  <LoadingButton variant="text" color="primary" loading loadingIndicator={<CircularProgress sx={{ width: 70, height: 70 }} />} />
                )}
              </Paper>
            </Grid>
            <Grid item xs={12} md={8} sx={{ mt: 10 }}>
              <Paper sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 800, height: 500 }}>
                {data2 ? (
                  <LineChartComponent
                    xAxisDatas={data2.dates.map(date => new Date(date))}
                    xAxisDatasFormater={DateFormater}
                    xAxisLabels="Date"
                    yAxisDatas={[data2.avant, data2.apres]}
                    yAxisDatasFormaters={[CurrencyFormater, CurrencyFormater]}
                    yAxisLabels={["CA semaine passée", "CA cette semaine"]}
                    yAxisLegends={["CA semaine passée", "CA cette semaine"]}
                    datasColors={['blue', 'tomato']}
                  />
                ) : (
                  <LoadingButton variant="text" color="primary" loading loadingIndicator={<CircularProgress sx={{ width: 70, height: 70 }} />} />
                )}
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}

export default RoleCheck(['Financier'])(DashboardFinancier);
