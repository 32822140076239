import React from 'react';
import * as d3 from 'd3';
import * as topojson from 'topojson-client';
import { useState, useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import '../../../App.css';
import { baseUrl } from '../../../api/auth_constant';
import colors from './colors';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';

export default function MapChartTracteur() {
  const [state, setState] = useState({ country: null, tractorsData: [], pannesData: [] });
  const [dialogData, setDialogData] = useState(null); // État pour gérer les données du dialog
  const [isDialogOpen, setIsDialogOpen] = useState(false); // État pour gérer l'ouverture du dialog
  const svgRef = useRef(null);
  const height = 850;

  const handleClose = () => {
    setIsDialogOpen(false);
  };
  const jsonToData = async (data) => {
    try {
      const responses = await Promise.all([
        d3.json(String(data.countries)),
        d3.json(String(`${baseUrl}/tractors`)),
        d3.json(String(`${baseUrl}/pannes`))
      ]);
      const countriesGeoJSON = topojson.feature(responses[0], responses[0].objects.collection);
      setState({ country: countriesGeoJSON, tractorsData: responses[1], pannesData: responses[2] });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchData = async () => {
    await jsonToData({
      countries: new URL("https://raw.githubusercontent.com/oscarduzer/countries-states-cities-database/master/country.topojson"),
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (state.country && svgRef.current) {
      const svg = d3.select(svgRef.current)
        .attr("viewBox", [0, 0, height / 2])
        .attr("height", height);

      const tooltip = d3.select("#tooltip").style('pointer-events', 'none').style("visibility", "hidden");

      const datacolor = [
        "0",
        "1-5",
        "6-10",
        ">10"
      ];
      const xAxisScale = d3.scaleBand()
        .domain(datacolor)
        .range([30, height / 3])
        .padding(0.04);

      const projection = d3.geoProjection(function (x, y) {
        return [x, Math.log(Math.tan(Math.PI / 4 + y / 2))];
      }).scale(5800).translate([-20, 1370]);

      const path = d3.geoPath().projection(projection);

      svg.selectAll("*").remove();
      const g = svg.append("g")
        .attr("fill", "#444");

      g.selectAll("path")
        .data(state.country.features)
        .join('path')
        .attr('fill', function (d, i) {
          const data = state.tractorsData.filter((item) => (String(item["locality"]).toLowerCase().includes(d.properties.name.toLowerCase())));
          if (data.length === 0) {
            return String(colors[0]);
          } else if (data.length >= 0 && data.length <= 5) {
            return String(colors[1]);
          } else if (data.length >= 6 && data.length <= 10) {
            return String(colors[2]);
          }
          return String(colors[3]);
        })
        .attr('d', path)
        .attr('title', (d) => d.properties?.name)
        .attr('stroke', 'gray')
        .attr('cursor', "pointer")
        .on("mouseover", (event, d) => {
          const data = state.tractorsData.filter((item) => (String(item["locality"]).toLowerCase().includes(d.properties.name.toLowerCase())));
          const pannes = state.pannesData.filter((item) =>
            String(item['Commune de la visite']).toLowerCase().includes(d.properties.name.toLowerCase())
          ).length;

          tooltip.html(`
            <em style="text-align:center;font-weight:bold;">${d.properties.name}</em><br/>
            <em style="font-weight:bold; text-wrap:nowrap;">Tracteur : </em>${data.length === 0 ? "Aucun" : data.length}<br/>
            <em style="font-weight:bold;">Pannes :</em> ${pannes === 0 ? 'Aucune' : pannes}<br/>
          `).style('left', `${event.pageX / 2}px`).style('top', `${event.pageY / 2}px`).style("visibility", "visible");
        })
        .on("mouseout", (event, i) => {
          tooltip.html("").style("visibility", "hidden").style("top", 0);
        })
        .on("click", clicked);

      const xAxis = svg.append("g")
        .attr("transform", `translate(0,30)`)
        .call(d3.axisBottom(xAxisScale).tickSizeOuter(0))
        .selectAll("text")
        .style("text-anchor", "middle");

      svg.selectAll(".bar")
        .data(datacolor)
        .enter().append("rect")
        .attr("class", "bar")
        .attr("x", d => xAxisScale(d))
        .attr("width", xAxisScale.bandwidth())
        .attr("y", 10)
        .attr("height", 20)
        .attr("fill", d => {
          switch (d) {
            case "0":
              return colors[0];
            case "1-5":
              return colors[1];
            case "6-10":
              return colors[2];
            case ">10":
              return colors[3];
          }
        });

      // Fonction pour ouvrir le dialog avec les informations du tooltip
      function clicked(event, d) {
        const data = state.tractorsData.filter((item) => (String(item["locality"]).toLowerCase().includes(d.properties.name.toLowerCase())));
        const pannes = state.pannesData.filter((item) =>
          String(item['Commune de la visite']).toLowerCase().includes(d.properties.name.toLowerCase())
        ).length;

        // Définir les données à afficher dans le dialog
        setDialogData({
          name: d.properties.name,
          tractorsCount: data.length === 0 ? "Aucun" : data.length,
          pannes: pannes === 0 ? 'Aucune' : pannes,
        });
        setIsDialogOpen(true); // Ouvrir le dialog
      }
    }
  }, [state]);

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
  return (
    <>
      <svg width={"100%"} ref={svgRef} />
      <div id="tooltip"></div>
      {/* Dialog pour afficher les informations */}
      <BootstrapDialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}   sx={{ 
    '& .MuiPaper-root': { 
      borderRadius: '40px' // Appliquer des bords arrondis au contenu du Dialog
    } 
  }}>
        {dialogData && (
          <>
            <DialogTitle sx={{fontWeight:'bold', color:'green'}}>{dialogData.name}</DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={(theme) => ({
                position: 'absolute',
                right: 8,
                top: 8,
                color: theme.palette.grey[500],
              })}
            >
          <Box
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 30, // diamètre du cercle
                height: 30, // diamètre du cercle
                borderRadius: '50%', // pour rendre la forme circulaire
                border: '2px solid red', // couleur et taille du bord du cercle
              }}
            >
              <CloseIcon sx={{ color: 'red' }} />
            </Box>
        </IconButton>
            <DialogContent dividers>
              <DialogContentText gutterBottom>
                <strong>Tracteur :</strong> {dialogData.tractorsCount}<br />
                <strong>Pannes :</strong> {dialogData.pannes}<br />
              </DialogContentText>
            </DialogContent>
          </>
        )}
      </BootstrapDialog>
    </>
  );
}
