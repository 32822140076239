import React, { useState, useEffect } from 'react';
import axios from 'axios';
import RoleCheck from './RoleCheck';
import SideBar from '../SideBar';
import { AppBar, Toolbar, IconButton, Typography, Modal, Grid, Box, Paper, Autocomplete, TextField, MenuItem, FormControl, InputLabel } from '@mui/material';
import CustomCard from '../CustomCard';
import { Tab, Tabs, Nav, Row, Col  } from 'react-bootstrap';
import { baseUrl, commercialTime } from '../../api/auth_constant';
import { commercialUrl } from '../../api/auth_constant';
import CalendarButton from '../CalendarButton';
import ButtonComponent from '../ButtonComponent';
import TypeChart from '../TypeChart';
import { MapChart } from './DashBoardCommercialUtils/MapChart';
import { MapChartArticle } from './DashBoardCommercialUtils/MapChartArticle';
import MapChartClient from './DashBoardSavUtils/MapChartClient';
import BarChartRender from './DashBoardCommercialUtils/BarChartRender';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import RvHookupIcon from '@mui/icons-material/RvHookup';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import LinkIcon from '@mui/icons-material/Link';
import { useMediaQuery } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { BarChart, Bar, Cell, ResponsiveContainer, LabelList } from 'recharts';
import FlecheComponent from '../FlecheComponent';
import { useData } from '../DataContext'; 
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
const anneeOptions = [
  { label: "2022" },
  { label: "2023" },
  { label: "2024" },

];
const currentDate = new Date();
const formattedDate = currentDate.toLocaleDateString('fr-FR', { year: 'numeric', month: 'long' });
const formattedDateWithCapitalizedMonth = capitalizeFirstLetter(formattedDate); 

const TRACTEURS = [
    'PSF0024', 'PSF0091', 'PSF0083', 'PSF0087', 'PSF0050', 'PSF0052', 'PSF0051'
];
const REMORQUES = [
    'PSF0041', 'PSF0038', 'PSF0039', 'PSF0084', 'PSF0040', 'PSF0088'
];
const KIT = [
    'KIT0012', 'KIT0011', 'KIT0008', 'KIT0007', 'KIT0003', 'KIT0005', 'KIT0009',
    'KIT0004', 'KIT0010', 'KIT0006', 'KIT0002', 'KIT0001', 'KIT0014'
];
const AUTRES = [
    'PSF0022', 'PSF0012', 'PSF0011', 'PSF0010', 'PSF0092', 'PSF0085', 'PSF0102',
    'PSF0089', 'PSF0055', 'PSF0139', 'PSF0058', 'PSF0137', 'PSF0103', 'PSF0104',
    'PSF0086', 'PSF0001', 'PSF0015', 'PSF0108', 'PSF0107', 'PSF0106', 'PSF0105',
    'PSF0101', 'PSF0035', 'PSF0033', 'PSF0034', 'PSF0059', 'PSF0130', 'PSF0090',
    'PSF0079'
];
const vagueOptions = [
  { label: "" },  
  { label: "Marques"},
  { label: "Puissance motrice"},
];
function DashboardCommercial() {
  const { commercialData, setCommercialData } = useData();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [totalVentes, setTotalVentes] = useState(0);
    const [tracteursCount, setTracteursCount] = useState(0);
    const [remorquesCount, setRemorquesCount] = useState(0);
    const [ventePuissance, setVentePuissance] = useState([]);
    const [venteMarque, setVenteMarque] = useState([]);
    const [selectedVague, setSelectedVague] = useState(vagueOptions[0].label);
    const [kitsCount, setKitsCount] = useState(0);
    const [autresCount, setAutres] = useState(0);
    const [typeChartData, setTypeChartData] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [tabValue, setTabValue] = useState('1');
    const [showMore, setShowMore] = useState(false);
    const [selectedAnnee, setSelectedAnnee] = useState(2024);
    const [chartData, setChartData] = useState({
      labels: [],
      data: [],
    });
    useEffect(() => {
        fetchData1(commercialTime);
    });
    useEffect(() => {
      if (selectedAnnee) {
        fetchData2(selectedAnnee); // Utilisez `selectedAnnee` ici, et non `commercialTime`
      }
    }, [selectedAnnee]);    

    useEffect(() => {
      if (!commercialData) {
        fetchData(commercialUrl);
      } else {
        const {
          totalVentes,
          tracteursCount,
          remorquesCount,
          ventePuissance,
          venteMarque,
          kitsCount,
          autresCount,
          typeChartData,
        } = commercialData;
  
        setTotalVentes(totalVentes);
        setTracteursCount(tracteursCount);
        setRemorquesCount(remorquesCount);
        setVentePuissance(ventePuissance);
        setVenteMarque(venteMarque);
        setKitsCount(kitsCount);
        setAutres(autresCount);
        setTypeChartData(typeChartData);
      }
    }, [commercialData]);

 const fetchData = async (url) => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const totalVentes = data.length;
  
        let tracteursCount = 0;
        let remorquesCount = 0;
        let kitsCount = 0;
        let autresCount = 0;
        const countMap = {};
        data.forEach(item => {
          const ref = item.AR_Ref;
          const design = item.DL_Design;
  
          if (TRACTEURS.includes(ref) || REMORQUES.includes(ref) || KIT.includes(ref)) {
            countMap[design] = (countMap[design] || 0) + 1;
          }
          if (TRACTEURS.includes(ref)) {
            tracteursCount++;
          }
           if (REMORQUES.includes(ref)) {
           remorquesCount++;
         }  if (KIT.includes(ref)) {
           kitsCount++;
         } if (AUTRES.includes(ref)) {
           autresCount++;
         }
        });
        // Mettez à jour le contexte avec les nouvelles données
        setCommercialData({
          totalVentes,
          tracteursCount,
          remorquesCount,
          kitsCount,
          autresCount,
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    const fetchData1 = async (url) => {
      try {
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth() + 1;
        const response = await fetch(commercialTime, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            annee: 2023,
              mois: currentMonth,
          }),
      });
         if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();  
        const formattedDataPuissance = data
        .filter(item => item.Puissance) // Filtrer pour ne garder que les données avec une puissance définie
        .map(item => ({
          name: item.Puissance,
          VenteParPuissance: item.Nombre_de_ventes,
        })) .sort((a, b) => b.VenteParPuissance - a.VenteParPuissance);

      const formattedDataMarque = data
      .filter(item => item.Marque) // Filtrer pour ne garder que les données avec une puissance définie
      .map(item => ({
        name: item.Marque,
        VenteParMarque: item.Nombre_de_ventes,
      })).sort((a, b) => b.VenteParMarque - a.VenteParMarque);
    setVentePuissance(formattedDataPuissance);
    setVenteMarque(formattedDataMarque);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    const fetchData2 = async (selectedAnnee) => {
      try {
        const response = await fetch(commercialTime, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            annee: Number(selectedAnnee), // Assurez-vous que cette valeur est correcte
          }),
        });
    
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
    
        const data = await response.json();
        console.log('Annee', selectedAnnee);

        const monthlyData = Array(12).fill(0);
    
        // Assurez-vous que la structure des données reçues est correcte
        data.forEach(item => {
          const month = item.Mois - 1; // Mois entre 1 (janvier) et 12 (décembre)
          monthlyData[month] = item.Nombre_de_ventes_total || 0; // Assurez-vous que la valeur existe
        });
    
        // Mettre à jour les données pour le graphique
        setChartData({
          labels: [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
          ],
          data: monthlyData,
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    
    const handleDrawerOpen = () => {
        setSidebarOpen(true);
    };

    const handleVagueChange = (event, newValue) => {
      setSelectedVague(newValue ? newValue.label : vagueOptions[0].label);
    };

    const handleShowMore = () => {
      setShowMore(!showMore);
    };
    const handleDrawerClose = () => {
        setSidebarOpen(false);
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };
    const handleAnneeChange = (event, value) => {
      setSelectedAnnee(value?.label || null);
    };
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const isXs = useMediaQuery('(max-width:600px)');
    const isXl = useMediaQuery('(min-width:1200px)');
    return (
        <Box sx={{ display: 'flex' }}>
        <SideBar open={sidebarOpen} onClose={() => setSidebarOpen(false)} />
        <Box component="main" sx={{ flexGrow: 1, p: { xs: 1, sm: 2, md: 3 } }}>
          <Toolbar />
          <Box display="flex" justifyContent="space-between" mb={3}>
            <CalendarButton />
            <ButtonComponent />
          </Box>
  
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 3, borderColor: 'divider' }}>
              <Nav variant="tabs" className="flex-column flex-sm-row">
                {['Ventes', 'Cartes'].map((label, index) => (
                  <Nav.Item key={index}>
                    <Nav.Link
                      eventKey={`${index + 1}`}
                      active={tabValue === `${index + 1}`}
                      onClick={() => handleTabChange(null, `${index + 1}`)}
                      style={{
                        color: tabValue === `${index + 1}` ? '#F6CA11' : '#02834A',
                        fontSize: '1.2rem',
                        textTransform: 'none',
                      }}
                    >
                      {label}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Box>
  
            <TabPanel value="1">
            <div style={{ textAlign: 'center'}}>
                  <span style={{ marginLeft: '10px', color: '#02834A', fontWeight: 'bold', fontSize:isXs ? '1rem' : '2.5rem', }}>{formattedDateWithCapitalizedMonth}</span>
                </div>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={6} md={3}>
                  <CustomCard title="Tracteurs" value={`${tracteursCount}`} color="#ffff" icon={<AgricultureIcon />} svalue={'+500'}/>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CustomCard title="Remorques" value={`${remorquesCount}`} color="#ffff" icon={<RvHookupIcon />} svalue={'+350'}/>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CustomCard title="Kits" value={`${kitsCount}`} color="#ffff" icon={<DeveloperBoardIcon />} svalue={'+300'}/>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CustomCard title="Autres équipements" value={`${autresCount}`} color="#ffff" icon={<LinkIcon />} svalue={'+250'}/>
                </Grid>
              </Grid>
            <Box display="flex" alignItems="center" mb={2} mt={3}>
                <Autocomplete
                  options={vagueOptions}
                  onChange={handleVagueChange}
                  renderInput={(params) => <TextField {...params} label="Filtre" color='success' size='small' />}
                  sx={{ width: 200, ml: 2 }} />
                
              </Box>
              {selectedVague === "" && (
                 <>
              <Grid container spacing={3}>
              <Grid item xs={12} md={6} mt={3}>
                <Paper
                    elevation={3}
                    sx={{
                      width: { xs: '100%', sm: '90%', md: 'auto' },
                      maxWidth: { xs: 350, sm: 500, md: isXl ? 800: 600 },
                      height: { xs: 380, sm: 400, md: 300 },
                      padding: 2,
                      borderRadius: 3,
                      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                    }}
                  >   
            
                  <div style={{textAlign:'center'}}>
                      <span style={{ fontWeight: 'bold', fontSize: isXs ? '1rem' :'1.5rem' }}>Tracteurs par puissance</span>
                    </div>
                    {ventePuissance && ventePuissance.length > 0 ? (
                      <>
                  <ResponsiveContainer width="100%" height={250} style={{marginTop:15}}>
                          <BarChart
                            layout="vertical"
                            data={ventePuissance}
                            margin={isXs ? {} : { top: 5, left: 10, bottom: 2 }} 
                            >
                            <XAxis type="number" axisLine={false} tick={false} />
                            <YAxis
                              type="category"
                              dataKey="name"
                              axisLine={false}
                              tickMargin={isXs ? 10 : 20}
                              tickLine={false}
                              width={isXs ? 80 : 100}
                              tick={{fontSize: 16, fontFamily: 'Times New Roman', fontWeight: 'bold' }}
                            />
                            <Tooltip />
                            <Bar dataKey="VenteParPuissance" fill="#F6CA11" radius={[5, 5, 5, 5]} barSize={30} minPointSize={60}>
                              <LabelList
                                dataKey="VenteParPuissance"
                                position="insideEnd"
                                fill="#fff"
                                fontSize={18}
                                offset={10}
                              />
                            </Bar>
                          </BarChart>
                        </ResponsiveContainer>
                     </>
                    ) : (
                      <Typography sx={{ textAlign: 'center', padding: 2 }}>Aucune donnée</Typography>
                    )}
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6} mt={3}>
                <Paper
                    elevation={3}
                    sx={{
                      width: { xs: '100%', sm: '90%', md: 'auto' },
                      maxWidth: { xs: 350, sm: 500, md: isXl ? 800: 600 },
                      height: { xs: 380, sm: 300, md: 300 },
                      padding: 2,
                      borderRadius: 3,
                      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)'
                    }}
                  >   
                <div style={{textAlign:'center'}}>
                      <span style={{ fontWeight: 'bold', fontSize: isXs ? '1rem' :'1.5rem' }}>Tracteurs par marque</span>
                    </div>
                    {venteMarque && venteMarque.length > 0 ? (
                      <>
                  <ResponsiveContainer width="100%" height={200} style={{marginTop:15}}>
                          <BarChart
                            layout="vertical"
                            data={venteMarque}
                            margin={isXs ? {} : { top: 5, left: 10 }} 
                            >
                            <XAxis type="number" axisLine={false} tick={false} />
                            <YAxis
                              type="category"
                              dataKey="name"
                              axisLine={false}
                              tickMargin={isXs ? 10 : 20}
                              tickLine={false}
                              width={isXs ? 130 : 150}
                              tick={{fontSize: 16, fontFamily: 'Times New Roman', fontWeight: 'bold' }}
                            />
                            <Tooltip />
                            <Bar dataKey="VenteParMarque" fill="#F6CA11" radius={[5, 5, 5, 5]} barSize={30}>
                              <LabelList
                                dataKey="VenteParMarque"
                                position="insideEnd"
                                fill="#fff"
                                fontSize={18}
                                offset={10}
                              />
                            </Bar>
                          </BarChart>
                        </ResponsiveContainer>
                     </>
                    ) : (
                      <Typography sx={{ textAlign: 'center', padding: 2 }}>Aucune donnée</Typography>
                    )}
                  </Paper>
                </Grid>
                </Grid>
                <Grid item xs={12} md={6} mt={2}>
                <Typography variant="h6"  sx={{ 
                      fontWeight: 'bold', 
                      fontSize: { xs: '1rem', sm: '1.2rem', md: '1.3rem' },
                      textAlign: 'center',
                      mb: { xs: 2, sm: 3 }
                      }}> Nombre de vente par mois</Typography>
                  <Box mb={3}>
                  <Autocomplete
                 options={anneeOptions}
                  onChange={handleAnneeChange}
                  renderInput={(params) => <TextField {...params} label="Année" color="success" size="small" />}
                  sx={{ width: { xs: 120, sm: 150 }, marginLeft: 2 }}
        />
                  </Box>
                  <ResponsiveContainer width="100%" sx={{  maxWidth: {
                    xs: '100%',  
                    sm: '100%', 
                    md:'800px'
                  }}} 
                  height={400}> 
                  <LineChart data={chartData.data.map((Production, index) => ({ month: chartData.labels[index], Production }))}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="Production" stroke="#F6CA11" activeDot={{ r: 6 }}/>
                  </LineChart>
                  </ResponsiveContainer>
                </Grid>
            </>  )}

            {selectedVague === "Puissance motrice" && (
            <>
                <Grid item xs={12} md={6} mt={3}>
                <Paper
                    elevation={3}
                    sx={{
                      width: { xs: '100%', sm: '90%', md: 'auto' },
                      maxWidth: { xs: 350, sm: 500, md: isXl ? '100%': 1000 },
                      height: { xs: 380, sm: 400, md: 400 },
                    }}
                  >   
                  <div style={{ textAlign: 'center' }}>
                      <span style={{ fontWeight: 'bold', fontSize: isXs ? '1rem' :'1.5rem' }}>Tracteurs par puissance</span>
                    </div>
                    {ventePuissance && ventePuissance.length > 0 ? (
                      <>
                  <ResponsiveContainer width="100%" height={300} style={{marginTop:15}}>
                          <BarChart
                            layout="vertical"
                            data={ventePuissance}
                            margin={isXs ? {} : { top: 5, left: 10, bottom: 2 }} 
                            >
                            <XAxis type="number" axisLine={false} tick={false} />
                            <YAxis
                              type="category"
                              dataKey="name"
                              axisLine={false}
                              tickMargin={isXs ? 10 : 20}
                              tickLine={false}
                              width={isXs ? 80 : 100}
                              tick={{fontSize: 16, fontFamily: 'Times New Roman', fontWeight: 'bold' }}
                            />
                            <Tooltip />
                            <Bar dataKey="VenteParPuissance" fill="#F6CA11" radius={[5, 5, 5, 5]}>
                              <LabelList
                                dataKey="VenteParPuissance"
                                position="insideEnd"
                                fill="#fff"
                                fontSize={18}
                                offset={10}
                              />
                            </Bar>
                          </BarChart>
                        </ResponsiveContainer>
                    
                     </>
                    ) : (
                      <Typography sx={{ textAlign: 'center', padding: 2 }}>Aucune donnée</Typography>
                    )}
                  </Paper>
                </Grid>
                </>
            )}

            {selectedVague === "Marques" && (
            <>
                <Grid item xs={12} md={6} mt={3}>
                <Paper
                    elevation={3}
                    sx={{
                      width: { xs: '100%', sm: '90%', md: 'auto' },
                      maxWidth: { xs: 350, sm: 500, md: isXl ? '100%': 1000 },
                      height: { xs: 380, sm: 400, md: 400 },
                    }}
                  >   
                  <div style={{textAlign:'center'}}>
                      <span style={{ fontWeight: 'bold', fontSize: isXs ? '1rem' :'1.5rem' }}>Tracteurs par marque</span>
                    </div>
                    {venteMarque && venteMarque.length > 0 ? (
                      <>
                  <ResponsiveContainer width="100%" height={300} style={{marginTop:15}}>
                          <BarChart
                            layout="vertical"
                            data={venteMarque}
                            margin={isXs ? {} : { top: 5, left: 10, bottom: 2 }} 
                            >
                            <XAxis type="number" axisLine={false} tick={false} />
                            <YAxis
                              type="category"
                              dataKey="name"
                              axisLine={false}
                              tickMargin={isXs ? 10 : 20}
                              tickLine={false}
                              width={isXs ? 130 : 150}
                              tick={{fontSize: 16, fontFamily: 'Times New Roman', fontWeight: 'bold' }}
                            />
                            <Tooltip />
                            <Bar dataKey="VenteParMarque" fill="#F6CA11" radius={[5, 5, 5, 5]}>
                              <LabelList
                                dataKey="VenteParMarque"
                                position="insideEnd"
                                fill="#fff"
                                fontSize={18}
                                offset={10}
                              />
                            </Bar>
                          </BarChart>
                        </ResponsiveContainer>
                      
                     </>
                    ) : (
                      <Typography sx={{ textAlign: 'center', padding: 2 }}>Aucune donnée</Typography>
                    )}
                  </Paper>
                </Grid>
                </>
          )}
            </TabPanel>
  
            <TabPanel value="2">
            <Grid container spacing={2} mt={3}>
            <Grid item xs={12} md={6}>
                            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                            Nombre de client par régions
                            </Typography>
                            <Paper elevation={2} sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', height: 'auto', width: 430, position: 'relative' }}>
                            <MapChartClient />
                        </Paper>
                    </Grid>    
                    <Grid item xs={12} md={6} mt={2}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                        Nombre de ventes par régions
                            </Typography>
                            <Paper elevation={2} sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', height: 'auto', width: 430, position: 'relative' }}>
                            <MapChart/>
                        </Paper>
                    </Grid> 
                    </Grid> 
                    <Grid item xs={12} md={6} mt={2}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                        repartition des articles par régions
                            </Typography>
                            <Paper elevation={2} sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', height: 'auto', width: 430, position: 'relative' }}>
                            <MapChartArticle/>
                        </Paper>
                    </Grid>      
                </TabPanel>
          </TabContext>
        </Box>
      </Box>
    );
  }
  
  export default RoleCheck(['Commercial'])(DashboardCommercial);
