import React, { useState, useEffect, useRef } from 'react';
import { Button, Menu, MenuItem, Popper } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { styled } from '@mui/system';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './CustomCalendar.css'; // Import your custom CSS file

const CalendarButton = ({ selectedDate, handleDateChange }) => {
  const [calendarAnchorEl, setCalendarAnchorEl] = useState(null);
  const [yearMenuAnchorEl, setYearMenuAnchorEl] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const popperRef = useRef(null);

  const handleCalendarClick = (event) => {
    setYearMenuAnchorEl(event.currentTarget);
  };

  const handleYearSelect = (year) => {
    setSelectedYear(year);
    setYearMenuAnchorEl(null);
    setCalendarAnchorEl(yearMenuAnchorEl); // Open calendar after year selection
  };

  const handleClose = () => {
    setYearMenuAnchorEl(null);
    setCalendarAnchorEl(null);
  };

  const handleClickOutside = (event) => {
    if (popperRef.current && !popperRef.current.contains(event.target)) {
      handleClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const openYearMenu = Boolean(yearMenuAnchorEl);
  const openCalendar = Boolean(calendarAnchorEl);

  const CustomButton = styled(Button)({
    borderColor: '#02834A',
    color: '#02834A',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  });

  const filterDate = (date) => {
    if (!selectedYear) return true;
    return date.getFullYear() === selectedYear;
  };

  return (
    <div>
      <CustomButton
        variant="outlined"
        endIcon={<CalendarMonthIcon />}
        onClick={handleCalendarClick}
      >
        Période
      </CustomButton>
      <Menu
        anchorEl={yearMenuAnchorEl}
        open={openYearMenu}
        onClose={handleClose}
        sx={{ marginLeft: 132, marginTop: 15 }}
      >
        <MenuItem onClick={() => handleYearSelect(2023)}>PA 23</MenuItem>
        <MenuItem onClick={() => handleYearSelect(2024)}>PA 24</MenuItem>
      </Menu>
      <Popper 
        open={openCalendar} 
        anchorEl={calendarAnchorEl} 
        placement="bottom-start" 
        disablePortal
        modifiers={[
          {
            name: 'zIndex',
            enabled: true,
            phase: 'afterWrite',
            fn: ({ state }) => {
              state.styles.popper.zIndex = 1500; // High z-index value
            },
          },
        ]}
      >
        <div ref={popperRef} className="custom-popper">
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            inline
            filterDate={filterDate}
            startDate={selectedYear ? new Date(selectedYear, 0, 1) : null}
            openToDate={selectedYear ? new Date(selectedYear, 0, 1) : new Date()}
            dayClassName={(date) =>
              date.getFullYear() !== selectedYear ? 'react-datepicker__day--grayed-out' : ''
            }
          />
        </div>
      </Popper>
    </div>
  );
};

export default CalendarButton;
