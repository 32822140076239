import * as React from 'react';
import Card from '@mui/joy/Card';
import Typography from '@mui/joy/Typography';
import { Box } from '@mui/system';
import '../App.css';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { useMediaQuery } from '@mui/material';


export default function CustomCard({ title, value, color, svalue, icon }) {
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString('fr-FR', { year: 'numeric', month: 'long' });
  const isXs = useMediaQuery('(max-width:600px)');

  return (
    <Card 
      variant="solid" 
      color={color} 
      sx={{ 
        height: '180px', 
        border: '1px solid #02834A', 
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        transition: 'transform 0.3s, box-shadow 0.3s',
        '&:hover': {
          transform: 'translateY(-8px)',
          boxShadow: '0 8px 24px rgba(0, 0, 0, 0.2)',
        },
        borderRadius: '20px',
        width: '230px',
        padding: '12px',
      }}
    >
      <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
        <Box display="flex" alignItems="center" mb={1}>
          <Box 
            sx={{ 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center', 
              width: '35px', 
              height: '35px', 
              borderRadius: '50%', 
              backgroundColor: 'rgba(255, 255, 255, 0)', 
              border: '2px solid #F6CA11', 
              marginRight: '8px',
            }}
          > 
            <Box sx={{ color: '#F6CA11', fontSize: '1rem' }}> 
              {icon}
            </Box>
          </Box>
          <Typography 
            level="body-md" 
            sx={{ fontSize: isXs ? '1rem' : '1.2rem', 
              fontFamily: 'Times New Roman', 
              color: '#02834A', 
              whiteSpace: 'nowrap',
              fontWeight: 'bold' }}
          >
            {title}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Typography 
            level="h2" 
            sx={{ 
              color: '#02834A', 
              fontWeight: 'bold', 
              whiteSpace: 'nowrap',
              fontSize: '3rem', 
            }}
          >
            {value}
          </Typography>
          <Box display="flex" alignItems="center">
            <Typography 
              level="h3" 
              sx={{ fontSize:'2rem', fontFamily: 'Times New Roman', color:'#3A8EBA', fontWeight: 'bold',marginLeft:'100px'}}
            >
              {svalue}
            </Typography>
            {svalue && (
            <TrendingUpIcon sx={{ marginLeft:'3px', color: '#3A8EBA' }} />
          )}
          </Box>
        </Box>
      </Box>
    </Card>
  );
}
