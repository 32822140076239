import React, { createContext, useContext, useState } from 'react';

const DataContext = createContext();

export const DataProvider = ({ children }) => {
const [productionData, setProductionData] = useState(null);
  const [commercialData, setCommercialData] = useState(null);
  const [mecanicienData, setMecanicienData] = useState(null);

  return (
    <DataContext.Provider value={{  productionData, setProductionData, commercialData, setCommercialData, mecanicienData, setMecanicienData }}>
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => {
  return useContext(DataContext);
};
