import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { styled } from '@mui/system';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

const CustomButton = styled(Button)({
  borderColor: '#02834A',
  color: '#02834A',
  textTransform: 'capitalize',
  '&:hover': {
    borderColor: 'darkgreen',
    backgroundColor: 'rgba(0, 128, 0, 0.1)',
  },
});

const exportToPDF = (data, filename) => {
  if (!data || !data.dates) {
    console.error('Data structure is not as expected:', data);
    return;
  }

  const doc = new jsPDF();
  doc.text('Chiffre d\'affaires Avant et Après', 14, 10); // Titre du document

  doc.autoTable({
    startY: 20, // Position de départ du tableau après le titre
    head: [["Date", "Chiffre d'affaires avant", "Chiffre d'affaires Après"]],
    body: data.dates.map((date, index) => [
      date.toLocaleDateString(),
      data.avant[index] !== null ? data.avant[index] : '',
      data.apres[index] !== null ? data.apres[index] : '',
    ]),
  });

  doc.save(filename);
};

const exportBarChartToPDF = (data, filename) => {
  if (!data || !data.Date || !data.CA) {
    console.error('Data structure is not as expected:', data);
    return;
  }

  const doc = new jsPDF();
  doc.text('Evolution du Chiffre d\'affaires', 14, 10); // Titre du document

  doc.autoTable({
    startY: 20, // Position de départ du tableau après le titre
    head: [["Date", "Chiffre d'affaires"]],
    body: data.Date.map((date, index) => [
      new Date(date).toLocaleDateString(),
      data.CA[index] !== null ? data.CA[index] : '',
    ]),
  });

  doc.save(filename);
};

export default function IconLabelButtons({ barChartData, lineChartData }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExportClick = (chartType) => {
    handleClose();
    if (chartType === 'bar') {
      exportBarChartToPDF(barChartData, 'bar_chart_data.pdf');
    } else if (chartType === 'line') {
      exportToPDF(lineChartData, 'line_chart_data.pdf');
    }
  };

  return (
    <div>
      <CustomButton
        variant="outlined"
        endIcon={<FileDownloadOutlinedIcon />}
        onClick={handleClick}
      >
        Exporter
      </CustomButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleExportClick('bar')}>Exporter Bar Chart</MenuItem>
        <MenuItem onClick={() => handleExportClick('line')}>Exporter Line Chart</MenuItem>
      </Menu>
    </div>
  );
}
