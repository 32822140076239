import React, { useState, useEffect } from 'react';
import { styled, useTheme, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Avatar, Typography, Collapse } from '@mui/material';
import List from '@mui/material/List';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import avatar from '../static/images/avatar.jpeg';
import iconSonama from '../static/images/logo_sonama.png';
import iconDirecteur from '../static/images/Directeur_default.svg';
import iconCommercial from '../static/images/commercial_default.svg';
import iconMecanic from '../static/images/mecanicien_default.svg';
import iconRelation from '../static/images/Relation_default.svg';
import iconCycle from '../static/images/cycle_defaut.svg';
import iconProduction from '../static/images/production_default.svg';
import iconDirectblack from '../static/images/directeur_select(2).svg';
import iconCommercialblack from '../static/images/commercial_select.svg';
import iconMecanicblack from '../static/images/mecanicien_select.svg';
import iconRelationblack from '../static/images/relation_select.svg';
import iconCycleblack from '../static/images/cycle__select.svg';
import iconProductionblack from '../static/images/production_select.svg';
import formation from '../static/images/formation_default.svg'
import formationblack from '../static/images/formation_select.svg'
import satisfaction from '../static/images/satisfaction.png'
import satisfactionblack from '../static/images/satisfactionblack.png'
import localisation from '../static/images/localisation.png'
import localisationblack from '../static/images/localisationblack.png'
import prediction from '../static/images/prediction.png'
import predictionblack from '../static/images/predictionblack.png'
import chat from '../static/images/chat.png'
import chatblack from '../static/images/chatblack.png'
import iasonama from '../static/images/iasonama.png'
import iasonamablack from '../static/images/iasonamablack.png'
import { useMediaQuery } from '@mui/material';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  backgroundColor: '#02834A',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  backgroundColor: '#02834A',
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backdropFilter: 'blur(10px)',
  backgroundColor: alpha(theme.palette.background.paper, 0.9),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': {
        ...openedMixin(theme),
      },
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': {
        ...closedMixin(theme),
      },
    }),
  }),
);

export default function SideBar() {
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [isMobile, setIsMobile] = React.useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['user', 'email']);
  const [openCycleDeVie, setOpenCycleDeVie] = useState(true);
  const [openRelation, setOpenRelation] = useState(true);
  const [openChat, setOpenChat] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const isXs = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    if (isXs) setOpen(false);
  }, [isXs]);
  const handleLogout = () => {
    // Supprime les cookies et les données du localStorage
    removeCookie('user');
    removeCookie('email');
    localStorage.removeItem('@usertype');
    
    // Redirige l'utilisateur vers la page de connexion
    navigate('/');
  };
  
  React.useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth < 768;
      setIsMobile(mobile);
      if (mobile) setOpen(false); // Ferme le Drawer sur mobile
      else setOpen(true); // Ouvre le Drawer sur grands écrans
    };

    handleResize(); // Vérifie la taille initiale
    window.addEventListener('resize', handleResize); // Ajoute un écouteur d'événements

    return () => {
      window.removeEventListener('resize', handleResize); // Nettoie l'écouteur d'événements
    };
  }, []);

  const handleDrawerToggle = () => {
    setOpen(!open); // Toggle l'état du Drawer
  };

  const isActiveRoute = (route) => {
    return location.pathname === route;
  };

  const handleNavigation = (path) => {
    navigate(path);
    if (isXs) {
      setOpen(false);
    }
  };

  const handleCycleDeVieClick = () => {
    setOpenCycleDeVie(!openCycleDeVie);
  };

  const handleRelationClick = () => {
    setOpenRelation(!openRelation);
  };

  const handleChatClick = () => {
    setOpenChat(!openChat);
  };

  const [activeSubMenu, setActiveSubMenu] = useState('');
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar open={open} style={{ boxShadow: 'none', color: 'black' }}>
  <Toolbar>
    <IconButton
      color="inherit"
      aria-label="open drawer"
      onClick={handleDrawerToggle}
      edge="start"
      sx={{
        marginRight: 2,
        ...(open && { display: 'none' }),
      }}
    >
      <MenuIcon />
    </IconButton>
    <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, fontSize: { xs: '1rem', sm: '1.2rem' } }}>
    Tableau de bord
    </Typography>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <IconButton color="inherit" aria-label="profile">
        <Avatar
          alt="Profile Picture"
          src={avatar}
          sx={{ width: 30, height: 30 }} // Réduire la taille de l'avatar pour les petits écrans
        />
      </IconButton>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', ml: 1 }}>
        <Typography variant="body1" component="div" sx={{ fontWeight: 'bold', fontSize: { xs: '0.75rem', sm: '1rem' } }}>
          {cookies.email}
        </Typography>
        <Typography variant="body2" sx={{ fontSize: { xs: '0.65rem', sm: '0.75rem' } }}>
          {cookies.user?.usertype}
        </Typography>
      </Box>
    </div>
  </Toolbar>
</AppBar>

      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerToggle}>
            <DoubleArrowIcon sx={{ transform: open ? 'rotate(180deg)' : 'rotate(0deg)', color: 'white' }} />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Box sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>
          <img src={iconSonama} alt="Sonama Logo" style={{ maxWidth: '70%', height: 'auto' }} />
        </Box>
        <Divider />
<List>
  <ListItem disablePadding onClick={handleCycleDeVieClick} sx={{ display: 'block' }}>
    <ListItemButton
      sx={{
        minHeight: 48,
        justifyContent: open ? 'initial' : 'center',
        px: 2.5,
      }}
    >
      <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center'}}>
        <img src={iconCycle} alt="Cycle de vie" style={{ width: 24, height: 24 }} />
      </ListItemIcon>
      <ListItemText primary="Cycle de vie" sx={{ opacity: open ? 1 : 0 , color:'white'}} />
      {openCycleDeVie ? <ExpandLessIcon sx={{color:'white'}}/> : <ExpandMoreIcon sx={{color:'white'}}/>}
    </ListItemButton>
  </ListItem>
  <Collapse in={openCycleDeVie} timeout="auto" unmountOnExit>
    <List component="div" disablePadding>
      {[
        { path: "/dashboard_director", icon: isActiveRoute("/dashboard_director") ? iconDirectblack : iconDirecteur, text: "Production" },
        { path: "/dashboard_sales", icon: isActiveRoute("/dashboard_sales") ? iconCommercialblack : iconCommercial, text: "Ventes" },
        { path: "/dashboard_mechanic", icon: isActiveRoute("/dashboard_mechanic") ? iconMecanicblack : iconMecanic, text: "SAV" },
      ].map(({ path, icon, text }) => (
        <ListItemButton
          key={path}
          sx={{
            minHeight: 48,
            justifyContent: open ? 'initial' : 'center',
            pl: 5,
            backgroundColor: isActiveRoute(path) ? '#F6CA11' : 'transparent',
            borderRadius: 2,
            margin: isActiveRoute(path) ? '5px' : '0',
            '&:hover': {
              backgroundColor: isActiveRoute(path) ? '#F6CA11' : 'transparent',
              borderRadius: 2,
              margin: isActiveRoute(path) ? '5px' : '0',
            },
          }}
          onClick={() => handleNavigation(path)}
        >
          <ListItemIcon>
            <img src={icon} alt={text} style={{ width: 24, height: 24 }} />
          </ListItemIcon>
          <ListItemText
          primary={text}
          sx={{
            opacity: open ? 1 : 0,
            color: isActiveRoute(path) ? 'black' : 'white',
          }}
        />
        </ListItemButton>
      ))}
    </List>
  </Collapse>
  <ListItem disablePadding onClick={handleRelationClick} sx={{ display: 'block' }}>
    <ListItemButton
      sx={{
        minHeight: 48,
        justifyContent: open ? 'initial' : 'center',
        px: 2.5,
      }}
    >
      <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center'}}>
        <img src={iconRelation} alt="Relation Client" style={{ width: 24, height: 24 }} />
      </ListItemIcon>
      <ListItemText primary="Relation Client" sx={{ opacity: open ? 1 : 0 , color:'white'}} />
      {openRelation ? <ExpandLessIcon sx={{color:'white'}}/> : <ExpandMoreIcon sx={{color:'white'}}/>}
    </ListItemButton>
  </ListItem>
  <Collapse in={openRelation} timeout="auto" unmountOnExit>
    <List component="div" disablePadding>
      {[
        { path: "/training", icon: isActiveRoute("/training") ? formationblack : formation, text: "Formations" },
        { path: "/retex", icon: isActiveRoute("/retex") ? satisfactionblack : satisfaction, text: "Satisfaction" },
        { path: "/carte", icon: isActiveRoute("/carte") ? localisationblack : localisation, text: "Cartes" },
      ].map(({ path, icon, text }) => (
        <ListItemButton
          key={path}
          sx={{
            minHeight: 48,
            justifyContent: open ? 'initial' : 'center',
            pl: 5,
            backgroundColor: isActiveRoute(path) ? '#F6CA11' : 'transparent',
            borderRadius: 2,
            margin: isActiveRoute(path) ? '5px' : '0',
            '&:hover': {
              backgroundColor: isActiveRoute(path) ? '#F6CA11' : 'transparent',
              borderRadius: 2,
              margin: isActiveRoute(path) ? '5px' : '0',
            },
          }}
          onClick={() => handleNavigation(path)}
        >
          <ListItemIcon>
            <img src={icon} alt={text} style={{ width: 24, height: 24 }} />
          </ListItemIcon>
          <ListItemText
          primary={text}
          sx={{
            opacity: open ? 1 : 0,
            color: isActiveRoute(path) ? 'black' : 'white',
          }}
        />
        </ListItemButton>
      ))}
    </List>
  </Collapse>
  {[
    { path: "/production_agricole", icon: isActiveRoute("/production_agricole") ? iconProductionblack : iconProduction, text: "Performance Agricole" },
  ].map(({ path, icon, text }) => (
    <ListItem key={path} disablePadding sx={{ display: 'block' }} onClick={() => handleNavigation(path)}>
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: open ? 'initial' : 'center',
          px: 1.5,
          backgroundColor: isActiveRoute(path) ? '#F6CA11' : 'transparent',
          borderRadius: 2,
          margin: isActiveRoute(path) ? '10px' : '0',
          '&:hover': {
            backgroundColor: isActiveRoute(path) ? '#F6CA11' : 'transparent',
            borderRadius: 2,
            margin: isActiveRoute(path) ? '10px' : '0',
          },
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 3 : 'auto',
            justifyContent: 'center',
          }}
        >
          <img src={icon} alt={text} style={{ width: 24, height: 24 }} />
        </ListItemIcon>
        <ListItemText
          primary={text}
          sx={{
            opacity: open ? 1 : 0,
            color: isActiveRoute(path) ? 'black' : 'white',
          }}
        />
      </ListItemButton>
    </ListItem>
  ))}
</List>
<Divider />
<List>
<ListItem disablePadding onClick={handleChatClick} sx={{ display: 'block' }}>
    <ListItemButton
      sx={{
        minHeight: 48,
        justifyContent: open ? 'initial' : 'center',
        px: 2.5,
      }}
    >
      <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center'}}>
        <img src={iasonama} alt="SoNaMA IA" style={{ width: 24, height: 24 }} />
      </ListItemIcon>
      <ListItemText primary="SoNaMA IA" sx={{ opacity: open ? 1 : 0 , color:'white'}} />
      {openChat ? <ExpandLessIcon sx={{color:'white'}}/> : <ExpandMoreIcon sx={{color:'white'}}/>}
    </ListItemButton>
  </ListItem>
  <Collapse in={openChat} timeout="auto" unmountOnExit>
    <List component="div" disablePadding>
      {[
        { path: "/prediction_agricole", icon: isActiveRoute("/prediction_agricole") ? predictionblack : prediction, text: "Prédictions" },
        { path: "/chat", icon: isActiveRoute("/chat") ? chatblack : chat, text: "Chat" },
      ].map(({ path, icon, text }) => (
        <ListItemButton
          key={path}
          sx={{
            minHeight: 48,
            justifyContent: open ? 'initial' : 'center',
            pl: 5,
            backgroundColor: isActiveRoute(path) ? '#F6CA11' : 'transparent',
            borderRadius: 2,
            margin: isActiveRoute(path) ? '5px' : '0',
            '&:hover': {
              backgroundColor: isActiveRoute(path) ? '#F6CA11' : 'transparent',
              borderRadius: 2,
              margin: isActiveRoute(path) ? '5px' : '0',
            },
          }}
          onClick={() => handleNavigation(path)}
        >
          <ListItemIcon>
            <img src={icon} alt={text} style={{ width: 24, height: 24 }} />
          </ListItemIcon>
          <ListItemText
          primary={text}
          sx={{
            opacity: open ? 1 : 0,
            color: isActiveRoute(path) ? 'black' : 'white',
          }}
        />
        </ListItemButton>
      ))}
    </List>
  </Collapse>
</List>

        <Divider />
        <List>
          <ListItem disablePadding sx={{ display: 'block' }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
                backgroundColor: 'transparent',
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
              onClick={handleLogout}
               >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                  color: 'white',
                }}
              >
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Déconnexion" sx={{ opacity: open ? 1 : 0, color: 'white' }} />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
    </Box>
  );
}